export const state = {
  tables: {},
  formOptions: {},
  workerCounts: [{
    id: "<10",
    name_ru: "меньше 10",
    name_uz: "10 дан кам",
    name_oz: "10 dan kam"
  },{
    id: "10-50",
    name_ru: "от 10 до 50",
    name_uz: "10 дан 50 гача",
    name_oz: "10 dan 50 gacha"
  },{
    id: "50-100",
    name_ru: "от 50 до 100",
    name_uz: "50 дан 100 гача",
    name_oz: "50 dan 100 gacha"
  },{
    id: "100-250",
    name_ru: "от 100 до 250",
    name_uz: "100 дан 250 гача",
    name_oz: "100 dan 250 gacha"
  },{
    id: "250-1000",
    name_ru: "от 250 до 1000",
    name_uz: "250 дан 1000 гача",
    name_oz: "250 dan 1000 gacha"
  },{
    id: ">1000",
    name_ru: "больше 1000",
    name_uz: "1000 дан ортиқ",
    name_oz: "1000 dan ortiq"
  }
  // ,{
  //   id: "",
  //   name_ru: "не указывать",
  //   name_uz: "кўрсатилмасин",
  //   name_oz: "ko'rsatilmasin"
  // }
  ],
  currencies: ["UZS","USD"],
  driversLicenses: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "BE",
    "CE",
    "DE",
    "TM",
    "TB",
  ],
  categories: [],
  flatCategories: [],
  errors: [],
  uzb_regions: [],
  priorities: [{
    id:"low",
    name_ru: "Низкий",
    name_uz: "Қуйи",
    name_oz: "Quyi",
  },{
    id:"medium",
    name_ru: "Средний",
    name_uz: "Ўрта",
    name_oz: "O'rta",
  },{
    id:"high",
    name_ru: "Высокий",
    name_uz: "Юқори",
    name_oz: "Yuqori",
  }],
}
