import {orderBy} from '@/utils'
export const mutations = {
  SET_ITEM: (state, item) => {
    item['timer'] = null;
    if(item.limit_time){
      var d = Number(item.limit_time);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      item['timer'] = {
        hours: h < 10?'0'+h:h,
        minutes: m < 10?'0'+m:m,
      }
    }
    state.item = item
  },
  SET_ITEMS: (state, items) => {
    state.items = {
      data: items
    }
  },
  SET_QUESTIONS: (state, items) => {
    state.questions = orderBy(items, 'id')
  },
}
