export const mutations = {
  SET_LIST: (state, items) => {
    state.list = items.data;
    state.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: ((items['current_page']-1)*items['per_page']),
    }
    if(state.pagination['startIndex']){
      state.list = items.data.map((item,index) => {
        item['number'] = state.pagination['startIndex']+(index+1);
        return item;
      })
    }
  },
  SET_MODEL: (state, data) => {
    state.model = data;
  },
  SET_SHORT_LIST: (state, data) => {
    state.shortList = data;
  },
  SET_MY_VACANCIES: (state, items) => {
    state.myVacancies.data = items.data
    state.myVacancies.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: ((items['current_page']-1)*items['per_page']),
    }
  },
  SET_RESPONSES: (state, items) => {
    state.responses.data = items.data
    state.responses.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
    }
  },
  SET_COMPATIBLES: (state, items) => {
    state.compatibles.data = items.data
    state.compatibles.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
    }
  },
  SET_MY_VACANCIES_COUNT: (state, items) => {
    state.my_vacancies_count.count_published = items.count_published
    state.my_vacancies_count.count_drafted = items.count_drafted
    state.my_vacancies_count.count_expired = items.count_expired
    state.my_vacancies_count.count_active = items.count_active
  },
  SET_RESPOND_ITEM: (state, item) => {
    state.respond_item = item
  },
  SET_RESPOND_ITEMS: (state, items) => {
    state.respond_items.data = items.data
    state.respond_items.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page
    }
  },
  SET_FUNNEL: (state, data) => {
    state.funnel = data
    let allCount = 0
    state.funnel['direct_applicants'].forEach(element => {
      allCount = allCount + element.count
    });
    state.funnel['proactively_searched'].forEach(element => {
      allCount = allCount + element.count
    });
    state.funnelAll = allCount
  },
  SET_UTM_LIST: (state, data) =>{
    state.utm_sources = data
  }
};