import {monthToYears} from '@/utils/index'

export const mutations = {
  SET_LIST: (state, items) => {
    state.list.data = items.data.map(item => {
      item['experience_period'] = null;
      if(item.seeker_profile && item.seeker_profile.experience_month){
        item['experience_period'] = monthToYears(item.seeker_profile.experience_month);
      }
      return item;
    });
    state.list.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: ((items['current_page']-1)*items['per_page']),
    }
    if(state.list.pagination['startIndex']){
      state.list.data = items.data.map((item,index) => {
        item['number'] = state.list.pagination['startIndex']+(index+1);
        return item;
      })
    }
  },
  SET_MODEL: (state, data) => {
    if(data){
      if(!data['tags']){
        data['tags'] = [];
      }
      data['experience_period'] = null;
      if(data.seeker_profile && data.seeker_profile.experience_month){
        data['experience_period'] = monthToYears(data.seeker_profile.experience_month);
      }
      data['vacancyCompatiblePercent'] = 0;
      data['type'] = 'candidate';
    }
    state.model = data;
  },
  SET_RESPONSES: (state, data) => {
    state.responses = data.map(item => {
      return {
        type: 'resume',
        id: item.id,
        profile_id: item.seeker_profile.id,
        avatar: item.seeker_profile.avatar,
        last_name: item.seeker_profile.last_name,
        first_name: item.seeker_profile.first_name,
        created_at: item.created_at,
        age: item.seeker_profile.age,
        wanted_salary: item.seeker_profile.wanted_salary,
        salary_currency: item.seeker_profile.salary_currency,
        position: item.seeker_profile.position,
        position_name: item.seeker_profile.position_name
      }
    });
  },
  SET_COMMENTS: (state, data) => {
    if(data){
      state.comments = data;
    }
  },
  SET_SHORT_LIST_STEP: (state, data) => {
    state.shortListStep = data.map(item => {
      return {
        type: 'candidate',
        id: item.id,
        profile_id: item.seeker_profile.id,
        avatar: item.seeker_profile.avatar,
        last_name: item.seeker_profile.last_name,
        first_name: item.seeker_profile.first_name,
        created_at: item.created_at,
        age: item.seeker_profile.age,
        wanted_salary: item.seeker_profile.wanted_salary,
        wanted_work: item.seeker_profile.wanted_work,
        salary_currency: item.seeker_profile.salary_currency,
        position: item.seeker_profile.position,
        position_name: item.seeker_profile.position_name
      }
    });
  },
  SET_SHORT_LIST: (state, data) => {
    state.shortList = data.map(item => {
      return {
        type: 'candidate',
        id: item.id,
        profile_id: item.seeker_profile.id,
        avatar: item.seeker_profile.avatar,
        last_name: item.seeker_profile.last_name,
        first_name: item.seeker_profile.first_name,
        created_at: item.created_at,
        age: item.seeker_profile.age,
        wanted_salary: item.seeker_profile.wanted_salary,
        salary_currency: item.seeker_profile.salary_currency,
        position: item.seeker_profile.position,
        position_name: item.seeker_profile.position_name
      }
    });
  },
  SET_ACTION_HISTORIES: (state, data) => {
    if(data){
      state.actionHistories = data;
    }
  },
  SET_AUTO_SELECTIONS: (state, data) => {
    state.autoselections = data.map(item => {
      return {
        type: 'resume',
        id: item.id,
        profile_id: item.id,
        avatar: item.avatar,
        last_name: item.last_name,
        first_name: item.first_name,
        created_at: null,
        compatible_percentage: item.scoring?item.scoring.compatible_percentage:0,
        age: item.age,
        wanted_salary: item.wanted_salary,
        salary_currency: item.salary_currency,
        position: item.position,
        position_name: item.position_name,
        profile_ball: item.scoring?item.scoring.profile_ball:0,
      }
    });
  },
  DELETE_FROM_LIST: (state, data) => {
    let listKey = data['list'];
    if(state[listKey] && state[listKey].length > 0) {
      if(data['id']){
        state[listKey] = state[listKey].filter(item => item.id != data['id']);
      }
      if(data['profile_id']){
        state[listKey] = state[listKey].filter(item => item.profile_id != data['profile_id']);
      }
    }
  },
  SET_ALL_TAGS: (state, data) => {
    if(data){
      state.allTags = data;
    }
  },
};