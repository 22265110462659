import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/";
import middlewares from "./middlewares";
import i18n from "@/i18n";
import { getItem,setItem } from '@/utils/storage'

import { checkTokenTime } from "@/utils/auth";

import recruiting from './modules/recruiting'
import company from './modules/company'
import report from './modules/report'
import {unsubscribeChatInRouteChange} from '@/plugins/websocket'

Vue.use(VueRouter)

var modules = [{
    path: "/database-resumes",
    name: "DatabaseResumes",
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Home"),
}];
modules = modules.concat(recruiting);
modules = modules.concat(company);
modules = modules.concat(report);

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: { name: "Company" },
    component: () =>
      import("@/layouts/Main"),
    children: modules
  },{
    path: '/auth/hh',
    name: 'AuthHH',
    component: () =>
    import("@/views/Auth/HHAuth"),
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import("@/views/Auth/Login"),
  },
  {
    path: '/activation',
    name: 'Activation',
    component: () =>
      import("@/views/Auth/Activation"),
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () =>
      import("@/views/Auth/Registration"),
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () =>
      import("@/views/Auth/ForgetPassword"),
  },
  {
    path: '/confirm-password-email',
    name: 'ConfirmEmailAlert',
    component: () =>
      import("@/components/ForgetPassword/ConfirmEmailAlert"),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import("@/components/ForgetPassword/ResetPassword"),
  },
  {
    path: '/select-company',
    name: 'SelectCompany',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Auth/SelectCompany"),
  },
  {
    path: '/select-devices',
    name: 'SelectDevices',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Auth/SelectDevices"),
  },
  {
    path: '/term-of-use',
    name: 'TermOfUse',
    component: () =>
    import("@/views/Auth/termOfUse.vue"),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () =>
    import("@/views/Auth/termOfUse.vue"),
  },
  {
    path: '/access-tariffs',
    name: 'TariffPlans',
    component: () =>
    import("@/views/Billing/TariffsUnauth.vue"),
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(!to.meta['savePosition']){
      var content = document.getElementById('content');
      if(content){
        content.scrollTo(0, 0)
      }
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  setLangFromQuery(to)
  unsubscribeChatInRouteChange(from, to)
  if (to.meta && to.meta.middleware) {
    if (middlewares[to.meta.middleware]) {
      let $res = middlewares[to.meta.middleware](store);
      if (!$res || !checkTokenTime()) {
        return next({ name: "Login", query: {redirect_url: to.path} });
      }
    }
  }
  return next();
});

function setLangFromQuery(to){
  if (to.query.locale && !getItem('locale')) {
    i18n.locale = to.query.locale
    setItem('locale', i18n.locale)
  }
}

export default router
