import { 
  recruiterStatistics,
  vacanciesHistoryStatistics,
  vacanciesTracking,
  funnelDownload
} from '@/api/reports'
export const actions = {
  recruiterStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      recruiterStatistics().then(res => {
        commit('SET_RECRUITERS', res.data)
        resolve(res)
      }).catch(error => { reject(error) })
    })
  },
  vacanciesHistoryStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      vacanciesHistoryStatistics().then(res => {
        commit('SET_VACANCIES', res.data)
        resolve(res)
      }).catch(error => { reject(error) })
    })
  },
  vacanciesTracking({ commit }) {
    return new Promise((resolve, reject) => {
      vacanciesTracking().then(res => {
        resolve(res)
      }).catch(error => { reject(error) })
    })
  },
  funnelDownload({ commit },data) {
    return funnelDownload(data);
  },
}
