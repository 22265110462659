import request from '@/utils/request';

export function vacancyApplications(params) {
  return request({
    url: '/vacancy-applications',
    method: 'get',
    params
  });
}

export function showVacancyApplication(id) {
  return request({
    url: `/vacancy-applications/${id}`,
    method: 'get'
  });
}

export function createVacancyApplication(data) {
  return request({
    url: '/vacancy-applications',
    method: 'post',
    data
  });
}

export function updateVacancyApplication(data) {
  return request({
    url: `/vacancy-applications/${data['id']}`,
    method: 'put',
    data
  });
}

export function deleteVacancyApplication(id) {
  return request({
    url: `/vacancy-applications/${id}`,
    method: 'delete'
  });
}

export function vacancyApplicationCount() {
  return request({
    url: `/vacancy-applications/opens/count`,
    method: 'get'
  });
}

export function vacancyApplicationAllCounts() {
  return request({
    url: `/vacancy-applications/statistic/count`,
    method: 'get'
  });
}

export function rejectOrder(data) {
  return request({
    url: `/vacancy-applications/${data['id']}/cancel`,
    method: 'put',
    data
  });
}

export function confirmOrder(id) {
  return request({
    url: `/vacancy-applications/${id}/confirm`,
    method: 'put'
  });
}