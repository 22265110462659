import { 
  login, 
  currentUser, 
  logout, 
  sendEmailCode, 
  companyRegistration, 
  resetPassword, 
  updatePassword,
  activation,
  checkUserByEmail,
  checkExistUser,
  changeLang,
  recruiterCompanies,
  companyAssign,
  devices,
  replaceToken,
  getUserByRemember
} from '@/api/auth';

import { setToken, setTokenTime, clearForLogout } from '@/utils/auth';
import createSocket from '@/plugins/websocket';

export const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            createSocket();
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setTokenAction({ commit }, token) {
    return new Promise((resolve, reject) => {
      setToken(token);
      setTokenTime(28800);
      createSocket();
      commit('SET_IS_AUTH', true);
      resolve(true);
    });
  },
  currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      currentUser()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_USER', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    commit('UNSUBSCRIBE_NOTIFICATIONS', true);
    return new Promise((resolve, reject) => {
      logout()
      .then(res => {
        if (res['success'] && res['data']) {
          clearForLogout();
        }
        resolve(true);
      })
      .catch(err => {
        reject(err);
      });
    });
  },
  registration({ commit }, data) {
    return new Promise((resolve, reject) => {
      companyRegistration(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            createSocket();
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendEmailCode(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  activation({ commit }, data) {
    return new Promise((resolve, reject) => {
      activation(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            createSocket();
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkUserByEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      checkUserByEmail(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  checkExistUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      checkExistUser(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      resetPassword(data)
        .then(res => {
          if (res['success'] && res['data']) {
            setToken(res['data']['access_token']);
            setTokenTime(res['data']['expires_in']);
            createSocket();
            commit('SET_IS_AUTH', true);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  changeLang({ commit }) {
    return new Promise((resolve, reject) => {
      changeLang()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  recruiterCompanies({ commit }) {
    return new Promise((resolve, reject) => {
      recruiterCompanies()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  companyAssign({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      companyAssign(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  devices({ commit }) {
    return new Promise((resolve, reject) => {
      devices()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  replaceToken({ commit }, id) {
    return new Promise((resolve, reject) => {
      replaceToken(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUserByRemember({ commit }, id) {
    return new Promise((resolve, reject) => {
      getUserByRemember(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
