<template>
  <span v-if="id">{{text|t}}</span>
</template>

<script>
import { cloneObject } from '@/utils';
export default {
  props: {
    id: {
      type: Number|String,
      default:() => 0
    },
    table: {
      type: String,
      default:() => ''
    }
  },
  data() {    
    return {
      loaded: false,
      text: '',
    }
  },
  watch: {
    id(){
      this.getText()
    }
  },
  mounted() {
    this.getText()
  },
  methods: {
    getText(){
      if(this.classifierTables && this.classifierTables[this.table] && this.classifierTables[this.table].length){
        this.setText()
      }else{
        this.getClassifier(this.table).then(res => {
          if(this.classifierTables && this.classifierTables[this.table] && this.classifierTables[this.table].length){
            this.setText()
          }
        })
      }
    },
    setText(){
      let find = this.classifierTables[this.table].find(item => item.id == this.id);
      if(find){
        this.text = cloneObject(find);
      }
    }
  }
}
</script>