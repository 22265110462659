export const mutations = {
  SET_RECRUITERS: (state, data) => {
    state.recruiters = data.map(item => {
      if(item.closed_vacancies_percentage){
        item.closed_vacancies_percentage = Math.round(item.closed_vacancies_percentage * 100) / 100;
      }
      return item;
    });
  },
  SET_VACANCIES: (state, data) => {
    state.vacancies = data;
  },
}