import store from '@/store';
import { formatDate } from "@/filters";
import { orderBy } from "@/utils/index";
import i18n from "@/i18n";

export const mutations = {
    SET_CHATS: (state, items) => {
        let unreadCount = 0;
        state.chats.data = items.map(item => {
            item = chatMutation(item);
            unreadCount = (unreadCount + item.unread_count);
            if (state.selectChat && state.selectChat.id == item.id) {
                state.selectChat = item
            }
            return item
        })
        store.commit('auth/SET_STATISTICS', { unread_count: unreadCount })
            // state.chats.pagination = {
            //   total: items.total,
            //   last_page: items.last_page,
            //   page: items.current_page,
            //   limit: items.per_page
            // }
    },
    RESET_UNREAD_COUNT: (state, chat_id) => {
        state.chats.data = state.chats.data.map(item => {
            if (item.id == chat_id) {
                item.unread_count = 0;
            }
            return item;
        })
    },
    INCREASE_UNREAD_COUNT: (state, chat_id) => {
        state.chats.data = orderBy(state.chats.data.map(item => {
            if (item.id == chat_id) {
                if (!(state.selectChat && state.selectChat.id == chat_id)) {
                    if (item.unread_count) {
                        item.unread_count++;
                    } else {
                        item.unread_count = 1;
                    }
                    item.last_time = formatDate((new Date).toString(), 'YYYY-MM-DD HH:mm:ss')
                }
            }
            return item;
        }), 'last_time', 'desc')
    },
    SET_SELECT_CHAT: (state, chat) => {
        if (state.selectChat) {
            UnSubscriber(state.selectChat.id)
        }
        if (chat && chat.seeker_profile) {
            chat = chatMutation(chat);
        }
        state.selectChat = chat
        state.messages.data = [];
        state.messages.pagination = [];
        if (chat) {
            ChatSubscriber(state)
        }
    },
    SET_MESSAGES: (state, items) => {
        state.messages.data = [];
        messageMutation(state, items.data)
        state.messages.pagination = {
            total: items.total,
            last_page: items.last_page,
            page: items.current_page,
            limit: items.per_page
        }
    },
    ADD_MESSAGE: (state, item) => {
        messageMutation(state, [item], true)
    },
    APPEND_MESSAGES: (state, items) => {
        messageMutation(state, items.data)
        state.messages.pagination = {
            total: items.total,
            last_page: items.last_page,
            page: items.current_page,
            limit: items.per_page
        }
    },
    SET_NOT_SHOWED: (state, params) => {
        state.notShowed = (params && params['not_showed']) ? params['not_showed'] == 1 : false;
    }
}

function chatMutation(item) {

    return {
        id: item.id,
        unread_count: item.unread_count,
        user_id: item.seeker_profile ? item.seeker_profile.user_id : (item.company ? item.company.user_id : null),
        last_time: item.last_time,
        avatar: item.seeker_profile ? item.seeker_profile.avatar : (item.company ? item.company.logo_image : ''),
        fullName: item.seeker_profile ? `${item.seeker_profile.last_name} ${item.seeker_profile.first_name}` : (item.company ? item.company.name : ''),
        optionText: item.seeker_profile ? item.seeker_profile.position : (item.company ? item.company.contact_person : ''),
        is_company: item.company ? true : false,
        profile_id: item.seeker_profile ? item.seeker_profile.id : (item.company ? item.company.id : null),
        seeker_profile_id: item.seeker_profile ? item.seeker_profile.id : null,
        uuid: item.seeker_profile ? item.seeker_profile.uuid : null,
        phone: item.seeker_profile ? item.seeker_profile.phone_number : null,
    }
}

function messageMutation(state, messages, prepend) {
    var currentUser = store.getters['auth/USER']
    messages.map(item => {
        item['is_my'] = item.from_user_id == currentUser.id;
        let date = formatDate(item.created_at, 'DD.MM.YYYY');
        let existDate = state.messages.data.find(item => item.date == date)

        if (!item['is_my']) {
            let messagesArr = String(item.message).split('|');
            if (messagesArr.length > 1 && Number(messagesArr[0])) {
                item['vacancy_id'] = Number(messagesArr[0]);
                item['vacancy_name'] = "";
                messagesArr.splice(0, 1);
                let messageJoin = messagesArr.join('|');
                item.message = messageJoin;
            }
        }
        if (existDate) {
            if (!checkIsExist(existDate.messages, item)) {
                if (prepend) {
                    existDate.messages.unshift(item);
                } else {
                    existDate.messages.push(item);
                }
            }
        } else {
            if (prepend) {
                state.messages.data.unshift({
                    date: date,
                    messages: [item]
                })
            } else {
                state.messages.data.push({
                    date: date,
                    messages: [item]
                })
            }
        }
    })
}

function checkIsExist(messages, item) {
    return messages.find(i => i.id == item.id);
}


function ChatSubscriber(state) {
    var currentUser = store.getters['auth/USER']
    window.Echo.private(`chat.${state.selectChat.id}`)
        .listen('.message.sent', (message) => {
            console.log('.message.sent', message.chatMessage);
            if (message.chatMessage) {
                if (currentUser.id != message.chatMessage.from_user_id) {
                    store.dispatch('chat/showedUpdate', { message_ids: [message.chatMessage.id] })
                }
                messageMutation(state, [message.chatMessage], true)
            }
        })
        .listen('.message.showed', (message) => {
            console.log('.message.showed', message.messages);
            if (message.messages) {
                state.messages.data = state.messages.data.map(dateGroup => {
                    dateGroup.messages = dateGroup.messages.map(item => {
                        if (message.messages.indexOf(item.id) >= 0) {
                            item.is_showed = true;
                        }
                        return item;
                    })
                    return dateGroup;
                })
            }
        });
}

function UnSubscriber(chatId) {
    window.Echo.leave(`chat.${chatId}`);
}