import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/candidate-folders',
    method: 'get',
    params
  });
}

export function store(data) {
  return request({
    url: '/candidate-folders',
    method: 'post',
    data
  });
}

export function update(data) {
  return request({
    url: '/candidate-folders/'+data['id'],
    method: 'PUT',
    data
  });
}

export function remove(id) {
  return request({
    url: '/candidate-folders/'+id,
    method: 'DELETE'
  });
}