export const state = {
  dynamics: [],
  finisheds: [],
  declineds: [],
  
  shortList: [],
  list: [],
  fullList: [],
  step_list_with_candidates: [],
  boardsList: [],
  funnel: []
}
