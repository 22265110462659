import { 
  storeSeekerEducation,
  updateSeekerEducation,
  deleteSeekerEducation,
  storeSeekerExperience,
  updateSeekerExperience,
  deleteSeekerExperience,
  storeSeekerCourse,
  updateSeekerCourse,
  deleteSeekerCourse
} from '@/api/candidateDetails';

export const actions = {
  storeSeekerEducation({ commit }, params) {
    return new Promise((resolve, reject) => {
      storeSeekerEducation(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSeekerEducation({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateSeekerEducation(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSeekerEducation({ commit }, params) {
    return new Promise((resolve, reject) => {
      deleteSeekerEducation(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  storeSeekerExperience({ commit }, params) {
    return new Promise((resolve, reject) => {
      storeSeekerExperience(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSeekerExperience({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateSeekerExperience(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSeekerExperience({ commit }, params) {
    return new Promise((resolve, reject) => {
      deleteSeekerExperience(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  storeSeekerCourse({ commit }, params) {
    return new Promise((resolve, reject) => {
      storeSeekerCourse(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSeekerCourse({ commit }, params) {
    return new Promise((resolve, reject) => {
      updateSeekerCourse(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSeekerCourse({ commit }, params) {
    return new Promise((resolve, reject) => {
      deleteSeekerCourse(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
