export const mutations = {
  SET_LIST: (state, items) => {
    state.list = items.data;
    state.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      show: items.total > items.per_page,
      startIndex: ((items['current_page']-1)*items['per_page']),
    }
    if(state.pagination['startIndex']){
      state.list = items.data.map((item,index) => {
        item['number'] = state.pagination['startIndex']+(index+1);
        return item;
      })
    }
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  }
};