import request from '@/utils/request';

export function classifiers(table, params) {
  table = (table=='other_regions'||table=='uzb_regions')?'regions':table;
  return request({
    url: '/classifiers/'+table,
    method: 'get',
    params: params
  });
}
export function formOptions(data) {
  return request({
    url: '/form-options/list',
    method: 'get',
    params: data
  });
}

export function categoriesHaveVacancy(params) {
  return request({
    url: '/categories-with-vacancies',
    method: 'get',
    data: params
  });
}

export function categoriesHaveResume(params) {
  return request({
    url: '/categories-with-resumes',
    method: 'get',
    data: params
  });
}

export function regionsHaveVacancy(params) {
  return request({
    url: '/regions-with-vacancies',
    method: 'get',
    data: params
  });
}

export function regionsHaveResume(params) {
  return request({
    url: '/regions-with-resumes',
    method: 'get',
    data: params
  });
}