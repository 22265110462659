import { orderBy } from "@/utils";

export const getters = {
  CHATS: (state) => state.chats.data,
  CHATS_PAGINATION: (state) => state.chats.pagination,
  // MESSAGES: (state) => orderBy(state.messages.data, 'date', 'desc'),
  MESSAGES: (state) => state.messages.data,
  MESSAGES_PAGINATION: (state) => state.messages.pagination,
  SELECTED_CHAT: (state) => state.selectChat,
  NOT_SHOWED: (state) => state.notShowed,
}
