const routes = [
  {
    path: 'vacancies-active',
    name: 'Vacancies',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Vacancies/Index"),
  },{
    path: 'vacancies-archived',
    name: 'VacanciesArchived',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Vacancies/Index"),
  },{
    path: 'vacancies/create',
    name: 'VacanciesCreate',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Vacancies/Create"),
  },{
    path: 'vacancies/:id/update',
    name: 'VacancyUpdate',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Vacancies/Create"),
  },{
    path: 'vacancies/show/:id',
    name: 'VacanciesShow',
    meta: { module: 'recruiting', middleware: 'Auth', savePosition: true },
    component: () =>
    import("@/views/Recruiting/Vacancies/Show"),
  },{
    path: 'vacancies/utm-sources/:id',
    name: 'UTMSources',
    meta: { module: 'recruiting', middleware: 'Auth', savePosition: true },
    component: () =>
    import("@/views/Recruiting/Vacancies/UTMSources.vue"),
  },{
    path: 'candidates/create',
    name: 'CandidatesCreate',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Candidates/Form"),
  },{
    path: 'resume/create',
    name: 'ResumeCreate',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Resumes/Form"),
  },{
    path: 'candidates/:id/update',
    name: 'CandidatesEdit',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Candidates/Edit/index"),
  },{
    path: 'candidates',
    name: 'Candidates',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Candidates/Index"),
  },{
    path: 'candidates/:id',
    name: 'CandidatesShow',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Candidates/Show"),
  },{
    path: 'candidates-reserved',
    name: 'CandidatesReserved',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Candidates/Reserved/Index"),
  },{
    path: 'candidates-blocked',
    name: 'CandidatesBlocked',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Candidates/Blocked/Index"),
  },{
    path: 'responses',
    name: 'VacancyResponces',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Responses/Index"),
  },{
    path: 'responses/:id/vacancy',
    name: 'ResponseShow',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Responses/Show"),
  },{
    path: 'response/:id',
    name: 'ResponseResumeShow',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/components/modals/ResumePageModal"),
  },{
    path: 'resumes/:id?',
    name: 'Resumes',
    meta: { module: 'recruiting', middleware: 'Auth', savePosition: true },
    component: () =>
    import("@/views/Resumes/Index"),
  },{
    path: 'assessment',
    name: 'AssessmentsList',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Assessment/Index"),
  },{
    path: 'assessment/create',
    name: 'AssessmentCreate',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Assessment/Create"),
  },{
    path: 'assessment/:id/show',
    name: 'AssessmentShow',
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
    import("@/views/Recruiting/Assessment/Create"),
  },
  // {
  //   path: 'assessment-candidate/:id/show',
  //   name: 'CandidateAssessmentShow',
  //   meta: { module: 'recruiting', middleware: 'Auth' },
  //   component: () =>
  //   import("@/views/Recruiting/Assessment/Show"),
  // }
]

export default routes;
