import request from '@/utils/request';

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  });
}
export function currentUser() {
  return request({
    url: '/auth/get-info',
    method: 'post'
  });
}
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  });
}
// Company Registration
export function sendEmailCode(data) {
  return request({
    url: '/send-code',
    method: 'post',
    data
  });
}

export function companyRegistration(data) {
  return request({
    url: '/company/register',
    method: 'post',
    data
  });
}

export function resetPassword(data) {
  return request({
    url: 'reset-password',
    method: 'post',
    data
  })
}
export function updatePassword(data) {
  return request({
    url: 'update-password',
    method: 'post',
    data
  })
}

export function activation(data) {
  return request({
    url: '/activate-account',
    method: 'post',
    data
  })
}

export function checkUserByEmail(data) {
  return request({
    url: '/check-exist-user-by-email',
    method: 'post',
    data
  })
}

export function checkExistUser(data) {
  return request({
    url: '/check-exist-user',
    method: 'post',
    data
  })
}

export function changeLang() {
  return request({
    url: '/update-user-lang',
    method: 'put'
  })
}

export function recruiterCompanies() {
  return request({
    url: '/recruiter-companies',
    method: 'get'
  })
}

export function companyAssign(data) {
  return request({
    url: '/company-assign',
    method: 'post',
    data
  })
}

export function devices() {
  return request({
    url: '/user-tokens',
    method: 'get'
  })
}

export function replaceToken(id) {
  return request({
    url: '/user-tokens/replace/'+id,
    method: 'put'
  })
}

export function getUserByRemember(remember_token) {
  return request({
    url: `get-user-by-remember/${remember_token}`,
    method: 'get'
  })
}
