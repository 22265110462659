import request from '@/utils/request';

export function list() {
  return request({
    url: '/company-emails',
    method: 'get'
  });
}

export function saveRefreshToken(data) {
  return request({
    url: '/company-emails/save-refresh-token',
    method: 'post',
    data
  });
}

export function sendMail(data) {
  return request({
    url: '/company-emails/send-message',
    method: 'post',
    data
  });
}

export function saveCustomEmail(data) {
  return request({
    url: '/company-emails/save-custom-company-email',
    method: 'post',
    data
  });
}

export function deleteEmail(id) {
  return request({
    url: '/company-emails/'+id,
    method: 'delete'
  });
}