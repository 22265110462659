import moment from 'moment';
import 'moment-precise-range-plugin';
import { mapGetters, mapActions } from 'vuex'
import {cloneObject as utilsCloneObject} from '@/utils/index'
import { getToken, clearForLogout } from '@/utils/auth';
export default {
  data() {
    return {
      metaTitle: 'ISH.UZ'
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTH',
      currentUser: 'auth/USER',
      currentUserStat: 'auth/STATISTICS',
      loadRunningGetter: 'loader/REQUEST_RUNNING',
      classifierTables: 'classifiers/TABLES',
      formOptions: 'classifiers/FORM_OPTIONS',
    }),
    loadRunning(){
      return this.loadRunningGetter < 0?0:this.loadRunningGetter;
    },
    currentUserProfile(){
      return this.currentUser?this.currentUser['profile']:null
    },
    currentCompanyUser(){
      return this.currentUserProfile?this.currentUserProfile['company_user']:null
    },
    isVacancyShow(){
      return this.$route.name == 'VacanciesShow' || this.$route.name == 'VacanciesShowTest';
    },
    vacancyId(){
      if(this.isVacancyShow || this.$route.name == 'VacancyShow'){
        return this.$route.params['id'];
      }
      return null;
    },
    isReservedCandidateList(){
      return this.$route.name == 'CandidatesReserved';
    },
    isCompany(){
      return this.currentUser?this.currentUser['role'] == 'company':false;
    },
    isRecruiter(){
      return this.currentUser?this.currentUser['role'] == 'recruiter':false;
    },
    isApplicant(){
      return this.currentUser?this.currentUser['role'] == 'applicant':false;
    },
    isModerator(){
      return this.currentUser?this.currentUser['role'] == 'moderator':false;
    },
    isPaid(){
      // return true
      if(this.isModerator){
        return true;
      }
      if(this.currentCompanyUser){
        // if(this.currentCompanyUser['billing_details'] && this.currentCompanyUser['billing_details']['payment_status'] == 'paid'){
        if(this.currentCompanyUser['billing_details']){
          return true;
        }
        return false;
      }
      return false;
    },
    fullAts(){
      if(this.currentCompanyUser && 
        this.currentCompanyUser['billing_details'] &&
        this.currentCompanyUser['billing_details']['plan_functionals'] &&
        this.currentCompanyUser['billing_details']['plan_functionals']['ats']){
        return this.currentCompanyUser['billing_details']['plan_functionals']['ats'] == 'full';
      }
      return false;
    },
    shortAts(){
      return !this.fullAts;
    },
    planFunctionals(){
      if(this.currentCompanyUser){
        if(this.currentCompanyUser['billing_details']){
          return this.currentCompanyUser['billing_details']['plan_functionals']
        } 
      }
      return null;
    },
    resumesLimit(){
      if(this.planFunctionals && this.planFunctionals['limits'] && this.planFunctionals['limits']['resumes']){
        return this.planFunctionals['limits']['resumes'];
      }
      return 0;
    },
    vacancyLimit(){
      if(this.currentUserProfile){
        if(this.currentUserProfile['count_balance_vacancy'] && this.currentUserProfile['count_balance_vacancy'] > 0){
          return this.currentUserProfile['count_balance_vacancy']===true?1000:this.currentUserProfile['count_balance_vacancy'];
        }
      }
      return 0;
    },
    vacanciesLimits(){
      if(this.currentUser && this.currentUser['vacancies_limits']){
        return this.currentUser['vacancies_limits'];
      }
      return null;
    },
    isTrialPeriod(){
      if(this.currentCompanyUser && this.currentCompanyUser['billing_details'] && this.currentCompanyUser['billing_details']['payment_status']){
        return this.currentCompanyUser['billing_details']['payment_status'] == 'unpaid';
      }
      return false;
    },
    isDev(){
      return process.env.NODE_ENV == "development";
    },
    companySettings(){
      if(
        this.currentUserProfile && 
        this.currentUserProfile['profile_details'] && 
        this.currentUserProfile['profile_details']['settings']
      ){
        return this.currentUserProfile['profile_details']['settings'];
      }
      return {};
    }
  },
  methods: {
    ...mapActions({
      getClassifiersByTableName: 'classifiers/getClassifiers',
    }),
    cloneObject(object) {
      return utilsCloneObject(object);
    },
    changeQuery(key, value){
      let query = {...{}, ...this.$route.query};
      if(value){
        if(query[key] != value){
          query[key] = value;
          this.$router.push({name: this.$route.name, params: this.$route.params, query });
        }
      }else{
        if(query[key]){
          delete query[key];
          this.$router.push({name: this.$route.name, params: this.$route.params, query });
        }
      }
    },
    getClassifier(table, param, fromDb){
      if(fromDb){
        return this.getClassifiersByTableName({table: table, params: param});
      }
      if (!(this.classifierTables && this.classifierTables[table] && !param)){
        return this.getClassifiersByTableName({table: table, params: param});
      }
      return new Promise((resolve, reject) => {
        resolve(this.classifierTables[table])
      });
    },
    formInvalidValid(form){
      let first = null;
      form.fields.reverse().forEach(field => {
        if(field.validateState == 'error'){
          first = field.$el;
        }
      })
      if(first){
        first.scrollIntoView({ behavior: 'smooth'});
      }
    },
    getYear(n){
      let str1 = this.$t('год');
      let str2 = this.$t('года');
      let str5 = this.$t('лет');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getAge(n){
      let str1 = this.$t('age год');
      let str2 = this.$t('age года');
      let str5 = this.$t('age лет');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getMonthRu(n){
      let str1 = this.$t('месяц');
      let str2 = this.$t('месяца');
      let str5 = this.$t('месяцев');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getDayRu(n){
      let str1 = this.$t('день');
      let str2 = this.$t('дня');
      let str5 = this.$t('дней');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getResponseText(n){
      let str1 = this.$t('n отклик');
      let str2 = this.$t('n отклика');
      let str5 = this.$t('n откликов');
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getNSTextRu(n, str1, str2, str5){
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    scrollToTop(){
      var content = document.getElementById('content');
      if(content){
        content.scrollTo(0, 0)
      }
    },
    getAccessPeriodText(billing_details){
      if(billing_details){
        if(billing_details.payment_status == 'paid'){
          return this.getAccessPeriodDays(billing_details.access_end_date);
        }else if(billing_details.trial_end_date){
          return this.getAccessPeriodDays(billing_details.trial_end_date);
        }
      }
      return null;
    },
    accessDaysByEndDate(endDate){
      if(endDate){
        let billingEndDate = new Date(endDate);
        let currentDate = new Date();
        let difference = billingEndDate.getTime() - currentDate.getTime();
        return Math.ceil(difference / (1000 * 3600 * 24));
      }
      return null;
    },
    getAccessPeriodDaysNumber(billing_details){
      if(billing_details){
        if(billing_details.payment_status == 'paid'){
          return this.accessDaysByEndDate(billing_details.access_end_date);
        }else if(billing_details.trial_end_date){
          return this.accessDaysByEndDate(billing_details.trial_end_date);
        }
      }
      return null;
    },
    getAccessPeriodDays(endDate){
      let currentDate = moment().format('YYYY-MM-DD');
      if(currentDate == endDate){
        return this.$t("Сегодня последний день доступа");
      }
      let text = "Осталось";
      let diffObj = moment(currentDate).preciseDiff(endDate, true);
      if(diffObj['years'] > 0){
        diffObj['months'] += (12*diffObj['years'])
      }
      if(diffObj['months'] > 0){
        text = `${text} {month} ${this.getNSTextRu(diffObj['months'], 'месяц', 'месяца', 'месяцев')}`
      }
      if(diffObj['days'] > 0){
        if(diffObj['months'] > 0){
          text = `${text} и`;
        }
        text = `${text} {n} ${this.getNSTextRu(diffObj['days'], 'день', 'дня', 'дней')}`
      }
      if(diffObj['years'] > 0 || diffObj['months'] > 0 || diffObj['days'] > 0){
        return this.$t(text+" доступа", {month: diffObj['months'], n: diffObj['days']});
      }
    },
    getPeriodText(tariff){
      let period=''
      if (tariff.period_key=='day') {
        period = tariff.period + ' ' + this.getDayRu(tariff.period)
      } else if(tariff.period_key=='year') {
        period = tariff.period + ' ' + this.getYear(tariff.period)
      } else {
        period = tariff.period + ' ' + this.getMonthRu(tariff.period)
      }
      return period
    },
    // openFile(base64, filename) {
    //   link = document.createElement("a");
    //   link.style = 'display: none';
    //   link.href = base64;
    //   link.download = filename;
    //   // link.target = "_blank";
    //   document.body.appendChild(link);
    //   link.click();
    // },
    openFile(base64, filename) {
      let link = document.createElement("a");
      link.href = base64;
      link.download = filename;
      // link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // openFile(base64, filename) {
    //   const base64Split = String(base64).split(" ");
    //   var base64URL = "";
    //   if(base64Split.length > 1){
    //     base64URL = base64Split[1];
    //   }else{
    //     base64URL = base64Split[0];
    //   }
    //   const binary = atob(base64URL.replace(/\s/g, ''));
    //   const len = binary.length;
    //   const buffer = new ArrayBuffer(len);
    //   const view = new Uint8Array(buffer);

    //   for (let i = 0; i < len; i += 1) {
    //     view[i] = binary.charCodeAt(i);
    //   }

    //   // create the blob object with content-type "application/pdf"
    //   const blob = new Blob([view], { type: 'application/pdf' });
    //   const url = URL.createObjectURL(blob);

    //   const a = document.createElement('a');
    //   document.body.appendChild(a);
    //   a.style = 'display: none';
    //   a.href = url;
    //   a.download = filename;
    //   a.click();
    // },
    onlyLatters(string){
      if(string){
        return String(string).replace(/[^A-Za-zА-Яа-яЁё\s-]/g,'');
      }
      return string;
    },
    changeQueries(queries){
      let query = {...{}, ...this.$route.query};
      Object.keys(queries).forEach(key => {
        var value = queries[key];
        if(value){
          if(query[key] != value){
            query[key] = value;
          }
        }else{
          delete query[key];
        }
      })
      this.$router.push({name: this.$route.name, params: this.$route.params, query });
    },
    checkRightPro(){
      if(this.isApplicant || this.isModerator){
        return true;
      }
      if(this.isPaid){
        return true;
      }else if(this.isCompany){
        let token = getToken();
        this.$alert(this.$t('У вас нет доступа на ISH.UZ PRO'), this.$t('Нет доступа'), {
          confirmButtonText: this.$t('Перейти на ISH.UZ'),
          type: 'warning',
          center: true,
          closeOnPressEscape: true,
          closeOnClickModal: true,
        }).then(() => {
          window.open(`${process.env.VUE_APP_NUXT_REDIRECT}/employer?token=${token}`, '_self')
          clearForLogout();
        });
        return false;
      }else{
        this.$alert(this.$t('У вас нет доступа на ISH.UZ PRO'), this.$t('Нет доступа'), {
          confirmButtonText: this.$t('Закрыть'),
          type: 'warning',
          center: true,
          closeOnPressEscape: true,
          closeOnClickModal: true,
        });
        return false;
      }
    }
  },
};
