import request from '@/utils/request';

export function companyInfoByTin(tin) {
  return request({
    url: '/get-company-date/'+tin,
    method: 'get'
  });
}

export function unpaidInvoicesByPlan(plan_id) {
  return request({
    url: '/billing/company-unpaid-invoice-by-plan/'+plan_id,
    method: 'get'
  });
}

export function plans(params) {
  return request({
    url: '/billing/plans',
    method: 'get',
    params
  });
}
export function getPlan(id) {
  return request({
    url: '/billing/plans/'+id,
    method: 'get'
  });
}

export function invoiceCreateForPayme(data) {
  return request({
    url: '/billing/invoice-create-for-payme',
    method: 'post',
    data
  });
}

export function getInvoice(id) {
  return request({
    url: '/billing/invoice-show/'+id,
    method: 'get'
  });
}

export function getInvoiceHistory(params) {
  return request({
    url: '/billing/company-invoices',
    method: 'get',
    params
  });
}

export function getInvoicePdf(uuid) {
  return request({
    url: '/billing/invoice-pdf/' + uuid,
    method: 'get'
  });
}

export function invoiceCreate(data) {
  return request({
    url: '/billing/invoice-create',
    method: 'post',
    data
  });
}

export function invoiceCreateForRecruiters(data) {
  return request({
    url: '/billing/invoice-create-for-recruiter',
    method: 'post',
    data
  });
}

export function contractCreate(data) {
  return request({
    url: '/billing/contract-create',
    method: 'post',
    data
  });
}

export function contractShow(id) {
  return request({
    url: '/billing/contract/'+id,
    method: 'get'
  });
}

export function myContracts() {
  return request({
    url: '/billing/my-contracts',
    method: 'get'
  });
}

export function invoiceDetails() {
  return request({
    url: '/billing/invoice-details',
    method: 'get'
  });
}

export function contractPdf(id) {
  return request({
    url: '/billing/contract-pdf/'+id,
    method: 'get'
  });
}

export function billingCustomPlans() {
  return request({
    url: '/billing/custom-plans',
    method: 'get'
  });
}

export function invoiceCreateForResumes(data) {
  return request({
    url: '/billing/invoice-create-for-resumes',
    method: 'post',
    data
  });
}

export function invoiceCreateForVacancies(data) {
  return request({
    url: '/billing/invoice-create-for-vacancies',
    method: 'post',
    data
  });
}