import { 
  list,
  saveRefreshToken,
  sendMail,
  saveCustomEmail,
  deleteEmail
} from '@/api/emails';

export const actions = {
  getList({ commit }) {
    return new Promise((resolve, reject) => {
      list()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveRefreshToken({ commit },data) {
    return new Promise((resolve, reject) => {
      saveRefreshToken(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendMail({ commit },data) {
    return new Promise((resolve, reject) => {
      sendMail(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveCustomEmail({ commit },data) {
    return new Promise((resolve, reject) => {
      saveCustomEmail(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteEmail({ commit },id) {
    return new Promise((resolve, reject) => {
      deleteEmail(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
