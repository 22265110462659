import Echo from 'laravel-echo'
import { getToken } from '@/utils/auth';
import store from '@/store';
import router from '@/router';

window.Pusher = require('pusher-js');

const createSocket = function (){
  let token = getToken();
  var options = {
    broadcaster: 'pusher',
    authEndpoint: process.env.VUE_APP_WEBSOCKET_AUTHURL,
    key: process.env.VUE_APP_WEBSOCKET_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKET_WSHOST,
    wsPort: process.env.VUE_APP_WEBSOCKET_WSPOST,
    wssPort: process.env.VUE_APP_WEBSOCKET_WSPOST,
    disableStats: true,
    forceTLS: true,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
  };
  if(token){
    options['auth'] = {
      headers: {
          'Authorization': 'Bearer '+token,
        }
    }
  }
  window.Echo = new Echo(options);
}
let token = getToken();
if(token){
  createSocket();
}
export default createSocket;


export function NotificationsSubscriber(user){
  window.Echo.private(`notifications.${user.id}`)
  .listen('.message.sent', (message) => {
      var selectChat = store.getters['chat/SELECTED_CHAT']
      if(message.chatMessage){
        if(message.chatMessage.chat_id && !(selectChat && selectChat.id == message.chatMessage.chat_id)){
          var audio = document.getElementById('notification');
          audio.play();
          store.dispatch('chat/increaseUnreadCount', message.chatMessage.chat_id)
          increaseUserStatUnreadCount();
        }
      }
    })
  .listen('.vacancy-response.created', (response) => {
    if(response['data']){
      let responseData = response['data'];
      if(responseData.vacancy_id){
        var audio = document.getElementById('notification');
        audio.play();
        store.dispatch('auth/currentUser')
        if(router.currentRoute.name == 'VacanciesShow'){
          let candidatesShowMode = store.getters['app/CANDIDATES_MODE'];
          if(candidatesShowMode == 2){
            store.dispatch('candidates/vacancyResponses', {vacancy_id:router.currentRoute.params.id})
          }else{
            store.dispatch('candidates/vacancyResponses', {vacancy_id:router.currentRoute.params.id, data: {is_valid: 1}})
            store.dispatch('candidates/vacancySpamResponses', {vacancy_id:router.currentRoute.params.id, data: {is_valid: 0}})
          }
        }
      }
    }
  })
  .listen('.invoice.paid', (billing_details) => {
    store.dispatch('auth/currentUser')
  })
}

export function increaseUserStatUnreadCount(){
  let statistics = store.getters['auth/STATISTICS'];
  if(statistics && statistics.unread_count){
    store.commit('auth/SET_STATISTICS', {unread_count: (statistics.unread_count+1)})
  }else{
    store.commit('auth/SET_STATISTICS', {unread_count: 1})
  }
}

export function NotificationsUnSubscriber(user){
  window.Echo.leave(`notifications.${user.id}`);
}

export function unsubscribeChatInRouteChange(from, to){
  if(to.name != 'CompanyChat'){
    store.commit('chat/SET_SELECT_CHAT', null)
  }
}