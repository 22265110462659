import request from '@/utils/request';

export function getChats(params) {
  return request({
    url: '/chats',
    method: 'get',
    params
  });
}
export function createChat(data) {
  return request({
    url: '/chats',
    method: 'post',
    data
  });
}
export function getMessages(params) {
  return request({
    url: '/chat-messages',
    method: 'get',
    params
  });
}
export function sendMessage(data) {
  return request({
    url: '/chat-messages/send',
    method: 'post',
    data
  });
}
export function showedUpdate(data) {
  return request({
    url: '/chat-messages/update-showed',
    method: 'put',
    data
  });
}