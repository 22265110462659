import {
    index,
    store,
    update,
    show,
    publish,
    destroy,
    myVacancies,
    myVacanciesActive,
    myVacanciesArchived,
    myVacanciesCount,
    getVacanyResponses,
    respondCandidateShow,
    shortListbyPositionName,
    countAutoselect,
    resendDraft,
    hhAvailableTypes,
    hhBrandTemplates,
    getVacancyFunnel,
    getHHVacancies,
    importHHVacancies, getUTMList, createUTM
} from '@/api/vacancies';

export const actions = {
    list({commit}, params) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_LIST', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    create({commit}, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    show({commit}, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    if (res['data']) {
                        commit('SET_MODEL', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    destroy({commit}, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res)
                }).catch((res) => {
                reject(res)
            })
        })
    },
    update({commit}, data) {
        return new Promise((resolve, reject) => {
            update(data['id'], data['data'])
                .then(res => {
                    resolve(res)
                }).catch(res => {
                reject(res)
            })
        })
    },
    publish({commit}, data) {
        return new Promise((resolve, reject) => {
            publish(data['id'], data['data']).then(res => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    myVacancies({commit}, params) {
        return new Promise((resolve, reject) => {
            myVacancies(params).then(res => {
                commit('SET_MY_VACANCIES', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    myVacanciesActive({commit}, params) {
        return new Promise((resolve, reject) => {
            myVacanciesActive(params).then(res => {
                commit('SET_MY_VACANCIES', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    myVacanciesArchived({commit}, params) {
        return new Promise((resolve, reject) => {
            myVacanciesArchived(params).then(res => {
                commit('SET_MY_VACANCIES', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    myVacanciesCount({commit}, params) {
        return new Promise((resolve, reject) => {
            myVacanciesCount(params).then(res => {
                commit('SET_MY_VACANCIES_COUNT', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    shortListbyPositionName({commit}, params) {
        return new Promise((resolve, reject) => {
            shortListbyPositionName(params).then(res => {
                commit('SET_SHORT_LIST', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    },
    getVacanyResponses({commit}, params) {
        return new Promise((resolve, reject) => {
            getVacanyResponses(params).then(res => {
                commit('SET_RESPOND_ITEMS', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        });
    },
    respondCandidateShow({commit}, id) {
        return new Promise((resolve, reject) => {
            respondCandidateShow(id)
                .then(res => {
                    commit('SET_RESPOND_ITEM', res.data)
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getCountAutoselect({commit}, id) {
        return new Promise((resolve, reject) => {
            countAutoselect(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    resendDraft({commit}, id) {
        return new Promise((resolve, reject) => {
            resendDraft(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    hhAvailableTypes({commit}) {
        return new Promise((resolve, reject) => {
            hhAvailableTypes()
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    hhBrandTemplates({commit}) {
        return new Promise((resolve, reject) => {
            hhBrandTemplates()
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getVacancyFunnel({commit}, id) {
        return new Promise((resolve, reject) => {
            getVacancyFunnel(id)
                .then(res => {
                    if (res['success'] && res['data']) {
                        commit('SET_FUNNEL', res['data']);
                    }
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    getHHVacancies({commit}) {
        return getHHVacancies();
    },
    importHHVacancies({commit}, data) {
        return importHHVacancies(data);
    },
    getUTMList({commit}, data) {
        return new Promise((resolve, reject) => {
            getUTMList(data)
                .then(res => {
                    commit('SET_UTM_LIST', res['data']);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    createUTM({commit}, data) {
        return createUTM(data)
    }
};
