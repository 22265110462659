import Vue from 'vue'

import NotFound from './pages/NotFound';
Vue.component("not-found", NotFound);

import DropdownDatepicker from './dropdown-datepicker/dropdown-datepicker';
Vue.component("dropdown-datepicker", DropdownDatepicker);

import SalaryInput from './elements/SalaryInput';
Vue.component("salary-input", SalaryInput);

import EmptyPage from './EmptyPage';
Vue.component('empty-page', EmptyPage);

import ClassifierName from '@/components/elements/ClassifierName';
Vue.component('classifier-name', ClassifierName);

import FormOption from '@/components/elements/FormOption';
Vue.component('form-option', FormOption);

import Editor from './elements/Editor';
Vue.component('editor', Editor);

import PhoneMask from './elements/PhoneMask';
Vue.component('phone-input', PhoneMask);


import GlobalPhoneMask from './elements/GlobalPhoneMask';
Vue.component('global-phone-input', GlobalPhoneMask);

import IsNotPaid from './cards/IsNotPaid';
Vue.component('isNotPaid', IsNotPaid);

import Loader from './elements/Loader';
Vue.component('v-loader', Loader);