export const state = {
  list: {
    data: [],
    pagination: {}
  },
  model: null,
  comments: [],
  shortList: [],
  shortListStep: [],
  actionHistories: [],
  autoselections: [],
  autoselectionsTest: {
    compatibleResumes: [],
    allResumes: [],
  },
  responses: [],
  spam_responses: [],
  allTags: []
}
