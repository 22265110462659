import axios from 'axios';
import { getToken, clearForLogout } from '@/utils/auth';
import { getItem, setItem } from '@/utils/storage'
import store from '@/store';
import router from '@/router';
import { errorsAlert, netwokErrorAlert } from './requestAlerts';
import i18n from "@/i18n";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, 
  timeout: 30000 // request timeout
});
function getUserIndex() {
  let index = getItem('user_index');
  if(!index){
    index = Math.ceil(Math.random() * (99999999999999999999 - 10000000000000000000) + 10000000000000000000);
    setItem('user_index', index);
  }
  return index;
}
service.interceptors.request.use(
  config => {
    loaderIncrease(config);
    let token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['X-USER-INDEX'] = getUserIndex();
    config.headers['X-USER-LANG'] = i18n.locale;
    if(window.Echo){
      config.headers['X-Socket-ID'] = window.Echo.socketId();
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    loaderDecrease(response.config);
    const res = response.data;
    if (response.status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    loaderDecrease(error.config);
    if(error && error.response && error.response.data && error.response.data.code && error.response.data.code == 441){
      if(router.currentRoute.name != 'SelectDevices'){
        router.push({ name: 'SelectDevices', query: router.currentRoute.name !='Login'?{redirect_url: router.currentRoute.fullPath}:{}});
      }else{
        router.push({ name: 'SelectDevices', query: router.currentRoute.name !='Login'?router.currentRoute.query:{}});
      }
      return Promise.reject(error.response);
    }
    if (error.response && (error.response.status == 401)) {
      if(error.response && error.response['data'] && error.response['data']['message'] && error.response['data']['message'] != "Unauthenticated."){
        errorsAlert(error.response);
      }
      logout();
    }else if(error.response){
      if(error.config.url.indexOf('/get-company-date/') < 0){
        errorsAlert(error.response);
      }
    }else{
      netwokErrorAlert(error);
    }
    return Promise.reject(error.response);
  }
);

function logout() {
  clearForLogout();
  if (router.currentRoute.name != 'Login') {
    return router.push({ name: 'Login' });
  }
}


const notLoader = [
  '/chat-messages',
  '/chat-messages/send',
  '/chat-messages/update-showed',
  '/chats',
  '/classifiers/skills',
];
function loaderIncrease(config) {
  if (notLoader.indexOf(config['url']) < 0 && !String(config['url']).indexOf('?nl=1')) {
    store.dispatch('loader/increase');
  }
}
function loaderDecrease(config) {
  if (notLoader.indexOf(config['url']) < 0 && !String(config['url']).indexOf('?nl=1')) {
    store.dispatch('loader/decrease');
  }
}

export default service;
