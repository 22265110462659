import request from '@/utils/request'

export function index(query) {
  return request({
    url: '/assessments',
    method: 'get',
    params: query
  })
}

export function show(id) {
  return request({
    url: '/assessments/' + id,
    method: 'get'
  })
}

export function store(role) {
  return request({
    url: 'assessments',
    method: 'post',
    data: role
  })
}

export function recursiveCheckObj(parentKey, value, formData){
  if(typeof value == 'object'){
    if(Array.isArray(value)){
      value.forEach((child, index) => {
        recursiveCheckObj(`${parentKey}[${index}]`, child, formData);
      });
    }else{
      Object.keys(value || {}).forEach(key => { 
        recursiveCheckObj(parentKey+`[${key}]`, value[key], formData);
      })
    }
  }else{
    formData.append(parentKey, value);
  }
}

export function update(data) {
  return request({
    url: 'assessments/' + data.id,
    method: 'put',
    data: data
  })
}

export function destroy(role_id) {
  return request({
    url: '/assessments/' + role_id,
    method: 'delete'
  })
}

// assessments questions

export function index_question(query) {
  return request({
    url: '/assessment-questions',
    method: 'get',
    params: query
  })
}

export function show_question(query) {
  return request({
    url: '/assessment-questions/',
    method: 'get',
    params: query
  })
}

// export function store_question(data) {
//   return request({
//     url: 'assessment-questions',
//     method: 'post',
//     data
//   })
// }
export function store_question(data) {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if(key != 'image'){
      recursiveCheckObj(key, data[key], formData);
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/assessment-questions',
    method: 'post',
    data: formData
  });
}

export function update_question(data) {
  if(!data['image']){
    delete data['image'];
  }
  return request({
    url: 'assessment-questions/' + data['id'],
    method: 'put',
    data
  })
}
// export function update_question(data) {
//   const formData = new FormData();
//   formData.append('_method', 'PUT');
//   Object.keys(data).forEach(key => {
//     if(key != 'image'){
//       recursiveCheckObj(key, data[key], formData);
//     }else{
//       if(data[key]){
//         formData.append(key, data[key]);
//       }
//     }
//   })
//   return request({
//     headers: {'Content-Type':'multipart/form-data'},
//     url: '/assessment-questions/'+data['id'],
//     method: 'post',
//     data: formData
//   });
// }

export function destroy_question(role_id) {
  return request({
    url: '/assessment-questions/' + role_id,
    method: 'delete'
  })
}


// candidate assessments
export function candidateAssessments(candidate_id) {
  return request({
    url: '/candidate/assessments/'+candidate_id,
    method: 'get'
  })
}
export function candidateSendAssessment(data) {
  return request({
    url: '/candidate/send-assessment',
    method: 'post',
    data
  })
}