export const state = {
  list: [],
  pagination: {},
  model: null,
  stepsList: [],
  myVacancies: {
    data: [],
    pagination: {}
  },
  responses: {
    data: [],
    pagination: {}
  },
  compatibles: {
    data: [],
    pagination: {}
  },
  my_vacancies_count:{
  },
  shortList: [],
  respond_item:{},
  respond_items:[],
  funnel: [],
  funnelAll: 0,
  utm_sources: []
}
