import request from '@/utils/request';


// SeekerEducation
export function storeSeekerEducation(data) {
  return request({
    url: '/moderator/seeker-education/'+data['user_id'],
    method: 'post',
    data
  });
}
export function updateSeekerEducation(data) {
  return request({
    url: '/moderator/seeker-education/'+data['id'],
    method: 'put',
    data
  });
}
export function deleteSeekerEducation(id) {
  return request({
    url: '/moderator/seeker-education/'+id,
    method: 'delete'
  });
}
// SeekerExperience
export function storeSeekerExperience(data) {
  return request({
    url: '/moderator/seeker-experience/'+data['user_id'],
    method: 'post',
    data
  });
}
export function updateSeekerExperience(data) {
  return request({
    url: '/moderator/seeker-experience/'+data['id'],
    method: 'put',
    data
  });
}
export function deleteSeekerExperience(id) {
  return request({
    url: '/moderator/seeker-experience/'+id,
    method: 'delete'
  });
}
// SeekerCourse
export function storeSeekerCourse(data) {
  return request({
    url: '/moderator/seeker-course/'+data['user_id'],
    method: 'post',
    data
  });
}
export function updateSeekerCourse(data) {
  return request({
    url: '/moderator/seeker-course/'+data['id'],
    method: 'put',
    data
  });
}
export function deleteSeekerCourse(id) {
  return request({
    url: '/moderator/seeker-course/'+id,
    method: 'delete'
  });
}