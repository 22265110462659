<template>
  <div class="notFoundPage">
    <div class="notFoundPageIco">
      <img src="/img/page-notfound.svg" alt="">
    </div>
    <div class="notFoundPageText" v-if="title">{{title}}</div>
    <div class="notFoundPageBtn" v-if="btnRoute && btnText">
      <router-link :to="btnRoute" class="btn btn-primary">
        <i v-if="btnIco" :class="btnIco"></i>
        {{btnText}}
      </router-link>
    </div>
    <div class="notFoundPageBtn" v-else-if="btnText">
      <button class="btn btn-primary" @click="$emit('btnClick', true)">
        <i v-if="btnIco" :class="btnIco"></i>
        {{btnText}}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: () => ""
      },
      btnText: {
        type: String,
        default: () => ""
      },
      btnRoute: {
        type: Object,
        default: () => null
      },
      btnIco: {
        type: String,
        default: () => ""
      }
    },
    computed: {},
    created() {},
    methods: {}
  };
</script>