import request from '@/utils/request';

export function update(data) {
  // const formData = new FormData();
  // formData.append('_method', 'PUT');
  // Object.keys(data).forEach(key => {
  //   if(Array.isArray(data[key])){
  //     data[key].forEach(item => {
  //       formData.append(`${key}[]`, item);
  //     })
  //   }else{
  //     formData.append(key, data[key]);
  //   }
  // })
  return request({
    // headers: {'Content-Type':'multipart/form-data'},
    url: '/company',
    method: 'put',
    data
  });
}

export function updateAsJson(data) {
  return request({
    url: '/company',
    method: 'put',
    data
  });
}

export function index(params) {
  return request({
    url: '/companies',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/companies/show/' + id,
    method: 'get'
  });
}

export function createCompanyApplication(data) {
  return request({
    url: '/company-applications',
    method: 'post',
    data
  });
}

export function saveHHTokens(data) {
  return request({
    url: '/company/save-tokens',
    method: 'put',
    data
  });
}

export function saveOutlookTokens(data) {
  return request({
    url: '/outlook/save-token',
    method: 'POST',
    data
  });
}