export default {
    data() {
        var ruleLength = (rule, value, callback) => {
            if (String(value).length != rule.number) {
                callback(new Error(this.$i18n.t(`Количество символов должно быть ${rule.number}`)));
            } else {
                callback();
            }
        };
        var ruleMinLength = (rule, value, callback) => {
            if (String(value).length < rule.number) {
                callback(new Error(this.$i18n.t(`Количество символов должно быть минимум ${rule.number}`)));
            } else {
                callback();
            }
        };
        var ruleMaxLength = (rule, value, callback) => {
            if (String(value).length > rule.number) {
                callback(new Error(this.$i18n.t(`Количество символов должно быть максимум ${rule.number}`)));
            } else {
                callback();
            }
        };
        var compare = (rule, value, callback) => {
            if (value != rule.pass) {
                callback(new Error(this.$i18n.t(`Пожалуйста, введите пароль еще раз`)));
            } else {
                callback();
            }
        };
        var required = (rule, value, callback) => {
            if (!value) {
                callback(new Error(this.$i18n.t(rule.msg)));
            } else {
                callback();
            }
        };
        var requiredNumber = (rule, value, callback) => {
            if (!Number(value) > 0) {
                callback(new Error(this.$i18n.t(rule.msg)));
            } else {
                callback();
            }
        };
        var requiredGender = (rule, value, callback) => {
            if (value === 0 || value === 1) {
                callback();
            } else {
                callback(new Error(this.$i18n.t(rule.msg)));
            }
        };
        var requiredRadio = (rule, value, callback) => {
            if (!value && !(typeof value == 'boolean')) {
                callback(new Error(this.$i18n.t(rule.msg)));
            } else {
                callback();
            }
        };
        var requiredList = (rule, value, callback) => {
            if (!(value && value.length)) {
                callback(new Error(this.$i18n.t(rule.msg)));
            } else {
                callback();
            }
        };
        var validURL = (str) => {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(str);
        }
        var requiredUrl = (rule, value, callback) => {
            if (value && !validURL(value)) {
                callback(new Error(this.$i18n.t(rule.msg)));
            } else {
                callback();
            }
        };
        var requiredIf = (rule, value, callback) => {
            if (!value && !rule.otherfield) {
                callback(new Error(this.$i18n.t(`Поля не может быть пустым`)));
            } else {
                callback();
            }
        };
        var requiredIfEqual = (rule, value, callback) => {
            if (!rule.otherfield) {
                callback(new Error(this.$i18n.t(`Поля не может быть пустым`)));
            } else if (rule.length < 150) {
                callback(new Error(this.$i18n.t(`Описание вакансии не может быть менее 150 символов`)));
            } else {
                callback();
            }
        };
        var email = (rule, value, callback) => {
            if (!this.validateEmail(value)) {
                callback(new Error(this.$i18n.t(rule.msg)));
            } else {
                callback();
            }
        };
        return {
            vRequiredIf: (otherfield) => {
                return { validator: requiredIf, otherfield: otherfield, trigger: 'blur' };
            },
            vRequiredIfEqual: (otherfield, length) => {
                return { validator: requiredIfEqual, otherfield: otherfield, length, trigger: 'blur' };
            },
            vRuleLength: (number, textNumber) => {
                return { validator: ruleLength, number: number, textNumber: (textNumber ? textNumber : number), trigger: 'blur' };
            },
            vRuleMinLength: (number) => {
                return { validator: ruleMinLength, number: number, trigger: 'blur' };
            },
            vRuleMaxLength: (number) => {
                return { validator: ruleMaxLength, number: number, trigger: 'blur' };
            },
            vConfomPass: (pass) => {
                return { validator: compare, pass: pass, trigger: 'blur' };
            },
            vRequired: { validator: required, msg: 'Поле не может быть пустым' },
            vRequiredNumber: { validator: requiredNumber, msg: 'Поле не может быть пустым' },
            vRequiredGender: { validator: requiredGender, msg: 'Поле не может быть пустым' },
            vRequiredList: { validator: requiredList, msg: 'Пожалуйста, выберите хотя бы одно значение' },
            vRequiredRadio: { validator: requiredRadio, msg: 'Пожалуйста, выберите одно значение' },
            vEmail: { validator: email, msg: 'Введите в формате адрес@ящик.домен', trigger: 'blur' },
            vNumeric: { validator: required, type: 'number', msg: 'Поле должно быть числовым' },
            vRequiredTerm: { validator: required, msg: 'Подтвердите ваше согласие' },
            vUrl: { validator: requiredUrl, type: 'url', msg: 'Пожалуйста, введите корректную ссылку', trigger: 'blur' }
        };
    },
    methods: {
        validateEmail(inputText) {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
            if (inputText.match(mailformat))
                return true;
            else
                return false;
        }
    },
};