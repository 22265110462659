import { 
  applicants,
  recruiters,
  store,
  show,
  update,
  destroy,
  workers,
  accounts,
  deleteFromAccount
} from '@/api/workers';

export const actions = {
  getWorkers({ commit }) {
    return new Promise((resolve, reject) => {
      workers()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getApplicants({ commit }, ) {
    return new Promise((resolve, reject) => {
      applicants()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_APPLICANTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRecruiters({ commit }, params) {
    return new Promise((resolve, reject) => {
      recruiters(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_RECRUITERS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, data) {
    return new Promise((resolve, reject) => {
      show(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  destroy({ commit }, id) {
    return new Promise((resolve, reject) => {
      destroy(id)
        .then(res => {
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data['id'], data['data'])
        .then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  },
  getAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      accounts()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_ACCOUNTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteFromAccount({ commit }, company_user_id) {
    return new Promise((resolve, reject) => {
      deleteFromAccount(company_user_id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
