import { 
  index,
  store,
  update,
  show,
  autoselection,
  comments,
  commentCreate,
  shortListByStepId,
  addCandidate,
  shortList,
  vacancyCompatible,
  actionHistories,
  reserved,
  blocked,
  vacancyResponses,
  allTags,
  rejectResponses,
  parseCv,
  updateFormData,
  autoselectionTest,
  applicantConfirm,
  sendSms,
  rejectNotification
} from '@/api/candidates';

export const actions = {
  list({ commit }, params) {
    return new Promise((resolve, reject) => {
      index(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          if(res['data']){
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data['id'], data['data'])
        .then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  },
  updateFormData({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateFormData(data['id'], data['data'])
        .then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  autoselection({ commit }, data) {
    return new Promise((resolve, reject) => {
      autoselection(data['vacancy_id'], data['data'])
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_AUTO_SELECTIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  autoselectionTest({ commit }, id) {
    return new Promise((resolve, reject) => {
      autoselectionTest(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_AUTO_SELECTIONS_TEST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getComments({ commit }, id) {
    return new Promise((resolve, reject) => {
      comments(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_COMMENTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  commentCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      commentCreate(data['candidate_id'], data['data'])
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_COMMENTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getShortListByStepId({ commit }, stepId) {
    return new Promise((resolve, reject) => {
      shortListByStepId(stepId)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_SHORT_LIST_STEP', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addCandidate({ commit }, data) {
    return new Promise((resolve, reject) => {
      addCandidate(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  shortList({ commit }, data) {
    return new Promise((resolve, reject) => {
      shortList(data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_SHORT_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  vacancyCompatible({ commit }, data) {
    return new Promise((resolve, reject) => {
      vacancyCompatible(data['vacancy_id'], data['profile_id'])
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getActionHistories({ commit }, params) {
    return new Promise((resolve, reject) => {
      actionHistories(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_ACTION_HISTORIES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  reserved({ commit }, params) {
    return new Promise((resolve, reject) => {
      reserved(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  blocked({ commit }, params) {
    return new Promise((resolve, reject) => {
      blocked(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  vacancyResponses({ commit }, params) {
    return new Promise((resolve, reject) => {
      vacancyResponses(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_RESPONSES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  vacancySpamResponses({ commit }, params) {
    return new Promise((resolve, reject) => {
      vacancyResponses(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_SPAM_RESPONSES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllTags({ commit }) {
    return new Promise((resolve, reject) => {
      allTags()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_ALL_TAGS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  rejectResponses({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectResponses(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  parseCv({ commit }, file) {
    return new Promise((resolve, reject) => {
      parseCv(file)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sortResumes({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('RESUMES_SORT', params);
      resolve(true);
    });
  },
  applicantConfirm({ commit }, data) {
    return new Promise((resolve, reject) => {
      applicantConfirm(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendSms({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendSms(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  rejectNotification({ commit }, data) {
    return rejectNotification(data);
  },
};
