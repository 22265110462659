import { setItem } from '@/utils/storage';
import { NotificationsSubscriber, NotificationsUnSubscriber } from '@/plugins/websocket';

export const mutations = {
  SET_USER: (state, data) => {
    state.user = data;
    state.role_name = data && data.role?data.role: "";
    state.statistics = state.user['profile']?state.user['profile']['statistics']:null;
    if (state.user) {
      NotificationsSubscriber(state.user);
      setItem('user', state.user);
    }
  },
  SET_IS_AUTH: (state, value) => {
    state.is_auth = value;
  },
  SET_STATISTICS: (state, data) => {
    if(state.statistics){
      state.statistics = {...state.statistics, ...data};
    }else{
      state.statistics = data;
    }
  },
  UNSUBSCRIBE_NOTIFICATIONS: (state, type) => {
    NotificationsUnSubscriber(state.user);
  },
  SET_INCREASE_VACANCY_RESPONSE: (state, data) => {
    if(state.statistics){
      let statVacancyResponse = null;
      if(state.statistics['vacancy_responses'] && state.statistics['vacancy_responses'].length){
        statVacancyResponse = state.statistics['vacancy_responses'].find(item => item.vacancy_id == data['vacancy_id']);
        if(statVacancyResponse){
          statVacancyResponse.new_count++;
        }else{
          data['new_count'] = 1;
          state.statistics['vacancy_responses'].push(data)
        }
      }else{
        data['new_count'] = 1;
        state.statistics['vacancy_responses'] = [data];
      }
    }else{
      data['new_count'] = 1;
      state.statistics = {
        "vacancy_responses":[data]
      };
    }
  },
  SET_DECREASE_VACANCY_RESPONSE: (state, vacancy_id) => {
    if(state.statistics){
      if(state.statistics['vacancy_responses']){
        state.statistics['vacancy_responses'] = state.statistics['vacancy_responses'].map(item => {
          if(item.vacancy_id == vacancy_id){
            item.new_count--;
          }
          return item;
        })
        
        state.statistics['vacancy_responses'] = state.statistics['vacancy_responses'].filter(item => {
          return item.new_count > 0;
        })
      }
    }
  },
};