import { update, index, show, createCompanyApplication, updateAsJson, saveHHTokens, saveOutlookTokens } from '@/api/company';
import { plans, getPlan, invoiceCreate, getInvoice, invoiceCreateForPayme, companyInfoByTin, getInvoiceHistory, getInvoicePdf, unpaidInvoicesByPlan,
  contractCreate,
  contractShow,
  invoiceDetails,
  contractPdf,
  myContracts,
  invoiceCreateForRecruiters,
  billingCustomPlans,
  invoiceCreateForResumes,
  invoiceCreateForVacancies
} from '@/api/billing';

export const actions = {
  billingCustomPlans({ commit }) {
    return billingCustomPlans();
  },
  invoiceCreateForResumes({ commit },data) {
    return invoiceCreateForResumes(data);
  },
  invoiceCreateForVacancies({ commit },data) {
    return invoiceCreateForVacancies(data);
  },
  index({ commit }, data) {
    return new Promise((resolve, reject) => {
      index(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAsJson({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateAsJson(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCompanyInfoByTin({ commit }, tin) {
    return new Promise((resolve, reject) => {
      companyInfoByTin(tin)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  plans({ commit }, data) {
    return new Promise((resolve, reject) => {
      plans(data)
        .then(res => {
          commit('SET_PLANS', res.data)
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPlan({ commit }, data) {
    return new Promise((resolve, reject) => {
      getPlan(data)
        .then(res => {
          commit('SET_PLAN', res.data)
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  invoiceCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      invoiceCreate(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getInvoice({ commit }, id) {
    return new Promise((resolve, reject) => {
      getInvoice(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  invoiceCreateForPayme({ commit }, id) {
    return new Promise((resolve, reject) => {
      invoiceCreateForPayme(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getInvoiceHistory({ commit }, id) {
    return new Promise((resolve, reject) => {
      getInvoiceHistory(id)
        .then(res => {
          if(res && res.data){
            commit('SET_INVOICES', res.data)
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getInvoicePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      getInvoicePdf(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getUnpaidInvoicesByPlan({ commit }, id) {
    return new Promise((resolve, reject) => {
      unpaidInvoicesByPlan(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  contractCreate({ commit }, data) {
    return new Promise((resolve, reject) => {
      contractCreate(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  invoiceDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      invoiceDetails(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  contractPdf({ commit }, data) {
    return new Promise((resolve, reject) => {
      contractPdf(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMyContracts({ commit }, ) {
    return new Promise((resolve, reject) => {
      myContracts()
        .then(res => {
          if(res && res.data){
            commit('SET_CONTRACTS', res.data)
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCompanyApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      createCompanyApplication(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveHHTokens({ commit }, data) {
    return new Promise((resolve, reject) => {
      saveHHTokens(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  invoiceCreateForRecruiters({ commit }, data) {
    return new Promise((resolve, reject) => {
      invoiceCreateForRecruiters(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveOutlookTokens({ commit }, data) {
    return new Promise((resolve, reject) => {
      saveOutlookTokens(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
