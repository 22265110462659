import {
  show, index, resumePdf, resumesStat, update, store, resumeCVPdf, resumeCV
} from '@/api/resumes'

export const actions = {
  getList({ commit }, query) {
    let params = {...{}, ...query};
    if(params['category_id']) params['category_id'] = [params['category_id']]; 
    if(params['childCategory']) params['category_id'] = [params['childCategory']]; 
    return new Promise((resolve, reject) => {
      index(params).then(res => {
        commit('SET_LIST', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show({ commit }, params) {
    return new Promise((resolve, reject) => {
      show(params['id'], params['vacancy_id']).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resumesStat({ commit }) {
    return new Promise((resolve, reject) => {
      resumesStat().then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resumePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      resumePdf(id).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resumeCVPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      resumeCVPdf(id).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resumeCV({ commit }, params) {
    return new Promise((resolve, reject) => {
      resumeCV(params).then(res => {
        commit('SET_CV', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
}
