import { 
  vacancyApplications,
  createVacancyApplication,
  updateVacancyApplication,
  deleteVacancyApplication,
  showVacancyApplication,
  vacancyApplicationCount,
  vacancyApplicationAllCounts,
  rejectOrder,
  confirmOrder
} from '@/api/orders';

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      vacancyApplications(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      showVacancyApplication(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createVacancyApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      createVacancyApplication(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateVacancyApplication({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateVacancyApplication(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteVacancyApplication({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteVacancyApplication(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  vacancyApplicationCount({ commit }) {
    return new Promise((resolve, reject) => {
      vacancyApplicationCount()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_COUNT', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  vacancyApplicationAllCounts({ commit }) {
    return new Promise((resolve, reject) => {
      vacancyApplicationAllCounts()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  rejectOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      rejectOrder(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  confirmOrder({ commit }, id) {
    return new Promise((resolve, reject) => {
      confirmOrder(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
