const routes = [
  {
    path: "funnel/:id",
    name: "Funnel",
    meta: { module: 'recruiting', middleware: 'Auth' },
    component: () =>
      import("@/views/Report/Funnel")
  },
  {
    path: "report",
    name: "Report",
    redirect: { name: "ReportRecruiters" },
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Report/Index"),
    children: [
      {
        path: "recruiters",
        name: "ReportRecruiters",
        meta: { middleware: 'Auth', savePosition: true },
        component: () =>
          import("@/views/Report/Recruiters")
      },
      {
        path: "vacancies",
        name: "ReportVacancies",
        meta: { middleware: 'Auth', savePosition: true },
        component: () =>
          import("@/views/Report/Vacancies")
      },
      {
        path: "tracking",
        name: "ReportTracking",
        meta: { middleware: 'Auth', savePosition: true },
        component: () =>
          import("@/views/Report/Tracking")
      }
    ]
  }
]

export default routes;
