export const getters = {
  LIST: (state) => state.list,
  FULL_LIST: (state) => state.fullList,
  VACANCY_STEPS: (state) => state.list.map(step => step.step_id),
  SHORT_LIST: (state) => state.shortList,
  STEP_LIST_WITH_CANDIDATES: (state) => state.step_list_with_candidates,

  DYNAMICS: (state) => state.dynamics,
  FINISHEDS: (state) => state.finisheds,
  DECLINEDS: (state) => state.declineds,
  BOARDS_LIST: (state) => state.boardsList,
  FUNNEL: (state) => state.funnel,
}
