import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth';
import loader from './loader';
import classifiers from './classifiers';
import app from './app';
import vacancies from './vacancies';
import companies from './companies';
import chat from './chat';
import workers from './workers';
import candidates from './candidates';
import resume from './resume';
import steps from './steps'
import emails from './emails'
import candidateDetails from './candidateDetails'
import events from './events'
import assessment from './assessment'
import reports from './reports'
import orders from './orders'
import folders from './folders'

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    classifiers,
    app,
    vacancies,
    companies,
    chat,
    workers,
    candidates,
    resume,
    steps,
    emails,
    candidateDetails,
    assessment,
    events,
    reports,
    orders,
    folders
  }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy['auth']['is_auth'] = false;
  initialStateCopy['auth']['user'] = null;
  initialStateCopy['auth']['role_name'] = "";
  initialStateCopy['auth']['statistics'] = null;
  initialStateCopy['classifiers']['formOptions'] = storeData['classifiers']['formOptions'];
  initialStateCopy['classifiers']['errors'] = storeData['classifiers']['errors'];
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}