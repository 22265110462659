import request from '@/utils/request';

export function workers() {
  return request({
    url: '/company/workers',
    method: 'get',
  });
}

export function applicants() {
  return request({
    url: '/company/applicants',
    method: 'get',
  });
}

export function recruiters(params) {
  return request({
    url: '/company/recruiters',
    method: 'get',
    params
  });
}

export function store(data) {
  return request({
    url: '/company/create-worker',
    method: 'post',
    data
  });
}

export function update(id, data) {
  return request({
    url: '/company/update-worker/' + id,
    method: 'PUT',
    data
  });
}

export function destroy(id) {
  return request({
    url: '/company/delete-worker/' + id,
    method: 'delete',
  });
}

export function show(user_id) {
  return request({
    url: `/company/show-worker/${user_id}`,
    method: 'get',
  });
}

export function accounts() {
  return request({
    url: `/company/company-accounts`,
    method: 'get',
  });
}

export function deleteFromAccount(company_user_id) {
  return request({
    url: `/company/set-empty-company-user/${company_user_id}`,
    method: 'put',
  });
}