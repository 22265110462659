import request from '@/utils/request';

export function index(params) {
  let data = {};
  Object.keys(params).forEach(key => {
    if(Array.isArray(params[key])){
      if(params[key].length){
        data[key] = params[key];
      }
    }else if(params[key]){
      if(key == 'position_name'){
        data['full_text'] = params[key];
      }else{
        data[key] = params[key];
      }
    }
  })
  return request({
    url: '/resumes/filter',
    method: 'post',
    data
  });
}

export function store(data) {
  return request({
    url: '/resumes',
    method: 'post',
    data
  });
}

export function show(id, vacancy_id) {
  let url = '/resumes/' + id;
  if(vacancy_id){
    url = url+'?vacancy_id='+vacancy_id;
  }
  return request({
    url: url,
    method: 'get'
  });
}

export function update(data) {
  return request({
    url: '/resumes/'+data['id'],
    method: 'put',
    data
  });
}

export function resumePdf(id) {
  return request({
    url: '/pdf/' + id,
    method: 'get'
  });
}

export function resumesStat() {
  return request({
    url: '/get-statistics-resume',
    method: 'get'
  });
}

export function resumeCVPdf(id) {
  return request({
    url: '/resumes/pdf/' + id,
    method: 'get'
  });
}

export function resumeCV(params) {
  let url = '/resumes-show/' + params['id'];
  if(params['vacancy_id']){
    url = url+'?vacancy_id='+params['vacancy_id'];
  }
  return request({
    url: url,
    method: 'get'
  });
}