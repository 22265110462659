export const getters = {
  LIST: (state) => state.list,
  PAGINATION: (state) => state.pagination,
  MODEL: (state) => state.model,
  STEPS_LIST: (state) => state.stepsList,
  MY_VACANCIES: (state) => state.myVacancies.data,
  MY_VACANCIES_PAGINATION: (state) => state.myVacancies.pagination,
  RESPONSES: (state) => state.responses,
  GET_COMPATIBLES: (state) => state.compatibles,
  GET_MY_VACANCIES_COUNT: (state) => state.my_vacancies_count,
  SHORT_LIST: (state) => state.shortList,
  GET_RESPOND_ITEM: (state) => state.respond_item,
  GET_RESPOND_ITEMS: (state) => state.respond_items,
  FUNNEL: (state) => state.funnel,
  FUNNEL_ALL: (state) => state.funnelAll,
  SET_UTM_LIST: (state) => state.utm_sources
}
