import {getPercentage,monthToYears} from '@/utils/index'
import store from '@/store';
import { orderBy } from '@/utils';

export const mutations = {
  SET_LIST: (state, items) => {
    state.list = orderBy(items.map(item => {
      if(item.step && item.step.id == 1) item['order'] = 0;
      if(!item['order']) item['order'] = 1;
      item['type'] = item['step']?item['step']['type']:null;
      item['fixed'] = item['step']?item['step']['fixed']:null;
      return item;
    }),'order');
    state.dynamics = state.list.filter(item =>  (item['type'] != 'finished' && item['type'] != 'declined'));
    state.finisheds = state.list.filter(item => item['type'] == 'finished');
    state.declineds = state.list.filter(item => item['type'] == 'declined');
  },
  SET_SHORT_LIST: (state, items) => {
    state.shortList = orderBy(items.map(item => {
      if(item.step && item.step.id == 1) item['order'] = 0;
      if(!item['order']) item['order'] = 1;
      item['type'] = item['step']?item['step']['type']:null;
      item['fixed'] = item['step']?item['step']['fixed']:null;
      if(item.type == 'finished') item.order = 10000;
      if(item.type == 'declined') item.order = 10001;
      return item;
    }), 'order');
  },
  SET_STEP_WITH_CANDIDATES_LIST: (state, items) => {
    state.step_list_with_candidates = orderBy(items.map(el => {
      if(el.step && el.step.id == 1) el['order'] = 0;
      if(!el['order']) el['order'] = 1;
      return {
        candidates: mapCandidate(el.candidates),
        candidates_count: el.candidates_count,
        created_at: el.created_at,
        fixed: el.step?el.step.fixed:el.fixed,
        id: el.id,
        name: el.name,
        step: el.step,
        order: el.order,
        step_id: el.step_id,
        type: el.step?el.step.type:el.type,
        updated_at: el.updated_at,
        vacancy_id: el.vacancy_id,
      }
    }),'order');

    state.dynamics = state.step_list_with_candidates.filter(item =>  (item['type'] != 'finished' && item['type'] != 'declined'));
    state.finisheds = state.step_list_with_candidates.filter(item => item['type'] == 'finished');
    state.declineds = state.step_list_with_candidates.filter(item => item['type'] == 'declined');
    let roleName = store.getters['auth/ROLE_NAME'];
    var staticSteps = [];
    if(roleName != 'applicant'){
      staticSteps = [{
        name: "Автоподбор",
        order: 0,
        type: "autoselect"
      },{
        name: "Отклики",
        order: 0,
        type: "response"
      }]  
    }
    state.boardsList = staticSteps.concat(state.dynamics)
    .concat(state.finisheds)
    .concat(state.declineds);
  },
  SET_FULL_LIST: (state, data) => {
    if (data && data.length) {
      state.fullList = data
    }
  },
  SET_FUNNEL: (state, data) => {
    data = data.map(item => {
      item['type'] = item['step']?item['step']['type']:null;
      item['fixed'] = item['step']?item['step']['fixed']:null;
      return item;
    });
    let dynamics = data.filter(item =>  (item['type'] != 'finished' && item['type'] != 'declined'));
    let finisheds = data.filter(item => item['type'] == 'finished');
    let items = dynamics.concat(finisheds)
    let prevItem;
    state.funnel = items.reverse().map(item => {
      let newItem = {
        count: item.candidate_ids.length,
        step: item['step']
      } 
      if(prevItem && newItem.count < prevItem.count){
        newItem.count = prevItem.count;
      }
      prevItem = newItem;
      return newItem;
    }).reverse()
    state.funnel = state.funnel.map((item,index) => {
      let opacity = (80/state.funnel.length);
      item['percentage'] = getPercentage(prevItem.count, item.count);
      item['opacity'] = (20+(opacity*(index+1)))/100;
      return item;
    });
    // let declineds = data.filter(item => item['type'] == 'declined');
  }
};

function mapCandidate(candidates){
  if (candidates && candidates.length) {
    return candidates.map(item => {
      if (item.seeker_profile) {
        return {
          type: 'candidate',
          profile_sourse: 'resume',
          id: item.id,
          profile_id: item.seeker_profile.id,
          profile_tags: item.seeker_profile.profile_tags,
          avatar: item.seeker_profile.avatar,
          last_name: item.seeker_profile.last_name,
          first_name: item.seeker_profile.first_name,
          created_at: item.created_at,
          age: item.seeker_profile.age,
          applicant_confirmed: item.applicant_confirmed===true?1:(item.applicant_confirmed===false?2:0),
          wanted_salary: item.seeker_profile.wanted_salary,
          salary_currency: item.seeker_profile.salary_currency,
          position: item.seeker_profile.position,
          position_name: item.seeker_profile.position_name,
          wanted_work: item.seeker_profile.wanted_work,
          latest_experience: item.seeker_profile.latest_experience ? item.seeker_profile.latest_experience : null,
          latest_education: item.seeker_profile.latest_education ? item.seeker_profile.latest_education : null,
          experience_period: monthToYears(item.seeker_profile.experience_month),
          experience_month: item.seeker_profile.experience_month,
          resume_id: null,
          resume_source: null,
          waiting_time_expired: item.waiting_time_expired,
          region: item.seeker_profile.region,
          starting_work: item.seeker_profile.starting_work,
        }
      }else if(item.resume){
        return {
          type: 'candidate',
          profile_sourse: 'cv',
          id: item.id,
          is_showed: true,
          avatar: item.resume.avatar,
          last_name: item.resume.last_name,
          first_name: item.resume.first_name,
          created_at: item.created_at,
          applicant_confirmed: item.applicant_confirmed===true?1:(item.applicant_confirmed===false?2:0),
          age: item.resume.birth_date?_calculateAge(item.resume.birth_date):item.resume.age,
          wanted_salary: item.resume.wanted_salary,
          wanted_work: true,
          region: item.resume.region,
          salary_currency: item.resume.additional_info?item.resume.additional_info['wanted_salary_currency']:'',
          position_name: item.resume.position_name,
          latest_experience: getCvLatestExperience(item.resume),
          experience_period: item.resume.experience_month?monthToYears(item.resume.experience_month):null,
          experience_month: item.resume.experience_month,
          resume_id: item.resume.id,
          resume_source: item.resume.source,
          waiting_time_expired: item.waiting_time_expired
        }
      }
      return {}
    })    
  } else {
    return []
  }
}

function _calculateAge(birthday) { // birthday is a date
  if(birthday){
    var ageDifMs = Date.now() - (new Date(birthday)).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  return 0;
}

function getCvLatestExperience(resume){
    if(resume){
        if(resume.latest_experience){
            return resume.latest_experience;
        }else if(resume.additional_info && resume.additional_info['experiences'] && resume.additional_info['experiences'].length){
            var current_job = resume.additional_info['experiences'].find(item => !item.end_date);
            if(current_job){
                return current_job;
            }
        }
    }
    return null;
}