import { 
  list,
  create,
  update,
  destroy,
  updateOrders,
  fullList,
  stepsWithCandidates,
  updateStep,
  destroyStep,
  stepsFunnel
} from '@/api/steps';

export const actions = {
  list({ commit }, vacancyId) {
    return new Promise((resolve, reject) => {
      list(vacancyId)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  shortList({ commit }, vacancyId) {
    return new Promise((resolve, reject) => {
      list(vacancyId)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_SHORT_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  stepsWithCandidates({ commit }, vacancyId) {
    return new Promise((resolve, reject) => {
      stepsWithCandidates(vacancyId)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_STEP_WITH_CANDIDATES_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      create(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateOrders(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  destroy({ commit }, data) {
    return new Promise((resolve, reject) => {
      destroy(data)
        .then(res => {
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data['id'], data['data'])
        .then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  },
  fullList({ commit }) {
    return new Promise((resolve, reject) => {
      fullList()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_FULL_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
  updateStep({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateStep(data['id'], data['data'])
        .then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        })
    })
  },
  destroyStep({ commit }, id) {
    return new Promise((resolve, reject) => {
      destroyStep(id)
        .then(res => {
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  getStepsFunnel({ commit }, id) {
    return new Promise((resolve, reject) => {
      stepsFunnel(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_FUNNEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    })
  },
};
