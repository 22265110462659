export const getters = {
  LIST: (state) => state.list.data,
  LIST_PAGINATION: (state) => state.list.pagination,
  MODEL: (state) => state.model,
  GET_COMMENTS: (state) => state.comments,
  SHORT_LIST: (state) => state.shortList,
  SHORT_LIST_STEP: (state) => state.shortListStep,
  ACTION_HISTORIES: (state) => state.actionHistories,
  AUTO_SELECTIONS: (state) => state.autoselections,
  RESPONSES: (state) => state.responses,
  ALL_TAGS: (state) => state.allTags,
}
