export default {
  data() {
    return {
      screenWidth: window.innerWidth
    }
  },
  computed: {
    isNavMobile(){
      return navigator && navigator.userAgentData?navigator.userAgentData.mobile:false;
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeScreen);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeScreen);
  },
  methods: {
    resizeScreen(){
      this.screenWidth = window.innerWidth;
    },
  },
};
