import request from '@/utils/request';

export function list(vacancyId) {
  return request({
    url: '/vacancy-steps/'+vacancyId,
    method: 'get'
  });
}

export function stepsWithCandidates(vacancyId) {
  return request({
    url: '/vacancy-steps-with-candidates/'+vacancyId,
    method: 'get'
  });
}

export function create(data) {
  return request({
    url: '/vacancy-steps',
    method: 'post',
    data
  });
}
export function update(id, data) {
  return request({
    url: '/vacancy-steps/'+id,
    method: 'put',
    data
  });
}
export function destroy(data) {
  return request({
    url: '/vacancy-steps',
    method: 'delete',
    data
  });
}
export function updateOrders(data) {
  return request({
    url: '/vacancy-steps/update-order',
    method: 'post',
    data
  })
}
export function fullList() {
  return request({
    url: '/steps',
    method: 'get'
  });
}
export function updateStep(id, data) {
  return request({
    url: '/steps/'+id,
    method: 'put',
    data
  });
}
export function destroyStep(id) {
  return request({
    url: '/steps/' + id,
    method: 'delete'
  });
}
export function stepsFunnel(id) {
  return request({
    url: '/vacancy-steps-with-candidate-ids/' + id,
    method: 'get'
  });
}