import Auth from './Auth'
import AuthAdmin from './AuthAdmin'
import AuthApplicant from './AuthApplicant'
import AuthRecruiter from './AuthRecruiter'
import AuthCompany from './AuthCompany'

export default {
  Auth,
  AuthAdmin,
  AuthApplicant,
  AuthRecruiter,
  AuthCompany
}