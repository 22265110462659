import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/company-events',
    method: 'get',
    params
  });
}

export function store(data) {
  return request({
    url: '/company-events',
    method: 'post',
    data
  });
}

export function update(data) {
  return request({
    url: '/company-events/'+data['id'],
    method: 'PUT',
    data
  });
}