<template>
  <div id="app">
    <router-view/>
    <audio id="notification">
      <source src="/file/notification.wav" type="audio/mpeg">
    </audio>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NotificationsSubscriber } from '@/plugins/websocket';
import { getToken, checkTokenTime } from "@/utils/auth";
export default {
  data(){
    return {
    }
  },
  created() {
    this.getClassifier('errors');
    this.getFormOptions()
  },
  mounted(){
    this.cacheClear();
    this.checkAuth();
  },
  watch: {},
  computed: {},
  methods: {
    ...mapActions({
      getFormOptions: 'classifiers/getFormOptions',
      getCurrentUser: 'auth/currentUser',
    }),
    cacheClear(){
      if(caches){
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        console.log('cache deleted')
      }
    },
    checkAuth(){
      let token = getToken();
      if(token && checkTokenTime()){
        this.getCurrentUser().then((user) => {
          this.checkRightPro()
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import './styles.scss';
@import './assets/styles/media.scss';
</style>
