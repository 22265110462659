import { 
  list,
  store,
  update
} from '@/api/events';

export const actions = {
  list({ commit }, params) {
    commit('SET_LIST', []);
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
