export const state = {
  chats: {
    data: [],
    pagination: {}
  },
  messages: {
    data: [],
    pagination: {}
  },
  selectChat: null,
  notShowed: false,
}
