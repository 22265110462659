<template>
  <div class="notPaid">
    <div class="notPaidInner">
      <div class="notPaidCont">
        <div class="notPaidContIco">
          <img src="/img/icons/Shield_Fail.svg" alt="">
        </div>
        <div class="notPaidContText mb-5" v-html="text">
        </div>
        <div class="notPaidBtn">
          <router-link :to="{name:'BillingTariff'}">
            <button class="btn btn-primary" style="width:200px;">{{$t('Выбрать тариф')}}</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text']
}
</script>
<style>
.notPaid{
  max-height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.notPaidCont{
  max-width: 500px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 6px 40px rgba(39, 50, 134, 0.08);
  border-radius: 10px;
  padding: 50px;
  margin-bottom: 30px;
}
.notPaidContIco{
  width: 84px;
  height: 74px;
  background: #ECF0F4;
  border-radius: 10px;
  line-height: 72px;
  margin: 0 auto 30px;
}
.notPaidContIco img{
  max-width: 80%;
  max-height: 90%;
}
.notPaidContText{
  /* opacity: 0.6; */
  font-size: 18px;
  font-weight: 500;
}
@media screen and (max-width: 400px) {
  .notPaid{
    height: auto;
    min-height: 0px;
    padding: 30px 0 80px;
  }
  .notPaidCont{
    padding: 35px 14px 30px;
    margin-bottom: 30px;
  }
  .notPaidContIco{
    width: 54px;
    height: 48px;
    line-height: 46px;
    margin-bottom: 20px;
  }
  .notPaidContIco img{
    max-width: 57%;
    max-height: 65%;
  }
  .notPaidContText{
    font-size: 14px;
  }
}
</style>