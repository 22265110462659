import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
import './plugins/ElementUi'
import './plugins/Directives'
import './plugins/Filters'
import './plugins/websocket'
import './plugins/xlsx'
import './plugins/metaInfo'
import './plugins/moment'
import './plugins/maps'
import './mixins';
import './components';
import { getItem, setItem } from "@/utils/storage";

Vue.config.productionTip = false


window.onerror = function(msg, url, lineNo, columnNo, error) {
  let err_msg = "Uncaught SyntaxError: Unexpected token '<'";
  let err_url = "/js/chunk-";
  let currentDate = Math.floor(Date.now() / 1000);
  let chunk_err = getItem("chunk_err_date");

  let message = [
      "Message: " + msg,
      "URL: " + url,
      "Line: " + lineNo,
      "Column: " + columnNo,
      "Error object: " + JSON.stringify(error),
  ].join(" - ");
  console.log("window.onerror", message);

  if (msg.includes(err_msg)) {
      let secund = 5; // 5 - minutes
      if (chunk_err && chunk_err + secund < currentDate) {
          setItem("chunk_err_date", currentDate);
          location.reload();
      } else if (!chunk_err) {
          setItem("chunk_err_date", currentDate);
          location.reload();
      }
  }
};

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
