import moment from 'moment';
import {orderBy} from '@/utils'
export const mutations = {
  SET_APPLICANTS: (state, data) => {
    state.applicants = data;
  },
  SET_RECRUITERS: (state, data) => {
    state.recruiters = data;
  },
  SET_LIST: (state, data) => {
    state.list = data;
  },
  SET_ACCOUNTS: (state, data) => {
    var currentDate = moment().format('YYYY-MM-DD');
    state.accounts = orderBy(data.filter(item => item.role != 'company').map(item => {
      var endDate = (new Date(item.end_date)).getTime();
      var currDate = (new Date(currentDate)).getTime();
      item['access_period'] = endDate >= currDate;
      item['order'] = item.company_user_id?1:9999999999;
      return item;
    }), 'order');
  },
};