const routes = [
  {
    path: 'company/home',
    name: 'Company',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Company/Index"),
  },{
    path: 'company/profile',
    name: 'CompanyProfile',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Company/Profile"),
  },{
    path: 'auth/outlook',
    name: 'AuthOutlook',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Company/Profile"),
  },{
    path: 'chat',
    name: 'CompanyChat',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/components/Chat/Main"),
  },{
    path: 'applicants',
    name: 'Applicants',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Workers/Applicants"),
  },{
    path: 'applicants/create',
    name: 'ApplicantsCreate',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Workers/Forms"),
  },{
    path: 'applicants/edit/:id',
    name: 'ApplicantsEdit',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Workers/Forms"),
  },{
    path: 'recruiters',
    name: 'Recruiters',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Workers/Accounts"),
  },{
    path: 'recruiters/create',
    name: 'RecruitersCreate',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Workers/Forms"),
  },{
    path: 'recruiters/edit/:id',
    name: 'RecruitersEdit',
    meta: { middleware: 'Auth' },
    component: () =>
      import("@/views/Workers/Forms"),
  },{
    path: 'vacancy/show/:id',
    name: 'VacancyShow',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/components/vacancyShow"),
  },{
    path: 'vacancy/report/:id',
    name: 'VacancyReport',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Report/VacancyReport"),
  },{
    path: 'billing/tariff',
    name: 'BillingTariff',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/tariffs.vue"),
  },{
    path: 'billing/create-invoice/:id',
    name: 'BillingCreateAccount',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/CreateInvoice.vue"),
  },{
    path: 'billing/payment-types/:uuid',
    name: 'BillingPayment',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/Payment.vue"),
  },{
    path: 'billing/payment-history',
    name: 'BillingHistoryPayment',
    redirect: { name: "BillingContractInvoices" },
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/HistoryPayment.vue"),
  },{
    path: 'billing/contracts',
    name: 'BillingContracts',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/Contracts.vue"),
  },{
    path: 'billing/contract-invoives',
    name: 'BillingContractInvoices',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/ContractInvoices.vue"),
  },{
    path: 'billing/payment-success/:uuid',
    name: 'BillingPaymentSuccess',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Billing/PaymentSuccess.vue"),
  },{
    path: 'calendar',
    name: 'Calendar',
    meta: { middleware: 'AuthCompany' },
    component: () =>
    import("@/views/Calendar/Index.vue"),
  },{
    path: 'my-orders',
    name: 'MyOrders',
    meta: { middleware: 'AuthApplicant' },
    component: () =>
    import("@/views/Orders/MyOrders.vue"),
  },{
    path: 'create-order',
    name: 'CreateOrder',
    meta: { middleware: 'AuthApplicant' },
    component: () =>
    import("@/views/Orders/Form.vue"),
  },{
    path: 'orders',
    name: 'Orders',
    meta: { middleware: 'Auth' },
    component: () =>
    import("@/views/Orders/MyOrders.vue"),
  }
]

export default routes;
