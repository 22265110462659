import { monthToYears } from '@/utils/index'
import { orderBy } from '@/utils';

export const mutations = {
    SET_LIST: (state, items) => {
        state.list.data = items.data.map(item => {
            return mutationCandidateModel(item);
        });
        state.list.pagination = {
            total: items.total,
            page: items.current_page,
            limit: items.per_page,
            show: items.total > items.per_page,
            startIndex: ((items['current_page'] - 1) * items['per_page']),
        }
        if (state.list.pagination['startIndex']) {
            state.list.data = state.list.data.map((item, index) => {
                item['number'] = state.list.pagination['startIndex'] + (index + 1);
                return item;
            })
        }
    },
    SET_MODEL: (state, data) => {
        if (data) {
            data = mutationCandidateModel(data);
        }
        state.model = data;
    },
    SET_RESPONSES: (state, data) => {
        state.responses = data.map(item => {
            if(item.seeker_profile){
                return {
                    type: 'resume',
                    profile_sourse: 'resume',
                    id: item.id,
                    profile_id: item.seeker_profile.id,
                    profile_tags: item.seeker_profile.profile_tags,
                    is_showed: item.is_showed,
                    avatar: item.seeker_profile.avatar,
                    last_name: item.seeker_profile.last_name,
                    region: item.seeker_profile.region,
                    first_name: item.seeker_profile.first_name,
                    created_at: item.created_at,
                    updated_at: item.created_at,
                    age: item.seeker_profile.age,
                    wanted_salary: item.seeker_profile.wanted_salary,
                    wanted_work: item.seeker_profile.wanted_work,
                    salary_currency: item.seeker_profile.salary_currency,
                    position: item.seeker_profile.position,
                    position_name: item.seeker_profile.position_name,
                    deleted_at: item.seeker_profile.deleted_at,
                    profile_ball: item.scoring ? item.scoring.profile_ball : 0,
                    compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                    latest_experience: item.seeker_profile.latest_experience ? item.seeker_profile.latest_experience : null,
                    latest_education: item.seeker_profile.latest_education ? item.seeker_profile.latest_education : null,
                    experience_period: monthToYears(item.seeker_profile.experience_month),
                    experience_month: item.seeker_profile.experience_month,
                    stepType: 'response',
                }
            }else if(item.resume){
                return {
                    type: 'resume',
                    profile_sourse: 'cv',
                    id: item.id,
                    profile_id: item.resume.id,
                    profile_tags: [],
                    is_showed: item.is_showed,
                    avatar: item.resume.avatar,
                    last_name: item.resume.last_name,
                    region: item.resume.region,
                    first_name: item.resume.first_name,
                    created_at: item.created_at,
                    updated_at: item.created_at,
                    age: item.resume.age,
                    wanted_salary: item.resume.wanted_salary,
                    wanted_work: item.resume.wanted_work,
                    salary_currency: item.resume.additional_info?item.resume.additional_info.wanted_salary_currency:null,
                    position: item.resume.position,
                    position_name: item.resume.position_name,
                    deleted_at: item.resume.deleted_at,
                    profile_ball: item.scoring ? item.scoring.profile_ball : 0,
                    compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                    latest_experience: item.resume.latest_experience ? item.resume.latest_experience : null,
                    latest_education: item.resume.latest_education ? item.resume.latest_education : null,
                    experience_period: monthToYears(item.resume.experience_month),
                    experience_month: item.resume.experience_month,
                    resume_source: item.source,
                    stepType: 'response',
                }
            }else{
                return item;
            }
        });
    },
    SET_SPAM_RESPONSES: (state, data) => {
        state.spam_responses = data.map(item => {
            if(item.seeker_profile){
                return {
                    type: 'resume',
                    profile_sourse: 'resume',
                    id: item.id,
                    profile_id: item.seeker_profile.id,
                    profile_tags: item.seeker_profile.profile_tags,
                    is_showed: item.is_showed,
                    avatar: item.seeker_profile.avatar,
                    last_name: item.seeker_profile.last_name,
                    region: item.seeker_profile.region,
                    first_name: item.seeker_profile.first_name,
                    created_at: item.created_at,
                    updated_at: item.created_at,
                    age: item.seeker_profile.age,
                    wanted_salary: item.seeker_profile.wanted_salary,
                    wanted_work: item.seeker_profile.wanted_work,
                    salary_currency: item.seeker_profile.salary_currency,
                    position: item.seeker_profile.position,
                    position_name: item.seeker_profile.position_name,
                    deleted_at: item.seeker_profile.deleted_at,
                    profile_ball: item.scoring ? item.scoring.profile_ball : 0,
                    compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                    latest_experience: item.seeker_profile.latest_experience ? item.seeker_profile.latest_experience : null,
                    latest_education: item.seeker_profile.latest_education ? item.seeker_profile.latest_education : null,
                    experience_period: monthToYears(item.seeker_profile.experience_month),
                    experience_month: item.seeker_profile.experience_month,
                    stepType: 'response',
                }
            }else if(item.resume){
                return {
                    type: 'resume',
                    profile_sourse: 'cv',
                    id: item.id,
                    profile_id: item.resume.id,
                    profile_tags: [],
                    is_showed: item.is_showed,
                    avatar: item.resume.avatar,
                    last_name: item.resume.last_name,
                    region: item.resume.region,
                    first_name: item.resume.first_name,
                    created_at: item.created_at,
                    updated_at: item.created_at,
                    age: item.resume.age,
                    wanted_salary: item.resume.wanted_salary,
                    wanted_work: item.resume.wanted_work,
                    salary_currency: item.resume.additional_info?item.resume.additional_info.wanted_salary_currency:null,
                    position: item.resume.position,
                    position_name: item.resume.position_name,
                    deleted_at: item.resume.deleted_at,
                    profile_ball: item.scoring ? item.scoring.profile_ball : 0,
                    compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                    latest_experience: item.resume.latest_experience ? item.resume.latest_experience : null,
                    latest_education: item.resume.latest_education ? item.resume.latest_education : null,
                    experience_period: monthToYears(item.resume.experience_month),
                    experience_month: item.resume.experience_month,
                    resume_source: item.source,
                    stepType: 'response',
                }
            }else{
                return item;
            }
        });
    },
    SET_COMMENTS: (state, data) => {
        if (data) {
            state.comments = data;
        }
    },
    SET_SHORT_LIST_STEP: (state, data) => {
        state.shortListStep = data.map(item => {
            if(item.resume){
                return {
                    type: 'candidate',
                    profile_sourse: 'cv',
                    id: item.id,
                    is_showed: true,
                    avatar: item.resume.avatar,
                    last_name: item.resume.last_name,
                    first_name: item.resume.first_name,
                    created_at: item.created_at,
                    applicant_confirmed: item.applicant_confirmed===true?1:(item.applicant_confirmed===false?2:0),
                    age: item.resume.birth_date?_calculateAge(item.resume.birth_date):item.resume.age,
                    wanted_salary: item.resume.wanted_salary,
                    wanted_work: true,
                    region: item.resume.region,
                    salary_currency: item.resume.additional_info?item.resume.additional_info['wanted_salary_currency']:'',
                    position_name: item.resume.position_name,
                    latest_experience: getCvLatestExperience(item.resume),
                    experience_period: item.resume.experience_month?monthToYears(item.resume.experience_month):null,
                    experience_month: item.resume.experience_month,
                    resume_source: item.resume.source,
                    waiting_time_expired: item.waiting_time_expired
                }
            }
            return {
                type: 'candidate',
                profile_sourse: 'resume',
                id: item.id,
                profile_id: item.seeker_profile.id,
                profile_tags: item.seeker_profile.profile_tags,
                is_showed: item.seeker_profile.is_showed,
                avatar: item.seeker_profile.avatar,
                last_name: item.seeker_profile.last_name,
                first_name: item.seeker_profile.first_name,
                created_at: item.created_at,
                applicant_confirmed: item.applicant_confirmed===true?1:(item.applicant_confirmed===false?2:0),
                age: item.seeker_profile.age,
                wanted_salary: item.seeker_profile.wanted_salary,
                wanted_work: item.seeker_profile.wanted_work,
                salary_currency: item.seeker_profile.salary_currency,
                position: item.seeker_profile.position,
                position_name: item.seeker_profile.position_name,
                latest_experience: item.seeker_profile.latest_experience ? item.seeker_profile.latest_experience : null,
                latest_education: item.seeker_profile.latest_education ? item.seeker_profile.latest_education : null,
                experience_period: monthToYears(item.seeker_profile.experience_month),
                experience_month: item.seeker_profile.experience_month,
                resume_source: null,
                waiting_time_expired: item.waiting_time_expired,
                region: item.seeker_profile.region,
                starting_work: item.seeker_profile.starting_work,
            }
        });
    },
    SET_SHORT_LIST: (state, data) => {
        state.shortList = data.map(item => {
            return {
                type: 'candidate',
                id: item.id,
                profile_id: item.seeker_profile.id,
                profile_tags: item.seeker_profile.profile_tags,
                avatar: item.seeker_profile.avatar,
                is_showed: item.seeker_profile.is_showed,
                last_name: item.seeker_profile.last_name,
                first_name: item.seeker_profile.first_name,
                created_at: item.created_at,
                applicant_confirmed: item.applicant_confirmed===true?1:(item.applicant_confirmed===false?2:0),
                age: item.seeker_profile.age,
                wanted_salary: item.seeker_profile.wanted_salary,
                wanted_work: item.seeker_profile.wanted_work,
                salary_currency: item.seeker_profile.salary_currency,
                position: item.seeker_profile.position,
                position_name: item.seeker_profile.position_name,
                latest_experience: item.seeker_profile.latest_experience ? item.seeker_profile.latest_experience : null,
                latest_education: item.seeker_profile.latest_education ? item.seeker_profile.latest_education : null,
                experience_period: monthToYears(item.seeker_profile.experience_month),
                experience_month: item.seeker_profile.experience_month,
                region: item.seeker_profile.region,
            }
        });
    },
    SET_ACTION_HISTORIES: (state, data) => {
        if (data) {
            state.actionHistories = data;
        }
    },
    SET_AUTO_SELECTIONS: (state, data) => {
        state.autoselections = data.map(item => {
            return {
                type: 'resume',
                profile_sourse: 'resume',
                id: item.id,
                profile_id: item.id,
                avatar: item.avatar,
                last_name: item.last_name,
                is_showed: item.is_showed,
                region: item.region,
                first_name: item.first_name,
                created_at: item.created_at,
                updated_at: item.created_at,
                compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                age: item.age,
                wanted_salary: item.wanted_salary,
                salary_currency: item.salary_currency,
                position: item.position,
                position_name: item.position_name,
                profile_tags: item.profile_tags,
                profile_ball: item.scoring ? item.scoring.profile_ball : 0,
                latest_experience: item.latest_experience ? item.latest_experience : null,
                latest_education: item.latest_education ? item.latest_education : null,
                experience_period: monthToYears(item.experience_month),
                experience_month: item.experience_month,
                stepType: 'autoselect',
            }
        });
    },
    SET_AUTO_SELECTIONS_TEST: (state, data) => {
        console.log(data['compatibleResumes']);

        if (data['compatibleResumes']) {
            state.autoselectionsTest['compatibleResumes'] = data['compatibleResumes'].map(item => {
                return {
                    type: 'resume',
                    profile_sourse: 'resume',
                    id: item.id,
                    profile_id: item.id,
                    avatar: item.avatar,
                    last_name: item.last_name,
                    is_showed: item.is_showed,
                    region: item.region,
                    first_name: item.first_name,
                    created_at: item.created_at,
                    updated_at: item.created_at,
                    compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                    age: item.age,
                    wanted_salary: item.wanted_salary,
                    salary_currency: item.salary_currency,
                    position: item.position,
                    position_name: item.position_name,
                    profile_tags: item.profile_tags,
                    profile_ball: item.profile_ball ? item.profile_ball : 0,
                    latest_experience: item.latest_experience ? item.latest_experience : null,
                    latest_education: item.latest_education ? item.latest_education : null,
                    experience_period: monthToYears(item.experience_month),
                    experience_month: item.experience_month,
            }
            });
        }
        if (data['allResumes']) {
            state.autoselectionsTest['allResumes'] = data['allResumes'].map(item => {
                return {
                    type: 'resume',
                    profile_sourse: 'resume',
                    id: item.id,
                    profile_id: item.id,
                    avatar: item.avatar,
                    last_name: item.last_name,
                    is_showed: item.is_showed,
                    region: item.region,
                    first_name: item.first_name,
                    created_at: item.created_at,
                    updated_at: item.created_at,
                    compatible_percentage: item.scoring ? item.scoring.compatible_percentage : 0,
                    age: item.age,
                    wanted_salary: item.wanted_salary,
                    salary_currency: item.salary_currency,
                    position: item.position,
                    position_name: item.position_name,
                    profile_tags: item.profile_tags,
                    profile_ball: item.profile_ball ? item.profile_ball : 0,
                    latest_experience: item.latest_experience ? item.latest_experience : null,
                    latest_education: item.latest_education ? item.latest_education : null,
                    experience_period: monthToYears(item.experience_month),
                    experience_month: item.experience_month,
                }
            });
        }
    },
    SET_IS_SHOWED_RESPONSE: (state, model) => {
        state.responses = state.responses.map(item => {
            if (item.profile_id == model.id) item['is_showed'] = true;
            return item;
        })
    },
    SET_IS_AUTO_SELECTION: (state, model) => {
        state.autoselections = state.autoselections.map(item => {
            if (item.id == model.id) item['is_showed'] = true;
            return item;
        })
    },
    DELETE_FROM_LIST: (state, data) => {
        let listKey = data['list'];
        if (state[listKey] && state[listKey].length > 0) {
            if (data['id']) {
                state[listKey] = state[listKey].filter(item => item.id != data['id']);
            }
            if (data['profile_id']) {
                state[listKey] = state[listKey].filter(item => item.profile_id != data['profile_id']);
            }
        }
    },
    DELETE_FROM_LIST_ITEM: (state, data) => {
        let listKey = data['list'];
        if (state[listKey] && state[listKey].length > 0) {
            let model = data['item'];
            if(model['seeker_profile_id']){
                state[listKey] = state[listKey].filter(item => item.profile_id != model['seeker_profile_id']);
            }else if(model['resume_id']){
                state[listKey] = state[listKey].filter(item => item.profile_id != model['resume_id']);
            }
        }
    },
    SET_ALL_TAGS: (state, data) => {
        if (data) {
            state.allTags = data;
        }
    },
    RESUMES_SORT: (state, params) => {
        if (params['step'] == 'autoselect') {
            params['columns'].forEach(item => {
                orderBy(state.autoselections, item, 'desc');
            })
        }
        if (params['step'] == 'response') {
            params['columns'].forEach(item => {
                orderBy(state.responses, item, 'desc');
            })
        }
    },
};

function _calculateAge(birthday) { // birthday is a date
    if(birthday){
        var ageDifMs = Date.now() - (new Date(birthday)).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    return 0;
}

function mutationCandidateModel(data){
    var newData = {
        id: data['id'],
        seeker_profile_id: data['seeker_profile_id'],
        company_id: data['company_id'],
        vacancy_id: data['vacancy_id'],
        vacancy: data['vacancy'],
        user: data['user'],
        added_from: data['added_from'],
        vacancy_step_id: data['vacancy_step_id'],
        vacancy_step: data['vacancy_step'],
        created_by: data['created_by'],
        tags: !data['tags']?[]:data['tags'],
        created_at: data['created_at'],
        updated_at: data['updated_at'],
        status: data['status'],
        source: data['source'],
        applicant_confirmed: data['applicant_confirmed'],
        resume_id: data['resume_id'],
        experience_period: null,
        vacancyCompatiblePercent: 0,
        vacancyCompatible: [],
    };
    if(data.seeker_profile){
        newData['type'] = 'candidate';
        newData['profile_sourse'] = 'resume';
        newData['experience_period'] = data.seeker_profile.experience_month? monthToYears(data.seeker_profile.experience_month):null;
        newData['experience_month'] = data.seeker_profile.experience_month;
        newData['user_id'] = data.seeker_profile.user_id;
        newData['last_name'] = data.seeker_profile.last_name;
        newData['first_name'] = data.seeker_profile.first_name;
        newData['patronymic'] = data.seeker_profile.patronymic;
        newData['birth_date'] = data.seeker_profile.birth_date;
        newData['gender'] = data.seeker_profile.gender;
        newData['avatar'] = data.seeker_profile.avatar;
        newData['position_id'] = data.seeker_profile.position_id;
        newData['district_id'] = data.seeker_profile.district_id;
        newData['region_id'] = data.seeker_profile.region_id;
        newData['wanted_work'] = data.seeker_profile.wanted_work;   
        newData['profile_created'] = data.seeker_profile.created_at;   
        newData['profile_updated'] = data.seeker_profile.updated_at;   
        newData['phone_number'] = data.seeker_profile.phone_number;   
        newData['email'] = data.seeker_profile.email;   
        newData['skills'] = data.seeker_profile.skills;
        newData['languages'] = data.seeker_profile.languages;
        newData['wanted_salary'] = data.seeker_profile.wanted_salary;
        newData['category_id'] = data.seeker_profile.category_id;
        newData['without_experience'] = data.seeker_profile.without_experience;
        newData['driver_rights'] = data.seeker_profile.driver_rights;
        newData['has_car'] = data.seeker_profile.has_car;
        newData['salary_currency'] = data.seeker_profile.salary_currency;
        newData['employment_type_id'] = data.seeker_profile.employment_type_id;
        newData['about'] = data.seeker_profile.about;
        newData['computer_level_id'] = data.seeker_profile.computer_level_id;
        newData['position_name'] = data.seeker_profile.position_name;
        newData['registration_step'] = data.seeker_profile.registration_step;
        newData['uuid'] = data.seeker_profile.uuid;
        newData['is_verified'] = data.seeker_profile.is_verified;
        newData['registration_lang'] = data.seeker_profile.registration_lang;
        newData['age'] = data.seeker_profile.age;
        newData['category'] = data.seeker_profile.category;
        newData['position'] = data.seeker_profile.position;
        newData['is_reserved'] = data.seeker_profile.is_reserved;
        newData['added_telegram'] = data.seeker_profile.added_telegram;
        newData['is_showed'] = data.seeker_profile.is_showed;
        newData['profile_tags'] = data.seeker_profile.profile_tags;
        newData['showed_count'] = data.seeker_profile.showed_count;
        newData['experiences'] = data.seeker_profile.experiences;
        newData['educations'] = data.seeker_profile.educations;
        newData['seeker_courses'] = data.seeker_profile.seeker_courses;
        newData['region'] = data.seeker_profile.region;
        newData['city'] = data.seeker_profile.city;
        newData['ball'] = data.seeker_profile.ball;
        newData['vacancy_response'] = data.vacancy_response;
        newData['resume_source'] = null;
        newData['from_portals'] = false;
        newData['resume_file_path'] = null;
        newData['candidate_folder_id'] = data.candidate_folder_id;
        newData['starting_work'] = data.seeker_profile.starting_work;
    }else if(data.resume){
        newData['type'] = 'candidate';
        newData['profile_sourse'] = 'cv';
        newData['experience_period'] = data.resume.experience_month?monthToYears(data.resume.experience_month):null;
        newData['experience_month'] = data.resume.experience_month;
        newData['user_id'] = null;
        newData['last_name'] = data.resume.last_name;
        newData['first_name'] = data.resume.first_name;
        newData['patronymic'] = data.resume.middle_name;
        newData['birth_date'] = data.resume.birth_date;
        newData['gender'] = data.resume.gender;
        newData['avatar'] = data.resume.avatar;
        newData['position_id'] = null;
        newData['district_id'] = null;
        newData['region_id'] = null;
        newData['wanted_work'] = null;   
        newData['profile_created'] = data.resume.created_at;   
        newData['profile_updated'] = data.resume.updated_at;   
        newData['phone_number'] = data.resume.phone_number;   
        newData['email'] = data.resume.email;   
        newData['skills'] = data.resume.additional_info?data.resume.additional_info['skills']:null;
        newData['languages'] = data.resume.additional_info?data.resume.additional_info['languages']:null;
        newData['languages_string'] = data.resume.languages;
        newData['wanted_salary'] = data.resume.wanted_salary;
        newData['category_id'] = null;
        newData['without_experience'] = null;
        newData['driver_rights'] = data.resume.additional_info?data.resume.additional_info['driver_rights']:null;
        newData['has_car'] = data.resume.additional_info?data.resume.additional_info['has_car']:null;
        newData['salary_currency'] = data.resume.additional_info?data.resume.additional_info['wanted_salary_currency']:null;
        newData['employment_type_id'] = null;
        newData['employment_type_name'] = data.resume.additional_info?data.resume.additional_info['employment_type']:null;
        newData['about'] = data.resume.about;
        newData['computer_level_id'] = data.resume.additional_info?data.resume.additional_info['computer_level_id']:null;
        newData['position_name'] = data.resume.position_name;
        newData['registration_step'] = 20;
        newData['uuid'] = data.resume.uuid;
        newData['is_verified'] = null;
        newData['registration_lang'] = null;
        newData['age'] = _calculateAge(data.resume.birth_date);
        newData['category'] = null;
        newData['position'] = null;
        newData['is_reserved'] = data.resume.is_reserved;
        newData['added_telegram'] = null;
        newData['is_showed'] = data.resume.is_showed;
        newData['profile_tags'] = [];
        newData['showed_count'] = data.resume.showed_count;
        newData['experiences'] = data.resume.additional_info?data.resume.additional_info['experiences']:null;
        newData['experiences_string'] = data.resume.experiences;
        newData['educations'] = data.resume.additional_info?data.resume.additional_info['educations']:null;
        newData['educations_string'] = data.resume.educations;
        newData['seeker_courses'] = data.resume.additional_info?data.resume.additional_info['courses']:null;
        newData['region'] = data.resume.region;
        newData['city'] = data.resume.additional_info?data.resume.additional_info['city']:null;
        newData['vacancy_response'] = null;
        newData['resume_source'] = data.resume.source;
        newData['from_portals'] = data.resume.source == 'olx.uz' || data.resume.source == 'hh.uz';
        newData['resume_file_path'] = data.resume.file_path;
        newData['candidate_folder_id'] = data.candidate_folder_id;
    }
    return newData;
}

function getCvLatestExperience(resume){
    if(resume){
        if(resume.latest_experience){
            return resume.latest_experience;
        }else if(resume.additional_info && resume.additional_info['experiences'] && resume.additional_info['experiences'].length){
            var current_job = resume.additional_info['experiences'].find(item => !item.end_date);
            if(current_job){
                return current_job;
            }
        }
    }
    return null;
}