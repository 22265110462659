export const mutations = {
  SET_APPLICANTS: (state, items) => {
    state.applicants = items;
  },
  SET_RECRUITERS: (state, items) => {
    state.recruiters = items;
  },
  SET_PLANS: (state, items) => {
    state.billing_plans = items;
  },
  SET_PLAN: (state, item) => {
    state.billing_plan = item;
  },
  SET_CONTRACTS: (state, items) => {
    state.contracts = items;
  },
  SET_INVOICES: (state, items) => {
    state.invoices = items;
  },
};