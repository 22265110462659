export default {
    "hello": "Салом",
    "about": {
        "example": "Tohle je stránka O nás."
    },
    "Ответ кандидата": "Номзоднинг жавоби",
    "Все": "Барсъчаси",
    "По вашему запросу ничего не найдено": "Сизнинг сўровингниз бўйича ҳеч қандай натижа топилмади",
    "Очистить фильтр": "Филтерни тозалаш",
    "Вакансия": "Вакансия",
    "Локация": "Ҳудуд",
    "Отдел": "Бўлим",
    "Крайний срок": "Охирги муддат",
    "Ответственный": "Масъул",
    "Приоритет": "Устуворлик даражаси",
    "У вас еще нет созданных вакансий": "Сизда ҳозирча эълон қилинган вакансиялар йўқ",
    "У вас еще нет кандидаты": "Сизда ҳозирча номзодлар йўқ",
    "Заказчики": "Буюртмачилар",
    "Рекрутеры": "Рекрутерлар",
    "У вас еще нет заказчики": "Сизда ҳозирча буюртмачилар йўқ",
    "У вас еще нет рекрутеры": "Сизда ҳозирча рекрутерлар йўқ",

    "Заказчик": "Буюртмачи",
    "Рекрутер": "Рекрутер",
    "Выберите роль пользователя": "Фойдаланувчини ролини танланг",
    "Выберите заказчика": "Буюртмачини танланг",
    "Крайний срок вакансии": "Вакансиянинг охирги муддати",
    "Ответственный рекрутер": "Масъул рекрутер",
    "Выберите ответственного рекрутера": "Масъул рекрутерни танланг",
    "Низкий": "Қуйи",
    "Средний": "Ўрта",
    "Высокий": "Юқори",
    "Есть опыт работы?": "Иш тажрибага эгами?",
    "Дополнительное образование": "Сертификатлар",
    "На какую вакансию добавить?": "Қайси вакансияга бириктирилади?",
    "Введите название вакансии": "Вакансия номини киритинг",
    "Добавить ещё опыт работы": "Яна иш тажриба қўшиш",
    "Организация": "Ташкилот",
    "Есть": "Бор",
    "Добавить ещё курс": "Яна сертификат қўшиш",
    "Дополнительная информация": "Қўшимча маълумот",
    "Выберите этап": "Босқични танланг",
    "Сменить этап": "Босқични ўзгартириш",

    "Добавление кандидата": "Номзод қўшиш",
    "Добавить новую": "Янги қўшиш",
    "Введите фамилию или имя кандидата": "Номзод исми ва фамилиясини киритинг",
    "На другую вакансию": "Бошқа вакансияга",
    "Отказать": "Рад этиш",
    "Профиль ISH.UZ": "ISH.UZ профили",
    "История действий": "Амаллар тарихи",
    "Совместимость": "Мослик",
    "Метки": "Теглар",
    "Добавить в базу кандидатов": "Номзодлар базасига қўшиш",
    "Добавить комментарий": "Изоҳ қўшиш",
    "Написать кандидату": "Номзодга ёзиш",
    "Переместить в кадровый резерв": "Кадрлар захирасига юбориш",
    "Отправить в черный список": "Қора рўйхатга қўшиш",
    "Комментарии": "Изоҳлар",
    "Источник": "Манба",
    "На какую вакансию отправить?": "Қасйи вакасияга жўнатиш керак?",
    "Написать коммениарий": "Изоҳ ёзиш",
    "Всем": "Ҳаммага",
    "Только я": "Фақат менга",
    "На какой этап?": "Қайси босқичга?",
    "Кандидат уже находится в кадровом резерве": "Номзод кадрлар захирасига қўшилган",

    "Комментировал": "Изоҳ қолдирилди",
    "Создан на этап ": "{param} босқичида яратилди",
    "Переведен на этап ": "{param} босқичига ўтқазилди",
    "Отправлен в черный список": "Қора рўйхатга қўшилди",
    "Активен": "Фаоллаштирилди",
    "Отправлен в кадровый резерв": "Кадрлар захирасига қўшилди",
    "lorem_send_archieve": "Кадрлар захираси - бу очиқ бўш лавозимни эгаллаш учун зарур бўлган кўникмаларга эга бўлган номзодларнинг маълумотлар базаси. Агар сизда очиқ вакансиялар мавжуд бўлса, кадрлар захирасида аввал кўриб чиқилган номзодларидан танлаб олишингиз мумкин бўлади.",
    "lorem_candidate_block": "Қора рўйхат - бу сиз келажакда кўриб чиқмайдиган ва ишга қабул қилмоқчи бўлмаган мутахассислар базаси. Ушбу рўйхатга қўшилган мутахассислар сизнинг тавсия этилган номзодларингизда кўринмайди, шунингдек чатга хабарлар юборолмайди.",

    "day": "{param} кун ичида",
    "days": "{param} кун ичида",
    "to_salary": "{param} гача",
    "Еще подходящих вакансий": "Яна {param} та мос бўш иш ўринлари",
    "вакансий": "та вакансия",
    "Создать вакансию": "Вакансия яратиш",
    "UZS": "сўм",
    "USD": "USD",
    "RUB": "Рубль",
    "EUR": "Евро",
    "CNY": "Юань",
    "Ташкент": "Тошкент",
    "Вакансии": "Вакансиялар",
    "Компании": "Компаниялар",
    "Профессии": "Касблар",
    "Работадателю": "Иш берувчига",
    "Создать резюме": "Резюме яратиш",
    "Войти": "Кириш",
    "Найти работу": "Иш излаш",
    "Работодателям": "Иш берувчиларга",
    "Категории": "Категориялар",
    "Поиск работы в Узбекистане": "Ўзбекистонда иш излаш портали",
    "Поиск": "Излаш",
    "Весь Узбекистан": "Бутун Ўзбекистон",
    "Найти вакансии": "Иш топиш",
    "Создать профиль": "Профил яратиш",
    "Это займет 3 минуты, и система сама для вас найдет наиболее подходящих кандидатов.": "3 дақиқадан сўнг тизим Сизга мос номзодни топади",
    "Это займет 3 минуты, и система сама найдет подходящие для вас вакансии": "Бунга 3 дақиқа вақт талаб этилади ва тизим Сизга мос вакансияларни топади",
    "Постоянная работа": "Доимий иш",
    "Работа по проекту": "Лойиҳа бўйича иш",
    "Удаленная работа": "Масофавий иш",
    "Стажировка": "Амалиёт",
    "Работа для экспатов": "Чет элликлар учун иш",
    "Всего объявлений": "Жами эълонлар",
    "Работа по категориям": "Категориялар бўйича вакансиялар",
    "Работа в компаниях": "Компаниялар бўйича вакансиялар",
    "Поиск работы в компании": "Барча компаниялар",
    "Поиск вакансий по регионам": "Ҳудудлар бўйича вакансиялар",
    "Поиск вакансий по городам": "Шаҳарлар бўйича вакансиялар",
    "Ish.uz использует файлы «cookie», с целью персонализации сервисов и повышения удобства пользования веб-сайтом.": "Ish.uz хизматларни шахсийлаштириш ва веб-сайтдан фойдаланиш қулайлигини ошириш учун «cоокие» файллардан фойдаланади.",
    "«Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта.": "«Cookie» файллар веб-сайтга олдинги ташрифлар ҳақидаги маълумотларни ўз ичига олган кичик файллардир.",
    "Если вы не хотите использовать файлы «cookie», измените настройки браузера.": "Агар Сиз «cookie» файллардан фойдаланишни хоҳламасангиз, браузер созламаларини ўзгартиринг.",
    "Вход": "Кириш",
    "Соискатель": "Иш изловчи",
    "Работодатель": "Иш берувчи",
    "Введите номер мобильного телефона": "Мобил телефон рақамини киритинг",
    "Далее": "Давом этиш",
    "Войти с паролем": "Парол билан кириш",
    "или": "ёки",
    "Продолжить через Google": "Google билан давом этиш",
    "Продолжить через Facebook": "Facebook билан давом этиш",
    "Введите адрес электронной почты": "E-mail манзилингизни киритинг",
    "Поле не может быть пустым": "Майдон бўш бўлиши мумкин эмас",
    "Пароль": "Парол",
    "Забыли пароль?": "Паролни унутдингизми?",
    "Нет аккаунта?": "Профилингиз йўқми?",
    "Зарегистрироваться": "Рўйхатдан ўтиш",
    "Зарегистрируйтесь и находите сотрудников": "Рўйхатдан ўтинг ва ходимларни топинг",
    "Название компании": "Компания номи",
    "Контактное лицо": "Алоқа учун маъсул шахс",
    "Введите Контактный номер": "Контакт рақамини киритинг",
    "Повторите пароль": "Паролни қайта киритинг",
    "Пожалуйста, введите пароль еще раз": "Илтимос, паролни қайта киритинг",
    "Нажимая кнопку “Зарегистрироваться”, вы принимаете": "«Рўйхатдан ўтиш» тугмасини босиш орқали сиз қуйидагиларни қабул қиласиз:",
    "условия использования сайта": "сайтдан фойдаланиш шартлари",
    "политику конфиденциальности": "махфийлик сиёсати",
    "Есть аккаунт?": "Профилингиз борми?",
    "Изменить фото": "Суратни ўзгартириш",
    "Профессия": "Касб",
    "Город": "Шаҳар",
    "Дата рождения": "Туғилган санаси",
    "Пол": "Жинси",
    "Телефон": "Телефон",
    "Ищу работу": "Иш излаяпман",
    "Не ищу работу": "Иш изламаяпман",
    "Готов выйти сразу": "Дарҳол чиқишга тайёр",
    "Нужно две недели": "Икки ҳафта керак",
    "Сохранить": "Сақлаш",
    "Отменить": "Бекор қилиш",
    "Категория": "Категория",
    "Позиция": "Лавозим",
    "Мой профиль": "Менинг профилим",
    "Подходящие вакансии": "Мос вакансиялар",
    "Приглашения от работодателей": "Иш берувчи билан чат",
    "Отклики": "Жавоблар",
    "Желаемый уровень заработной платы": "Исталган иш ҳақи даражаси",
    "В этом блоке вы можете добавить свой уровень зарплаты.": "Ушбу блокда Сиз ўзингизнинг иш ҳақи даражангизни қўшишингиз мумкин.",
    "Укажите уровень образования": "Малумот даражангизни кўрсатинг",
    "В этом блоке вы можете добавить образование, которое вы получили.": "Ушбу блокда Сиз олган маълумотингизни қўшишингиз мумкин.",
    "Опыт работы": "Иш тажрибаси",
    "Заполняя информацию об опыте работы, раскройте для работодателя ваши положительные стороны, которые соответствуют должности, на которой вы хотите работать. Опишите ваши обязанности, достижения, знания, умения и положительный опыт применения их в работе.": "Иш тажрибаси тўғрисидаги маълумотни тўлдирганда, иш берувчига ишламоқчи бўлган лавозимга мос келадиган ижобий томонларингизни ошкор қилинг. Мажбуриятлар, ютуқлар, билим, кўникма ва уларни ишингизда қўллаш бўйича ижобий тажрибангизни тасвирлаб беринг.",
    "Нет опыта работы": "Иш тажрибаси йўқ",
    "Укажите навыки": "Қобилиятларни белгиланг",
    "Когда вы описываете свои навыки, включите те, которые будут полезны вам в новой работе. Это покажет работодателю ваши конкретные преимущества перед другими кандидатами.": "Ўз маҳоратингизни тавсифлаганингизда, янги иш жойида Сизга фойдали бўладиганларини қўшинг. Бу иш берувчига Сизнинг бошқа номзодларга нисбатан ўзига хос афзалликларингизни кўрсатади.",
    "Знание языков": "Тилларни билиши",
    "Укажите в этом блоке, какие языки и на каком уровне вы знаете. Чтобы определить уровень, воспользуйтесь нашими рекомендациями.": "Ушбу блокда қайси тилларни ва қайси даражада билишингизни кўрсатинг. Даражани аниқлаш учун тавсияларимиздан фойдаланинг.",
    "Категория прав": "Ҳайдовчилик гувоҳномаси тоифалари",
    "Наличие автомобиля": "Автомобил мавжудлиги",
    "Есть личный автомобиль": "Шахсий автомобил мавжуд",
    "Добавить": "Қўшиш",
    "Отклик без резюме": "Резюмесиз таклиф юбориш",
    "Показывать только непрочитанные": "Фақат ўқилмаганларни кўрсатиш",
    "Выход": "Чиқиш",
    "Код": "Код",
    "Хотите изменить E-mail?": "E-mail манзилингизни ўзгартирмоқчимисиз?",
    "Изменить E-mail": "E-mail манзилни ўзгартириш",
    "Настройки компании": "Компания созламалари",
    "Поиск по кандидатам": "Ходим топиш",
    "Баланс": "Ҳисобингиз",
    "пополнить": "тўлдириш",
    "Редактирование информации о компании": "Компания маълумотларини таҳрирлаш",
    "Название тип компании": "Компания номи ва тури",
    "Название": "Номи",
    "Тип": "Тури",
    "Главная": "Бош саҳифа",
    "Редактировать": "Таҳрирлаш",
    "Информация о компании": "Компания ҳақида маълумот",
    "Отрасль": "Соҳа",
    "Количество сотрудников в компании": "Компаниядаги ходимлар сони",
    "Телефоно": "Телефон",
    "Описание компании": "Компания тавсифи",
    "Ищете сотрудников?": "Ходимларни излаяпсизми?",
    "Регионы": "Ҳудудлар",
    "Зарплата": "Иш ҳақи",
    "Вид занятости": "Бандлик тури",
    "Образование": "Малумоти",
    "Сбросить все": "Ҳаммасини олиб ташлаш",
    "Предыдущая": "Олдинги",
    "Следующая": "Кейинги",
    "мужской": "эркак",
    "женский": "аёл",
    "Написать": "Ёзиш",
    "profile_fill": {
        "about": "Ўзим ҳақимда",
        "computer_level_id": "Компютер саводхонлик",
        "driver_rights": "Ҳайдовчилик гувоҳномаси",
        "email": "E-mail",
        "languages": "Тилларни билиш",
        "phone_number": "Телефон",
        "seeker_courses": "Қўшимча таълим(курс)",
        "seeker_educations": "Таълим даражаси",
        "seeker_experiences": "Меҳнат фаолияти",
        "skills": "Кўникмалар",
        "wanted_salary": "Ойлик кутилмаси"
    },
    "Полная занятость": "Доимий бандлик",
    "Частичная занятость": "Қисман бандлик",
    "Попроектная работа или разовое задание": "Лойиҳавий иши ёки бир марталик вазифа",
    "Волонтерство": "Волонтёрлик",
    "не имеет значения": "аҳамияти йўқ",
    "Высшее бакалавр": "Малумоти олий, бакалавр",
    "Высшее магистр": "Малумоти олий, магистр",
    "Профиль заполнен на": "Профил {param} %га тўлдирилган",
    "Чем выше ваш процент, тем больше работодателей видят ваш профиль.": "Сизнинг фоизингиз қанчалик юқори бўлса,шунчалик кўп иш берувчилар Сизнинг профилингизни кўради.",
    "Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений.": "Максимал таклифлар сонини олиш учун, профилингизни 80% дан юқорироқ тўлдиришга ҳаракат қилинг.",
    "Еще вы можете указать": "Бундан ташқари, белгилашингиз мумкин",
    "Учебное заведение": "Таълим муассасаси",
    "Специализация (если есть)": "Мутахассислик (агар мавжуд бўлса)",
    "Направление": "Йўналиш",
    "Год окончания": "Тамомлаган йили",
    "Если вы сейчас учитесь — укажите ожидаемый год выпуска": "Агар сиз ҳозир ўқиётган бўлсангиз, кутилаётган битирув йилини кўрсатинг",
    "Вакансии Узбекистана": "Ўзбекистонда бўш иш ўринлари",
    "Подходящие резюме": "Тўғри келадиган кадидатлар",
    "Истекает": "Муддати тугамоқда",
    "Получить больше откликов": "Кўпроқ таклифлар олиш",
    "Действия": "Ҳаракатлар",
    "Активные": "Фаол",
    "Неопубликованные": "Эълон қилинмаганлар",
    "Архивные": "Архивланган",
    "Мои вакансии": "Менинг вакансияларим",
    "Сохраненные резюме": "Сақланган резюмелар",
    "Чат с кандидатами": "Номзодлар билан чат",
    "Название должности": "Лавозим номи",
    "Категория размещения вакансии": "Бўш иш ўринларни жойлаштириш категорияси",
    "Например, менеджер по продажам": "Масалан, савдо менежери",
    "Условия работы": "Иш шароитлари",
    "Регион работы": "Иш ҳудуди",
    "Город работы": "Иш шаҳри",
    "Адрес работы": "Иш манзили",
    "Улица и дом": "Кўча ва уй",
    "Вакансии с указанной зарплатой получают в два раза больше откликов!": "Иш ҳақи белгиланган бўш иш ўринларига икки баробар кўп жавоблар юборилади!",
    "Комментарий к зарплате": "Иш ҳақи бўйича изоҳ",
    "Диапазон": "Диапазон",
    "Одно значение": "Битта қиймат",
    "Не указыать": "Белгиланмаган",
    "Требования к соисталелю": "Иш изловчига қўйиладиган талаблар",
    "Готовы взять без опыта": "Тажрибасиз қабул қилишга тайёр",
    "от 1 года": "1 йилдан юқори",
    "от 2 лет": "2 йилдан юқори",
    "от 5 лет": "5 йилдан юқори",
    "Вакансия подходит для": "Бўш иш ўрин мос келади",
    "Студента": "Талабага",
    "Студент": "Талаба",
    "Человека с инвалидностью": "Ногиронлиги бўлган шахсга",
    "Пенсионера": "Пенсионерга",
    "Экспата иностранного специалиста": "Чет эллик мутахассисга",
    "Описание вакансии": "Бўш иш ўрин тавсифи",
    "Требования": "Талаблар",
    "Обязанности": "Мажбуриятлар",
    "Ключевые навыки": "Асосий кўникмалар",
    "Контактная информация": "Боғланиш учун маълумот",
    "Пользователь": "Фойдаланувчи",
    "Показывать на странице этой вакансии имя и телефон": "Ушбу бўш иш ўрин саҳифасида исм ва телефон рақамини кўрсатиш",
    "Указать другой телефон": "Бошқа телефон кўрсатиш",
    "Как можно откликнуться": "Қандай жавоб юборилиши мумкин",
    "Резюме обязательно": "Маълумотнома шарт",
    "Можно и без резюме (только имя, фамилия и контакты)": "Маълумотнома шарт эмас (фақат исм, фамилия ва контактлари)",
    "Сколько человек вы хотите нанять по данной вакансии?": "Ушбу бўш иш ўрин учун қанча одамни ёллашни хоҳлайсиз?",
    "Продолжить": "Давом этиш",

    "Сохранено": "Сақланган",
    "Чтобы сохранить кандидата, нужно войти или зарегистрироваться.": "Резюмени сақлаш учун тизимга киришингиз ёки рўйхатдан ўтишингиз керак.",
    "по дате": "сана бўйича",
    "по убыванию зарплат": "иш ҳақи камайиши бўйича",
    "по возрастанию зарплаты": "иш ҳақи кўпайиши бўйича",
    "Готов выйти через 2 недели": "2 ҳафтадан кейин чиқишга тайёр",
    "Опыт работы:": "Иш тажрибаси:",
    "Место работы": "Иш жойи",
    "по настоящее время": "ҳозирги вақтга қадар",
    "Основной язык": "Асосий тил",
    "Обновлено": "Янгиланган",
    "Чтобы сохранить вакансию, нужно войти или зарегистрироваться.": "Вакансияни сақлаш учун тизимга киришингиз ёки рўйхатдан ўтишингиз керак",
    "Ищете работу?": "Ищете работу?",
    "Разместите резюме": "Разместите резюме",
    "чтобы работодатели сами могли предложить вам работу.": "чтобы работодатели сами могли предложить вам работу.",
    "Откликнуться": "Таклиф юбориш",
    "Вакансия от": "Эълон қилинган:",
    "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться": "Вакансияга таклиф юбориш учун тизимга киришингиз ёки рўйхатдан ўтишингиз керак",
    "Фильтры": "Фильтры",
    "Разместите вакансию": "Разместите вакансию",
    "чтобы кандидаты сами могли найим вас": "чтобы кандидаты сами могли найим вас",
    "Применить": "Применить",
    "подходящих вакансий": "подходящих вакансий",
    "Сортировка": "Сортировка",
    "C полследнего размещения": "Эълон қилинганидан бошлаб",
    "откликов": "таклифлар",
    "просмотров вакансии": "кўришлар сони",
    "показов вакансии": "показов вакансии",
    "Вам подойдут эти вакансии": "Вам подойдут эти вакансии",
    "до": "гача",
    "от": "дан",
    "Подробнее": "Батафсил",
    "Поиск по компаниям": "Компаниялар бўйича излаш",
    "Все компании": "Барча компаниялар",
    "По отраслям": "Соҳалар бўйича",
    "Поиск компании": "Компанияни излаш",
    "Найти": "Топиш",
    "Нет компании": "Ташкилотлар мавжуд эмас",
    "сотрудников": "ходимлар",
    "О компании": "Ташкилот ҳақида",
    "Просмотры": "Кўриб чиқилган",
    "Восстановить": "Қайтариш",
    "Опубликовать": "Эълон қилиш",
    "Поднять вакансию": "Вакансияни кўтариш",
    "Опубликовано": "Эълон қилинган",
    "Закрыть вакансию": "Вакансияни ёпиш",
    "Хорошо": "Яхши",
    "Добавить вакансию": "Вакансия қўшиш",
    "Кандидаты по вакансии": "Вакансия бўйича номзодлар",
    "Новые": "Янгилари",
    "Просмотренные": "Кўрилганлари",
    "Aктивные": "Активлари",
    "Пожалуйста, введите новый пароль для учетной записи": "Илтимос фойдаланувчи учун янги парол киритинг",
    "Адрес": "Манзил",
    "Требуемый опыт работы": "Талаб қилинган иш тажрибаси",
    "Вы отправили отклик на эту вакансию": "Сиз бу вакансияга отклик юбордингиз",
    "Работа в Узбекистане. Открытые вакансии по категориям.": "Ўзбекистонда иш излаш. Категориялар бўйича вакансиялар",
    "Например, организация конференцей": "Масалан, конференция ташкиллаштириш",
    "Указать ещё один язык": "Яна бир тил кўрсатиш",
    "Указать язык": "Тил билиш даражасини кўрсатиш",
    "Указать другое контактное лицо": "Алоқа учун бошқа шахс кўрсатиш",
    "Можно и без резюме (только имя,  фамилия и контакты)": "Резюмесиз ( фақат исм, фамилия ва алоқа маълумоти)",
    "Посмотреть все категории": "Барча категорияларни кўриш",
    "Требования к  соисталелю": "Номзодга талаблар",
    "Выберите": "Танланг",
    "сум": "сум",
    "До": "гача",
    "От": "дан",
    "Сумма": "Миқдор",
    "suitable_vacancies": "Еще {param} подходящих вакансий",
    "Формат файла должно быть один из следующих (JPG, JPEG, PNG).": "Формат файла должно быть один из следующих (JPG, JPEG, PNG).",
    "Размер файла не должно превышать 2мб.": "Размер файла не должно превышать 2мб.",
    "Поиск сотрудников в Узбекистане": "Ўзбекистонда ходимларни топиш",
    "Соискателю": "Иш изловчиларга",
    "Кандидаты по категориям": "Категория бўйича кандидатлар",
    "Поиск кандидатов по регионам": "Ҳудудлар бўйича кандидатлар",
    "Найти сотрудников в Узбекистане": "Ўзбекистонда ходимлар топиш",
    "На данной странице вы найдете информацию о предложениях компаний. Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана": "Ушбу саҳифада сиз компаниялар таклифлари ҳақида маълумот топасиз. Таклифлар нархлари, батафсил тавсифи ва Ўзбекистон бозорида фаолият юритаётган иш берувчиларнинг алоқа маълумотларини ўз ичига олади",
    "Вакансии в Ташкенте": "Ташкент бўйича иш ўринлари",
    "Вакансии компаний": "Компания вакансиялари",
    "Удобный поиск, актуальная информация - описание, фото, адреса и телефоны": "Қулай қидирув, долзарб маълумотлар - тавсиф, фотосуратлар, манзиллар ва телефонлар",
    "найдено вакансий": "{param}та вакансия топилди",
    "На сайте Ish.uz размещены вакансии в Ташкенте и регионах Узбекистана. Информация на сайте позволяет осуществлять быстрый поиск и подбор персонала в любой сфере.": "Ўзбекистонда ходимларни топиш Тошкент шаҳри ва Ўзбекистон ҳудудларидаги вакансиялари Ish.uz сайтида жойлайштирилган. Сайтдаги маълумотлар исталган соҳада ходимларни тезда топиш ва танлаш имконини беради.",
    "Найти кандидатов": "Ходим топиш",
    "Введите свой номер телефона": "Телефон рақамингизни киритинг",
    "и": "ва",
    "Регион": "Ҳудуд",
    "Похожие вакансии": "Ўхшаш вакансиялар",
    "Все вакансии": "Барча вакансиялар",
    "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться.": "Бу имкониятдан фойдаланишингиз учун иш изловчи сифатида тизимга киришингиз керак.",
    "Вакансия успешно опубликовано!": "Вакансия муваффақиятли жойлаштирилди!",
    "Успешно": "Муваффақиятли",
    "Это займет 3 минуты, и система сама найдет подходящие для вас  вакансии": "Бу 3 дақиқа вақт олади ва тизим Сизга мос вакансияларни топади",
    "На данной странице вы найдете информацию о предложениях компаний . Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана": "Ушбу саҳифада Сиз компаниялар таклифлари ҳақида маълумот топасиз. Таклифларда нархлар, батафсил тавсиф ва Ўзбекистон бозорида ишлайдиган иш берувчиларнинг алоқа маълумотлари мавжуд.",
    "Работа в Ташкенте и городах Узбекистана. Подбор персонала и вакансии. Поиск работы в Узбекистане": "Тошкент ва Ўзбекистон шаҳарларида ишлаш. Ходимларни ва вакансияларни танлаш. Ўзбекистонда иш топиш",
    "Работа в Ташкенте и городах Узбекистана": "Тошкент ва Ўзбекистон шаҳарларида ишлаш",
    "Нет подходящих вакансий": "Мос вакансиялар йўқ",
    "Здесь будут находиться ваши чаты с работодателями": "Иш берувчилар билан суҳбатларингиз шу ерда бўлади",
    "Сообщение": "Хабар",
    "Отправить": "Юбориш",
    "Сайт": "Сайт",
    "Не указана": "Кўрсатилмаган",
    "Посмотреть все отрасли": "Барча соҳаларни кўриш",
    "Логотип": "Логотип",
    "Чем занимается компания, ее миссия, успехи, перспективы, профессионального и карьерного роста, мотивационные программы, премиущества работы в компании": "Компания нима қилади, унинг вазифаси, муваффақиятлари, истиқболлари, профессионал ва мартаба ўсиши, мотивацион дастурлар, компанияда ишлашнинг афзалликлари",
    "Прямой работодатель": "Тўғридан-тўғри иш берувчи",
    "Кадровое агентство": "Ишга қабул қилиш агентлиги",
    "Ваша эл. почта": "Сизнинг электрон почтангиз",
    "Перейти на эл. почту": "Электрон почтага ўтиш",
    "Новый пароль еще раз": "Янги паролни қайта киритинг",
    "Войти по коду": "Код билан кириш",
    "Подтверждение": "Тасдиқлаш",
    "Повторно отправить код": "Кодни қайта юбориш",
    "Отправить повторно": "Қайта юбориш",
    "Письмо отправлено!": "Хат юборилди!",
    "Чтобы восстановить пароль, откройте письмо, которое мы только что отправили на эл. почту": "Паролингизни тиклаш учун, Сизгнинг электрон почтангизга юборилган хатни очинг.",
    "и нажмите в нем на кнопку “Восстановить пароль”": "ва “Паролни тиклаш” тугмасини босинг",
    "Новый пароль": "Янги парол",
    "Назад": "Орқага",
    "Еще": "Яна",
    "Выбрать несколько": "Бир нечтасини танлаш",
    "Выбрать один": "Биттасини танлаш",
    "без указания": "белгиланмаган",
    "Мои сохраненные резюме": "Менинг сақланган резюмеларим",
    "Отклик на вакансию": "Ишга юборилган жавоб",
    "Cопроводительный текст (не обязательно)": "Қўшимча матн (киритиш ихтиёрий)",
    "Для отклик на вакансию": "Вакансияга жавоб юбориш учун",
    "Ваше резюме неполное": "Сизнинг резюменгиз тўлиқ эмас",
    "Пожалуйста, заполните свое резюме полностью": "Илтимос, резюменгизни тўлиқ тўлдиринг",
    "Заполнить резюме": "Резюмени тўлдиринг",
    "Чем выше ваш процент, тем больше работодателей видят ваш профиль. Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений": "Сизнинг фоизингиз қанчалик юқори бўлса, иш берувчилар Сизнинг профилингизни шунчалик кўп кўради. Максимал таклифлар сонини олиш учун профилингизни 80% дан кўпроқ тўлдиришга ҳаракат қилинг",
    "Данные успешно сохранены!": "Маълумотлар муваффақиятли сақланди!",
    "появлений в результатах поиска в неделю": "Ҳафтада {param} та чиқиш қидирув натижаларида",
    "Просмотрен": "Кўрилган",
    "Не просмотрен": "Кўрилмаган",
    "Доставлен": "Етказилган",
    "Удалить": "Ўчириш",
    "Нет отклики": "Юборилган жавоб йўқ",
    "В этом блоке вы можете добавить информации о вас": "Ушбу блокда ўз ҳақингизда маълумот қўшишингиз мумкин",
    "Укажите дополнительное образование": "Қўшимча таълимни белгиланг",
    "Добавить еще": "Яна қўшиш",
    "В этом блоке вы можете добавить дополнительное образование": "Ушбу блокда Сиз қўшимча таълим қўшишингиз мумкин",
    "В этом блоке вы можете добавить желаемый вид занятости.": "Ушбу блокда Сиз бандлик турингизни қўшишингиз мумкин",
    "Вы уверены, что хотите удалить этот запись?": "Ҳақиқатан ҳам бу маълумотни ўчириб ташламоқчимисиз?",
    "Внимание": "Диққат",
    "Да": "Ҳа",
    "Нет": "Йўқ",
    "Запись успешно удалена": "Маълумот муваффақиятли ўчирилди",
    "Специализация": "Мутахассислик",
    "Регион поиска работы": "Иш топиш ҳудуди",
    "Весь город": "Бутун шахар",
    "Укажите уровень владения компьютером": "Компютер билиш даражасини белгиланг",
    "Нет личный автомобиль": "Шахсий автомобил мавжуд эмас",
    "Местоположение учебного заведения": "Таълим муассасасининг манзили",
    "Должность": "Лавозим",
    "Местоположение": "Манзил",
    "Обязанности и достижения": "Масъулият ва ютуқлар",
    "Наименование организации": "Ташкилотнинг номи",
    "Местоположение (страна)": "Манзил (мамлакат)",
    "Местоположение (регион)": "Манзил (ҳудуд)",
    "Начало работы": "Ишнинг бошланиши",
    "Завершение работы": "Ишнинг тугаши",
    "Семейное положение": "Оилавий аҳвол",
    "Семейный статус": "Оилавий ҳолат",
    "Кол-во детей": "Болалар сони",
    "В этом блоке вы можете указать семейное положение": "Ушбу блокда Сиз оилавий ҳолатингизни белгилашингиз мумкин",
    "Указать": "Белгилаш",
    "Язык": "Тил",
    "Уровень владения": "Билиш даражаси",
    "Персональная информация": "Шахсга доир маълумотлар",
    "ФИО": "ФИО",
    "Дата рождение": "Туғилган санаси",
    "Серия и номер паспорта": "Паспорт рақами",
    "ПИНФЛ": "ЖШШИР",
    "Фамилия": "Фамилия",
    "Имя": "Исм",
    "Отчество": "Отасининг исми",
    "Желаемая должность": "Исталган лавозим",
    "Мои навыки": "Менинг кўникмаларим",
    "Укажите свои навыки": "Ўз кўникмаларингизни белгиланг",
    "Кандидаты  по категориям": "Категориялар бўйича номзодлар",
    "Поиск кандидатов по городам": "Номзодларни шаҳар бўйича топиш",
    "response send": "Вакансияга юборилган жавоб",
    "Здесь хранятся соискатели, которые вас заинтересовали. Чтобы добавить соискателя в избранное посмотрите кандидатов и  нажмите на сердечку": "Сизни қизиқтирган иш изловчилар шу ерда сақланади. Иш изловчини сараланганларга қўшиш учун, номзодларни кўриб чиқиб, 'юракча' тугмасига босинг.",
    "Посмотреть кандидатов": "Номзодларни кўриш",
    "Работа в Ташкенте": "Тошкентда иш",
    "Адрес поиска работы": "Иш қидириш манзили",
    "Код отправлен на ваш номер в смс": "Код смс орқали қуйидаги рақамга юборилди:",
    "Введите код": "Кодни киритинг",
    "Количество символов должно быть максимум": "Белгилар сони кўпи билан {param} та бўлиши мумкин",
    "Количество символов должно быть": "Белгилар сони {param} та бўлиши керак",
    "Количество символов должно быть минимум": "Белгилар сони камида {param} та бўлиши керак",
    "Зарегистрируйтесь и найдите работу": "Рўйхатдан ўтинг ва излаган ишингизни топинг",
    "Напишите фамилия": "Фамилиянгизни киритинг",
    "Напишите имя": "Исмингизни киритинг",
    "Какую вы ищете работу": "Қандай иш излаяпсиз",
    "Например, учитель": "Масалан, ўқитувчи",
    "Где вы ищете работу": "Қаердан иш излаяпсиз",
    "Мужчина": "Эркак",
    "Женщина": "Аёл",
    "Город(район)": "Қайси шаҳар (туман)дан излаяпсиз",
    "Чтобы сохранить вакансию, нужно войти в систему как соискатель": "Вакансияни сақлаш учун, иш изловчи сифатида тизимга киришингиз керак",
    "Для отклика на данную вакансию ваш профиль должен быть заполнен информацией": "Бу вакансияга таклиф юборишингиз учун профилингизни тўлдиришингиз лозим.",
    "Чтобы откликнуться на эту вакансию, ваше резюме должно быть выше 70 процентов.  Заполните оставшиеся данные: ": "Бу вакансияга таклиф юборишингиз учун профилингиз 70% дан юқорига тўлдирилиши керак. Қуйидаги блокларни тўлдиринг:",
    "Заполнить профиль": "Профилни тўлдириш",
    "Весь регион": "Бутун вилоят",
    "Требования:": "Талаблар:",
    "Условия работы:": "Иш шароитлари:",
    "Обязанности:": "Мажбуриятлар:",
    "Не указывать контактное лицо (обращение только через отклик)": "Алоқа учун маълумотлар кўрсатилмасин(фақат таклиф юбориш орқали)",
    "Не указывать": "Белгиланмаган",
    "Редактировать вакансию": "Вакансияни таҳрирлаш",
    "год": "йил",
    "года": "йил",
    "лет": "йил",
    "месяц": "ой",
    "месяца": "ой",
    "месяцев": "ой",
    "age год": "ёш",
    "age года": "ёш",
    "age лет": "ёш",
    "Контактный номер": "Алоқа учун рақам",
    "Введите E-mail": "E-mail манзилини киритинг",
    "Введите контактный номер": "Алоқа учун рақам киритинг",
    "База кандидатов доступна только зарегистрированным работодателям.": "Кандидат ҳақида батафсил маълумотни кўриш учун иш берувчи сифатида рўйхатдан ўтишингиз керак.",
    "О себе": "Ўзим ҳақимда",
    "Найти сотрудника": "Ходим топиш",
    "Добавить фото": "Фотосурат қўшиш",
    "Работаю тут по настоящее время": "Ҳозир шу ерда ишлаяпман",
    "Опишите, что вы делали на работе": "Ишда нима қилганингизни тавсифлаб беринг",
    "Отмечайте понравившиеся вам вакансии и смотрите их здесь.": "Вакансияларни сақлаб қўйинг ва шу ерда кузатиб боринг.",
    "Нет избранных": "Сақланганлар йўқ",
    "Мы отправили код подтверждение на ваш E-mail": "Биз сизнинг E-mail манзилингизга тасдиқлаш кодини жўнатдик",
    "Пожалуйста, проверьте свой E-mail": "Илтимос, E-mail манзилингизни текширинг",
    "У вас пока нет опубликованных вакансий. Опубликуйте вакансию и находите лучших кандидатов!": "Ҳозирча сизда эълон қилинган вакансиялар йўқ. Вакансияларингизни эълон қилинг ва энг яхши ходимларни топинг!",
    "Сообщений пока нет": "Ҳозирча хабарлар йўқ",
    "Здесь будут находиться ваши чаты с Соискателями": "Бу ерда сизнинг номзодлар билан ёзишмаларингиз сақланади",
    "Удалить фото": "Ўчириш",
    "Добавлено в сохраненное": "Сақланганларга қўшилди",
    "Удалено из сохраненных": "Сақланганлардан ўчирилди",
    "Выбрать": "Танланг",
    "Закрыть": "Ёпиш",
    "Задать вопрос": "Савол йўллаш",
    "Нет соединения с сервером, пожалуйста проверьте подключение к интернету": "Сервер билан алоқа йўқ, интернетга уланганлигингизни текширинг",
    "Районы": "Туманлар",
    "Вакансия будет отправлена в архив, в будущем вы можете восстановить вакансию для повторной публикации.": "Вакансия архивга юборилади, келажакда сиз вакансияни қайта тиклашингиз мумкин.",
    "Переместить в архив": "Архивга ўтказиш",
    "Вакансия успешно отправлено в архив!": "Вакансия архивга кўчрилди!",
    "Пожалуйста, выберите хотя бы одно значение": "Илтимос, юқоридагилардан ҳеч бўлмаганда бирини танланг",
    "Пожалуйста, выберите одно значение": "Илтимос, юқоридагилардан бирини танланг",
    "Публикуйте вакансии, получайте отклики и общайтесь с кандидатами": "Вакансияларингизни эълон қилинг, таклифлар олинг ва номзодлар билан мулоқотга киришинг",
    "Вы можете указать дополнительную информацию о компании, загрузить лого и добавить описание": "Ташкилотингиз ҳақида қўшимча маълумотлар киритишингиз ва ташкилот логотипини юклашингиз мумкин",
    "Вакансии<br> и отклики": "Вакансиялар<br> ва таклифлар",
    "Информация<br> о компании": "Ташкилот<br> ҳақида маълумот",
    "Изменить пароль": "Паролни ўзгартириш",
    "Скачать резюме": "Юклаб олиш",
    "Настройки профиля": "Профил созламалари",
    "Удаление аккаунта": "Профилни ўчириш",
    "Вы уверены, что хотите удалить свой профиль?": "Ҳақиқатан ҳам ўз профилингизни ўчирмоқчимисиз?",
    "Код подтверждение": "Тасдиқлаш коди",
    "Посмотреть вакансии": "Вакансияларни кўриш",
    "SMS отправлено на номер": "Код {param} рақамига sms тарзида юборилди",
    "Сохраненные вакансии": "Сақланган вакансиялар",
    "Пожалуйста, введите УРЛ": "Илтимос, URL стандартида киритинг",
    "У вас нет архивные вакансий": "Сизда архивланган вакансиялар йўқ",
    "У вас нет неопубликованных вакансий": "Сизда эълон қилинмаган вакансиялар йўқ",
    "Подтвердите ваше согласие": "Розилигингизни тасдиқланг",
    "Наверх": "Саҳифа юқорисига",
    "Вернуться к регистрации": "Рўйхатдан ўтишга қайтиш",
    "Создано": "Яратилган сана",
    "У вас нет заархивированных вакансий": "Сизда архивланган вакансия йўқ",
    "Вакансия закрыта": "Вакансия ёпилган",
    "У вас <b>1</b> непрочитанных сообщений": "Сизда <b>{param}</b>та ўқилмаган хабар мавжуд",
    "У вас есть непрочитанное сообщение": "Сизда ўқилмаган хабар мавжуд",
    "Ваш статус 'Не ищу работу'": "Сизнинг статусингиз 'Иш изламаяпман'",
    "Чтобы откликнуться на вакансию, пожалуйста, поменяйте свой статус на 'Ищу работу'": "Ушбу вакансияга таклиф юбориш учун, илтимос, статусингизни 'Иш излаяпман'га ўзгартиринг",
    "Сумма должно быть больше, чем начальная сумма": "Ушбу миқдор бошланғич миқдордан кўп бўлиши керак",
    "Текущий пароль": "Ҳозрги парол",
    "Не ищет работу": "Иш изламаяпти",
    "Деактивировать аккаунт": "Аккаунтни деактивация қилиш",
    "Вы уверены, что хотите деактивировать свой профиль?": "Ҳақиқатан ҳам ўз профилингизни деактивация қилмоқчимисиз?",
    "Самый быстрый и простой способ найти работу — создать профиль на нашем сайте!": "Иш топишнинг энг тез ва осон йўли - сайтимизда профиль яратиш!",
    "Создайте профиль и система сама найдет вам работу.": "Профиль яратинг ва тизимнинг ўзи Сизга иш топиб беради.",
    "Удалённый аккаунт": "Ўчирилган ҳисоб",
    "Вакансия не найдена": "Вакансия топилмади",
    "день": "кун",
    "Фрилансер": "Фрилансер",
    "Выберите язык": "Тил танланг",

    "ish.uz - это смарт платформа для быстрого поиска работы": "<b>ish.uz</b> - бу тезкор иш топиш<br> смарт платформаси",
    "Cмарт платформа для быстрого поиска работы": "Тезкор иш топиш смарт платформаси",
    "Как работает ISH.UZ": "ISH.UZ қандай ишлайди",
    "Заполните профиль, указав информацию о себе, уровне образования и опыте работы": "<b>Профилингизни</b> ўзингиз, таълим даражангиз ва иш тажрибангиз ҳақидаги маълумотлар билан <b>тўлдиринг</b>",
    "Система в автоматическом режиме подберет самые подходящие для вас  вакансии": "Тизим автоматик равишда сиз учун <b>энг мос бўлган</b> иш ўринларини танлайди",
    "Получайте уведомления о новых вакансиях и предложения от компаний": "Янги иш ўринлари ва <b>компаниялардан таклифлар</b> ҳақида билдиришномаларни олинг",
    "5 минут и вы получите доступ к вакансиям лучших компаний": "<span>5 дақиқа</span> ва сиз энг яхши компанияларнинг вакансияларига кириш ҳуқуқига эга бўласиз",
    "Мы используем технологии использования нейросетей и машинного обучения для того, чтобы вы нашли лучшую работу как можно быстрее.": "Сиз энг яхши ишни имкон қадар тезроқ топишингиз учун биз нейрон тармоқ ва машинавий ўқитиш технологияларидан фойдаланамиз.",
    "Войти в профиль": "Профилга кириш",
    "Замечательно!": "Ажойиб!",
    "Давайте перейдем к новым возможностям!": "Келинг, янги имконият сари йўл олайлик!",
    "Мы на полпути!": "Ярим йўлни босиб ўтдик!",
    "Пропустить": "Ўтказиб юбориш",
    "Добавить язык": "Тил қўшиш",
    "Поздравляем!": "Табриклаймиз!",
    "Профиль заполнен!": "Профил тўлдирилди",
    "В каком регионе ищете работу?": "Қайси ҳудуддан иш излаяпсиз?",
    "Какую работу ищете?": "Қандай иш излаяпсиз?",
    "Добавить учебное заведение": "Муассаса қўшиш",
    "Добавить опыт работы": "Иш тажрибаси қўшиш",
    "Имеется личный автомобиль?": "Шахсий автомобил борми?",
    "Нет избранных вакансий": "Сақланган вакансиялар йўқ",
    "Вперёд": "Бошладик",
    "Пожалуйста уточните": "Илтмос, аниқлаштиринг",
    "Возможно вы имели в виду": "Қуйидагиларни назарда тутдингизми",
    "У вас есть опыт работы?": "Иш тажрибага эгамисиз?",
    "Расположение места работы": "Иш манзили",
    "Перейти в профиль": "Профилга ўтиш",
    "Профиль": "Профил",
    "Изменить": "Ўзгартириш",
    "Добавить желаемый уровень заработной платы": "Исталган иш ҳақи даражаси қўшиш",
    "Добавить знание языков": "Тил қўшиш",
    "Добавить ключевые навыки": "Кўникма қўшиш",
    "Cкопировать": "Нусхалаш",
    "Скопирован": "Нусхаланди",
    "Публичный": "Оммавий",
    "Скрытый": "Яширин",
    "Чат": "Чат",
    "Создайте вакансию в системе": "<b>Создайте вакансию</b> в системе",
    "Система автоматически подберет и оценит подходящих кандидатов на вакансию": "<b>Система автоматически подберет</b> и оценит подходящих кандидатов на вакансию",
    "Проведите все этапы отбора с помощью удобных инструментов": "Проведите все этапы отбора с помощью <b>удобных инструментов</b>",
    "Ссылка": "Ҳавола",
    "Настройте конфиденциальность вашего профиля": "Профилингиз махфийлигини созланг",
    "Мобильный телефон:": "Мобил телефон:",
    "Работодатели не могут найти ваше резюме в системе поиска ish.uz.  Это не повлияет на предыдущие заявки и не помешает компаниям, которым вы ответили, связаться с вами.": "Иш берувчилар сизнинг резюмеингизни  ish.uz қидирув тизимида топа олмайди. Бу аввалги юборилган хабарларга таъсир қилмайди ёки сиз жавоб берган компанияларнинг сиз билан боғланишига тўсқинлик қилмайди.",
    "Подробнее о конфиденциальности вашего профиль": "Профилингиз махфийлиги ҳақида кўпроқ билиб олинг",
    "Работодатели, которые ищут кандидатов, могут найти ваше резюме и информацию о профиле на сайте ish.uz в соответствии с нашими условиями": "Номзодларни қидираётган иш берувчилар ish.uz сайтида бизнинг шартларимизга мувофиқ резюме ва профил маълумотларини топишлари мумкин",
    "Молодец!": "Баракалла!",
    "Профил тўлдириш": "Профил тўлдириш",
    "Сертификаты": "Сертификатлар",
    "Добавить сертификат": "Сертификат қўшиш",
    "Наименование": "Номи",
    "Дата получения": "Олган санаси",
    "Без опыта работы": "Иш тажрибага эга эмас",
    "Добавить о себе": "Ўзингиз ҳақингизда маълумот қўшиш",
    "Добавить уровень образования": "Таълим маълумоти қўшиш",
    "Добавить уровень владения компьютером": "Компютердан фойдаланиш кўникмаси қўшиш",
    "Уровень владения компьютером": "Компютер билиш даражаси",
    "Добавить Эмаил": "Email қўшиш",
    "Подтверждать": "Тасдиқлаш",
    "Отправить код повторно можно через": "{param} дан кейин кодни қайта юбориш мумкин",
    "Отправить код еще раз": "Кодни қайта юборинг",
    "Изменить номер": "Рақамни ўзгартириш",
    "Отклик успешно отправлен!": "Таклиф муваффақиятли юборилди!",
    "Вы пока не оставляли отклика ни к одной вакансии.": "Сиз ҳали бирор вакансияга таклиф юбормагансиз.",
    "Навык": "Кўникма",
    "Резюме не найдено": "Резюме топилмади",
    "Не требуется личный автомобиль": "Шахсий автомобил талаб қилинмайди",
    "Опубликовать на {portal}": "<b>{portal}</b> да эълон қилиш",
    "Опубликовано на portals": "Қуйидагиларда эълон қилинган ",
    "Кандидаты": "Номзодлар",
    "Отчеты": "Ҳисоботлар",
    "Кадровый резерв": "Кадрлар захираси",
    "Черный список": "Қора рўйхат",
    "Пользователи": "Фойдаланувчилар",
    "Посмотреть вакансию": "Вакансияни кўриш",
    "Режим доски": "Доска режими",
    "Режим списка": "Рўйхат режими",
    "Этапы": "Босқичлар",
    "Дополнительные этапы": "Қўшимча босқичлар",
    "Наши этапы": "Бизнинг босқичларимиз",
    "Добавить этап": "Босқич қўшиш",
    "Открыть воронку": "Воронкани очиш",
    "Стандартные этапы": "Стандарт босқичлар",
    "Предложения ISH.UZ": "ISH.UZ дан таклифлар",
    "Загрузить резюме": "Резюме юклаш",
    "Загрузить фото": "Фотосурат юклаш",
    "Желаемый уровень ЗП": "Исталган иш ҳақи даражаси",
    "Добавить кандидата": "Номзод қўшиш",
    "Уровень образования": "Таълим даражаси",
    "Добавить образование": "Таълим маълумоти қўшиш",
    "Добавить ещё образование": "Яна таълим маълумоти қўшиш",
    "Фото": "Фото",
    "Дата добавления": "Қўшилган сана",
    "Кем добавлен": "Ким қўшган",
    "Скачать в Excel": "Excel га юклаб олиш",
    "Срок закрытия вакансии": "Вакансиянинг ёпилиш санаси",
    "Мы отправили ссылку активации на почту {mail}": "Фаоллаштириш ҳаволасини сизнинг қуйидаги электрон почтангизга юбордик {mail}",
    "Кандидаты не добавлены на этот этап": "Номзодлар ушбу босқичга қўшилмаган",
    "У вас нет активных вакансий": "Сизда фаол вакансиялар йўқ",
    "Комментарий": "Изоҳ",
    "Добавлен вручную": "Қўлда қўшилган",
    "Введите текст": "Матн киритинг",
    "Логин": "Логин",
    "Вы уверены, что хотите удалить этот email?": "Ҳақиқатан ҳам ушбу эмаил ни ўчирмоқчимисиз?",
    "Другая почта": "Бошқа почта",
    "Добавить почту": "Почта қўшиш",
    "Сервер почты": "Почта сервери",
    "Порт": "Порт",
    "Безопасное соединение": "Хавфсиз уланиш",
    "Введите логин": "Логинни киритинг",
    "Введите email": "Email киритинг",
    "У вас еще не добавлен email отправителя": "Сизда ҳали жўнатувчининг emailли қўшилмаган",
    "На почту": "Почтага",
    "ISH.UZ чат": "ISH.UZ чат",
    "Телеграм": "Телеграм",
    "Открыть в телеграме": "Телеграмда очиш",
    "Новое сообщение": "Янги хабар",
    "Тема письма": "Хатнинг мавзуси",
    "Кому": "Кимга",
    "От кого": "Кимдан",
    "sent_time": "Юборилган вақти",
    "Оценка профиля": "Профил баҳоси",
    "г.": "й.",
    "Высшее": "Oлий",
    "Пароль от почты": "Почтанинг пароли",
    "Создан": "Яратилган",
    "Нет информации": "Маълумот йўқ",
    "Кандидат не найден": "Номзод топилмади",
    "Введите должность": "Лавозимни киритинг",
    "Причина отказа": "Рад этиш сабаби",
    "Способ уведомления кандидата": "Номзодни хабардор қилиш усули",
    "Редактировать кандидата": "Номзодни таҳрирлаш",
    "Нет данных": "Маълумот йўқ",
    "У вас есть несохраненные данные. Хотите продолжить?": "Сизда сақланмаган маълумотлар мавжуд. Давом этишни хоҳлайсизми?",
    "Соответствие": "Мослиги",
    "Скоринг": "Скоринг",
    "Конверсия": "Конверсия",
    "Добавить заказчика": "Буюртмачи қўшиш",
    "Добавить рекрутера": "Рекрутер қўшиш",
    "Организация не зарегистрирована на этот адрес": "Ушбу <b>{param}</b> билан ташкилот рўйхатга олинмаган.Илтимос,қуйидаги маълумотларни киритинг.",
    "Добавить email отправителя": "Жўнатувчи учун эмаил қўшиш",
    "Поиск по базе<br> соискателей": "Номзодлар базасидан қидириш",
    "Находите лучших кандидатов из базы данных": "Номзодлар базасидан энг яхши номзодни топинг",
    "Введите в формате адрес@ящик.домен": "example@gmail.com форматида киритинг",
    "Восстановить пароль": "Паролни тиклаш",
    "Почта для отправки писем": "Хабар жўнатиш учун почта",
    "Удаленный профиль": "Ўчирилган профил",
    "Организация не зарегистрирована по данному адресу email.": "Ушбу <b>{email}</b> билан ташкилот рўйхатга олинмаган.",
    "Пожалуйста, введите следующую информацию.": "Илтимос, қуйидаги маълумотларни киритинг.",
    "Электронный адрес email не привязан ни к одной из компаний.": "<b>{email}</b> электрон почтаси ҳеч бир ташкилотга боғланмаган.",
    "Для регистрации новой компании заполните поля ниже:": "Янги ташкилотни рўйхатдан ўтказиш учун қуйидаги маълумотларни киритинг:",
    "На e-mail email отправлено письмо с кодом доступа.": "<b>{email}</b> электрон почтага тасдиқлаш коди юборилди.",
    "Пожалуйста, проверьте электронную почти и введите код из письма.": "Илтимос, электрон почтангизни текширинг ва хабардаги кодни киритинг.",
    "Чат с соискателями": "Номзодлар билан чат",
    "По вашим опубликованным вакансиям есть новые отклики": "Эълон қилинган вакансияларингизга янги номзодлар бор",
    "n отклик": "номзод",
    "n отклика": "номзод",
    "n откликов": "номзод",
    "Не опубликовано": "Эълон қилинмаган",
    "Опубликованные": "Эълон қилинганлар",
    "Не опубликованные": "Эълон қилинмаганлар",
    "Статус": "Статус",
    "Архив": "Архив",
    "соискателей": "номзодлар",
    "У вас есть новые отклики": "Сизда янги жавоблар бор",
    "У вас нет новых откликов": "Сизда янги жавоблар йўқ",
    "Вы не можете добавить кандидата на эту доску": "Сиз ушбу устунга номзод қўша олмайсиз",

    "Осталось {n} день доступа": "Рухсат тугашига {n} кун қолди",
    "Осталось {n} дня доступа": "Рухсат тугашига {n} кун қолди",
    "Осталось {n} дней доступа": "Рухсат тугашига {n} кун қолди",
    "Выберите подходящий тарифный план для доступа к базе соискателей": "Номзодлар базасига уланиш учун ўзингизга мос тарифни танланг",
    "Рекомендуемый": "Тавсия этилади",
    "Доступ к базе соискателей": "Номзодлар базасига уланиш",
    "дней": "кун",
    "Вы уже достигли этот лимит": "Сизнинг лимитингиз тўлган",
    "c функцией “Автоподбор”": "“Автоматик тавсия” функцияси билан",
    "Количество вакансий": "Вакансиялар сони",
    "Вы выбрали": "Танланган тариф",
    "Доступ к базе данных кандидатов": "Номзодлар базасига уланиш",
    "Срок": "Муддати",
    "Автоподбор кандидатов": "Номзодларни автоматик тавсия этиш",
    "Наша алгоритмы подберут наилучших кандидатов, вам останется только выбрать": "Бизнинг алгоритмларимиз энг яхши номзодларни тавсия этади, сиз фақат танлашингиз керак",
    "Бесплатно при покупке доступа к базе": "Бонус",
    "У вас уже существует не оплаченный счет на этот тариф": "Сизда бу тариф учун тўланмаган ҳисоб-фактура мавжуд",
    "Пожалуйста оплатите": "Илтимос тўланг",
    "Дата создание": "Яратилган санаси",
    "Общая сумма": "Умумий сумма",
    "Не оплачен": "Тўланмаган",
    "Оплачен": "Тўланган",
    "Оплатить через Payme": "Payme орқали тўлаш",
    "История оплаты": "Тўловлар тарихи",
    "Виберите способ оплаты": "Тўлов усулини танланг",
    "Оплата производится через платежную систему Payme": "Тўлов Payme тўлов тизими орқали амалга оширилади",
    "Оплатить": "Тўлаш",
    "Оплата производится через банковский счет": "Тўлов банк ҳисоб рақами орқали амалга оширилади",
    "Сформировать счет": "Тўловни шакллантириш",
    "Расчетный счет": "Ҳисоб рақами",
    "Банк": "Банк",
    "МФО": "Банк коди(МФО)",
    "ИНН": "ИНН",
    "ОКЭД": "ОКЕД",
    "Рег.код НДС": "ҚҚС рўйхатга олиш коди",
    "Тариф": "Тариф",
    "Дата начало": "Бошланган санаси",
    "Дата окончание": "Тугаш санаси",
    "Дата оплаты": "Тўлов санаси",
    "Способ оплаты": "Тўлов тури",
    "Посмотреть": "Кўриш",
    "Исполнитель": "Ижрочи",
    "Эл.почта": "Эл.почта",
    "Список заказанных услуг": "Буюртма қилинган хизматлар рўйхати",
    "Кол-во": "Сони",
    "Стоимость": "Нархи",
    "Размещение вакансий": "Вакансиялар сони",
    "Итого к оплате": "Жами сумма",
    "Не забудьте оплатить счет в течение 1-го дня.": "Тўловни 1 кун ичида тўлашни унутманг.",
    "После формирования счета на оплату вы сразу сможете получить доступ к базе соискателей и функционалу автопоиска.": "Тўлов учун ҳисоб-фактурани яратганингиздан сўнг, сиз дарҳол номзодлар базасига киришингиз ва “Автоматик тавсия” функциясидан фойдаланишингниз мумкин бўлади.",
    "Скачать счет": "Ҳисобни юклаб олиш",
    "Не ограничено": "Чекланмаган",
    "Счет на оплату": "Тўлов ҳисоби",

    "Электронная почта": "Электрон почта",

    "Сохранить вакансию": "Вакансияни сақлаш",
    "Мои кандидаты": "Менинг номзодларим",
    "Контактный телефон": "Телефон рақами",
    "Оплаченный период": "Тўланган давр",

    "Осталось {month} месяц доступа": "Рухсат тугашига {month} ой қолди",
    "Осталось {month} месяца доступа": "Рухсат тугашига {month} ой қолди",
    "Осталось {month} месяцев доступа": "Рухсат тугашига {month} ой қолди",

    "Осталось {month} месяц и {n} день доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Осталось {month} месяц и {n} дня доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Осталось {month} месяц и {n} дней доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",

    "Осталось {month} месяца и {n} день доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Осталось {month} месяца и {n} дня доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Осталось {month} месяца и {n} дней доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",

    "Осталось {month} месяцев и {n} день доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Осталось {month} месяцев и {n} дня доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Осталось {month} месяцев и {n} дней доступа": "Рухсат тугашига {month} ой ва {n} кун қолди",
    "Продлить доступ": "Рухсатни узайтириш",
    "Купить доступ": "Рухсатни сотиб олиш",
    "Нет доступа": "Киришга рухсат йўқ",
    "Бесплатно": "Текин",
    "Итого": "Жами",
    "Автоподбор": "Автоматик тавсия",
    "База кандидатов": "Номзодлар базаси",
    "Моя компания": "Менинг компаниям",
    "Договора и счета": "Шартномалар ва тўловлар",
    "Тарифы": "Тарифлар",
    "Период": "Муддат",
    "Счета": "Тўловлар",
    "Договора": "Шартномалар",
    "Выберите компанию": "Компанияни танланг",
    "Выберите период": "Муддатни талнанг",
    "дня": "кун",


    "Счет на оплату № invoice_number к договору № contract_number доступ ISH.UZ period price сум": "{contract_number}-сонли шартнома бўйича  ISH.UZ PRO дан <b>{period}</b>лик фойдаланиш ҳуқуқи учун <b>{price}</b> сўмга  {invoice_number}-сонли ҳисоб",
    "Счет на оплату № invoice_number доступ ISH.UZ period price сум": "ISH.UZ PRO дан <b>{period}</b>лик фойдаланиш ҳуқуқи учун <b>{price}</b> сўмга  {invoice_number}-сонли ҳисоб",
    "Договор № contract_number от contract_date года возмездное оказание услуг ISH.UZ": "ISH.UZ PRO сайтида пулли хизматлар учун <b>{contract_date}</b> йилдаги <b>{contract_number}</b>-сонли шартнома",

    "Руководитель": "Раҳбар",
    "Введите пароль": "Паролни киритинг",
    "Пока у вас не сформированы счета": "Ҳозирча сизда ҳисоблар яратилмаган",
    "Уважаемый user": "Ҳурматли {user}",
    "Мы предоставили вашей компании полный доступ к сайту не дожидаясь поступления оплаты. Доступ действует в течение days. Пожалуйста, не забудьте оплатить счет.": "Биз сизнинг компаниянгизга тўловни кутмасдан сайтга тўлиқ кириш ҳуқуқини бердик. Тўловсиз кириш {days} давомида амал қилади. Илтимос, ҳисобни тўлашни унутманг.",
    "Распечатать": "Чоп этиш",
    "Введите реквизиты организации": "Ташкилот реквизитларини киритинг",
    "ФИО руководителя": "Раҳбар ФИОси",
    "Должность руководителя": "Раҳбар лавозими",
    "Действующего на основании": "Фаолият асоси",
    "Создать договор": "Шартнома яратиш",

    "Для получения доступа к базе кандидатов, подключите тариф.": "Номзодлар базасига рухсат учун тарифни уланг.",
    "Тарифы от 290 000 сум.": "Тарифлар 290 000 сўмдан.",
    "У вас нет доступа к базе кандидатов.": "Сизда номзодлар базасига рухсат йўқ.",
    "Пробный период": "Синов муддати",
    "Оплатить доступ": "Тўлаш",
    "Сегодня последний день доступа": "Бугун кириш рухсатининг охирги куни",
    "Выбрать тариф": "Тарифни танлаш",
    "Если у вас возникли вопросы, позвоните на номер +998 95 169-08-08": "Агар сизда саволлар бўлса, <a href='tel:+998951690808'>+998 95 169-08-08</a> рақамига қўнғироқ қилинг",
    "Телефон службы поддержки": "Қўллаб-қувватлаш телефон рақами",
    "Выберите тариф, чтобы открыть доступ к контактным данным кандидатов и использовать все возможности сервиса": "Номзодларнинг алоқа маълумотларини кўриш ва тизимнинг имкониятларидан тўлиқ фодайланиш учун тарифни танланг",
    "Поздравляем": "Табриклаймиз",
    "Ваша оплата прошла успешно": "Тўловингиз мувафаққиятли ўтказилди",
    "Теперь вам доступна все возможности сервиса": "Энди тизимнинг имкониятларидан тўлиқ фойдаланишингиз мумкин",
    "Перейти на главную страницу": "Бош саҳифага ўтиш",
    "Оплата не проведена": "Тўлов амалга оширилмади",

    "Профиль заполнен на русском языке": "Профил рус тилида тўлдирилган",
    "Профиль заполнен на узбекском языке": "Профил ўзбек тилида тўлдирилган",
    "Запросить дополнительные данные": "Қўшимча маълумотларни сўраш",
    "Выберите данные которые вы хотите запросить": "Қандай маълумотни сўрамоқчилигингизни танланг",
    "Ваш запрос успешно отправлен!": "Сизиннг сўровингиз муваффақиятли юборилди",
    "Запросить информацию": "Маълумот сўраш",
    "Добавить к себе": "Номзодларимга қўшиш",
    "В кадровый резерв": "Кадрлар захирасига қўшиш",
    "В черный список": "Қора рўйхатга қўшиш",
    "Кандидат добавлен в кадровый резерв": "Номзод кадрлар захирасига қўшилган",
    "Уведомления": "Xabarnomalar",
    "Получать уведомления о новых кандидатах на вакансии(отклики) (11:00)": "Вакансияларга янги номзодлар(откликлар) ҳақида 11:00 да хабарнома олиш",
    "Получать уведомления о новых кандидатах на вакансии(отклики) (17:00)": "Вакансияларга янги номзодлар(откликлар) ҳақида 17:00 да хабарнома олиш",
    "Активный": "Фаол",
    "Неактивный": "Нофаол",
    "Сортировка по дате": "Сана бўйича саралаш",
    "Сортировка по соответствию": "Мослиги бўйича саралаш",
    "Сортировка по скорингу": "Скоринг бўйича саралаш",
    "Показывать только новые": "Фақат янгиларни кўрсатиш",
    "Время последнего входа": "Охирги кирган вақти",
    "Превышен лимит доступных устройств на этом аккаунте": "Ушбу акаунтдаги қурилмалар лимитидан ошиб кетди",
    "Вы можете удалить одно из нижеперечисленных устройств, чтобы войти": "Кириш учун қуйидаги қурилмалардан бирини ўчиришингиз мумкин",
    "по": "гача",
    "position_name в company_name": "<b>{company_name}</b> да <b>{position_name}</b>",
    "Есть личное авто": "Шахсий автомобил мавжуд",
    "Водительские права": "Ҳайдовчилик гувоҳномаси",
    "Предпочтительный возраст": "Мос келувчи ёш",
    "Предпочтительный пол": "Вакансия кимларга мос",
    "эта информация только для автоподбора и не будет отображаться для соискателей": "ушбу маълумот номзодларни автоматик саралаш учун ишлатилади, иш изловчиларга кўрсатилмайди",
    "до param_to": "{param_to} гача",
    "от param_from до param_to": "{param_from} дан {param_to} гача",
    "от param_from": "{param_from} дан",
    "ФИО контактного лица": "Алоқа учун шахснинг ФИОси",
    "Номер телефона контактного лица": "Алоқа учун шахснинг телефон рақами",
    "Данная информация не публикуется в вакансии. Используется только для уточнения автоматического подбора.": "Ушбу маълумот вакансияда эълон қилинмайди. Фақат автоматик тавсияни такомиллаштириш учун фойдаланилади.",
    "Предпочтения к кандидатам": "Вакансияга мос келувчилар",
    "Возраст": "Ёши",
    "Вакансия подойдет для кандидатов": "Кимларга мос келади",
    "Доступы к вакансиям": "Қайси вакансияларга рухсати бор?",
    "Только свои вакансии": "Фақат ўзи яратган вакансияларига",
    "Все вакансии right": "Ҳамма вакансияларга",
    "Выбрать вакансию": "Вакансия танлаш",
    "Роль": "Рол",
    "Администратор": "Администратор",
    "Подходит": "Тоғри келади",
    "Не подходит": "Тоғри келмайди",
    "Заказчик одобрил": "Буюртмачи маъқуллади",
    "Заказчик не одобрил": "Буюртмачи маъқулламади",
    "Может создавать вакансии, имеет доступ ко всей базе данных соискателей, управляет этапами отбора.": "Вакансиялар яратиши, барча номзодларнинг базасига кириш ҳуқуқи ва босқичларини бошқаради.",
    "Имеет доступ к отобранным кандидатам, оценивает кандидатов на конкретной вакансии.": "Танланган номзодларни кўриш ҳуқуқига эга ва муайян вакансия учун номзодларни баҳолайди.",
    "Добавить пользователя": "Фойдаланувчи қўшиш",
    "Прокомментируйте действие": "Изоҳ қолдиринг",
    "Спам": "Спам",
    "Кандидатов пока нет": "Ҳозирча номзодлар йўқ",
    "Этап": "Босқич",
    "Сортировка по опыту работы": "Иш тажрибаси бўйича саралаш",
    "Поля не может быть пустым": "Майдон бўш бўлиши мумкин эмас",
    "Описание вакансии не может быть менее 150 символов": "Вакансия тавсифи 150 белгидан кам бўлмаслиги керак",
    "Другие": "Бошқалар",
    "Дата публикации": "Эълон қилинган сана",
    "Город(район) работы": "Иш шаҳри(тумани)",
    "Кандидат добавлен к вакансии vacancy_position_name": "Номзод {vacancy_position_name} вакансияга қўшилди",
    "Успешно добавлен": "Муваффақиятли қўшилди",
    "Перейти к вакансии": "Вакансияга ўтиш",
    "Подтвердить адрес": "Манзилни тасдиқлаш",
    "Собеседование": "Суҳбат",
    "Собеседование не назначено": "Суҳбат белгиланмаган",
    "Назначить собеседование": "Суҳбатни белгилаш",
    "Дата": "Сана",
    "Время": "Вақт",
    "Добавить участников": "Иштирокчиларни қўшиш",
    "Добавить ссылку на онлайн собеседование": "Онлайн суҳбатга ҳаволани қўшинг",
    "Укажите адрес, где будет проходить собеседование": "Суҳбат ўтказиладиган манзилни киритинг",
    "показать на карте": "харитада кўрсатиш",
    "Дата и время": "Сана ва вақт",
    "Отменить собеседование": "Суҳбатни бекор қилиш",
    "Собеседование проведено": "Суҳбат ўтказилди",
    "Календарь": "Календар",
    "Текст": "Матн",
    "Отладчик": "Отладчик",
    "Назначено собеседование": "Суҳбат белгиланди",
    "Понедельник": "Душанба",
    "Вторник": "Сешанба",
    "Среда": "Чоршанба",
    "Четверг": "Пайшанба",
    "Пятница": "Жума",
    "Суббота": "Шанба",
    "Воскресенье": "Якшанба",
    "Январь": "Январ",
    "Февраль": "Феврал",
    "Март": "Март",
    "Апрель": "Апрел",
    "Май": "Май",
    "Июнь": "Июн",
    "Июль": "Июл",
    "Август": "Август",
    "Сентябрь": "Сентябр",
    "Октябрь": "Октябр",
    "Ноябрь": "Ноябр",
    "Декабрь": "Декабр",
    "Сегодня": "Бугун",
    "Пожалуйста, введите корректную ссылку": "Илтимос, тўғри ҳавола киритинг",
    "Добавить к вакансии": "Вакансияга бириктириш",

    "Вопрос": "Савол",
    "Варианты": "Вариантлар",
    "Вариант": "Вариант",
    "Добовить еще вариант": "Яна вариант қўшиш",
    "Добавить вопрос": "Савол қўшиш",
    "Мои тесты": "Менинг тестларим",
    "Название теста": "Тест номи",
    "Добавить тест": "Тест қўшиш",
    "Вы уверены, что хотите удалить этот тест?": "Ҳақиқатан ҳам ушбу тестни ўчирмоқчимисиз?",
    "Тест успешно удалено!": "Тест муваффақиятли ўчирилди!",
    "Тесты": "Тестлар",
    "Отправить тест": "Тест юбориш",
    "прошёл": "топширди",
    "не прошёл": "кутилмоқда",
    "пройден": "топширди",
    "в ожидании": "кутилмоқда",
    "Создать тест": "Тест яратиш",
    "Выберите тип": "Тест турини танланг",
    "Правильный вариант": "Тўғри жавоб",
    "Неправильный вариант": "Нотўғри жавоб",
    "Редактировать тест": "Тестни таҳрирлаш",
    "баллов": "балл",
    "Результат": "Натижа",
    "Добавить резюме на вакансию position_name": "{position_name} вакансиясига резюме қўшиш",
    "Добавить резюме": "Резюме қўшиш",
    "Поиск резюме": "Резюме қидириш",
    "на ISH.UZ": "ISH.UZ дан",
    "на HeadHunter.uz": "HeadHunter.uz дан",
    "на OLX.uz": "OLX.uz дан",
    "У вас нет тесты": "Сизда тестлар йўқ",
    "Ссылка на онлайн собеседование": "Онлайн суҳбатга ҳавола",
    "указать": "танлаш",
    "Чтобы легко добавлять кандидатов с других порталов поиска работы на ISH.UZ, установите расширение для браузера Google Chrome.": "Кандидатларни бошқа иш излаш порталларидан ISH.UZ га қулай равишда қўшиш учун, Google Chrome браузери учун кутубхонани ўрнатинг.",
    "После установки расширения на профиле кандидата появится кнопка «Добавить в ISH.UZ», если вы имеете доступ к контактным данным кандидата.": "Кутубхонани ўрнатганингиздан сўнг, кандидат профилида, кандидатнинг алоқа маълумотларини кўришга ҳуқуқингиз бўлса \"ISH.UZ га қўшиш\" тугмаси кўринади.",
    "Установить расширение": "Кутубхонани ўрнатиш",
    "Перейти на portal": "{portal} га ўтиш",
    "Эффективности рекрутеров": "Рекрутерларнинг самарадорлиги",
    "Рекрутер / вакансия": "Рекрутер / вакансия",
    "Количество вакансии": "Вакансиялар сони",
    "Статус вакансии": "Вакансиялар статуси",
    "Закрытые вакансии": "Ёпилган вакансиялар",
    "Все рекрутеры": "Барча рекрутерлар",
    "Закрыта": "Ёпилган",
    "Открыта": "Очиқ",
    "Дата открытия": "Очилган сана",
    "Дата закрытия": "Ёпилган сана",
    "Количество кандидатов": "Номзодлар сони",
    "Длительность": "Давомийлиги",
    "Время закрытия вакансии": "Вакансиялар ёпиш вақти",
    "Публикация вакансии на сайтах поиска работы": "Вакансияни иш излаш сайтларида эълон қилиш",
    "Подключить HeadHunter": "HeadHunter га улаш",
    "HeadHunter подключен": "HeadHunter уланган",
    "Подключить": "Улаш",
    "Если у вас есть аккаунт работодателя на сайте hh.uz, вы можете его подключить к ISH.UZ": "Агар сизда hh.uz сайтида иш берувчи сифатида аккаунтингиз бўлса, уни ISH.UZ га улашингиз мумкин.",
    "Интеграции": "Интеграциялар",
    "Отправлено тестовое задание": "Тест топшириғи юборилди",
    "Отправлено СМС сообщение": "СМС хабар юборилди",
    "Доступы к этапам": "Қайси босқичларга рухсати бор?",
    "Все этапы": "Барча босқичлар",
    "Выбрать этапы": "Босқичларни танлаш",
    "Срок прохождения этапа": "Босқичдан ўтиш вақти",
    "Количество дней": "Кунлар",
    "У вас не осталось вакансий для публикации в hh.uz": "Сизда hh.uz сайтида жойлаштириш учун вакансиялар қолмаган",
    "Анонимная премиум": "Аноним премиум",
    "Премиум рекламная": "Премиум рекламалик",
    "Анонимная: скрытый подбор": "Аноним: яширин танлов",
    "Стандарт плюс: автообновление": "Стандарт плюс: авто янгилаш",
    "Премиум: неделя в топе": "Премиум: бир хафта топда",
    "Рекламная": "Рекламалик",
    "Бесплатная": "Бепул",
    "Стандарт: без обновления": "Стандарт: янгилашсиз",
    "Прикрепить проверочный тест": "Синов тестини бириктириш",
    "Вы можете отправить тестовое задание для кандидатов, чтобы при отклике проверить их": "Номзод таклиф юборишдан олдин ушбу тестни ечиш талаб қилинади",
    "Выберите тест": "Тестни танланг",
    "Результаты теста": "Тест натижалари",
    "Cопроводительный текст": "Қўшимча матн",
    "У вас нет доступа на ISH.UZ PRO": "Сизда ISH.UZ PRO га рухсат йўқ",
    "Перейти на ISH.UZ": "ISH.UZ га ўтиш",
    "Просрочено": "Муддати ўтган",
    "Просрочен": "Муддати ўтган",
    "Превышено время нахождения кандидата на этом этапе": "Номзоднинг ушбу босқичда ўтказган вақти ошиб кетди",
    "Доступ к системе для автоматизации подбора персонала ISH.UZ PRO": "Ходимларни ишга олишни автоматлаштирувчи ISH.UZ PRO дан фойдаланиш",
    "за одного рекрутера": "ҳар бир рекрутер учун",
    "Выберите подходящий тарифный план для подключения к системе по автоматизации подбора персонала ISH.UZ PRO": "Ходимларни ишга олишни автоматлаштирувчи ISH.UZ PRO тизимига уланиш учун мос тарифни танланг.",
    "Доступ к ISH.UZ PRO": "ISH.UZ PRO га кириш имконияти",
    "Продлить доступы": "Рухсатларни узайтириш",
    "Купить еще доступ": "Қушимча рухсат олиш",
    "Отменить продление": "Узайтиришни бекор қилиш",
    "Создать счет": "Тўловни шакллантириш",
    "<b>Выберите</b> кому хотите продлить доступ.<br> После выбора внизу появится кнопка <br>\"<b>Создать счет</b>\"": "Фойдаланувчиларни <b>танланг</b>.<br> Танлаганингиздан кейин пастда \"<b>Тўловни шакллантириш</b>\" тугмаси чиқади",
    "Рекрутера": "Рекрутерлар",
    "Выбрано": "Танланганлар",
    "Выберите тариф": "Тарифни танланг",
    "Стоимость каждого рекрутера recruiterPerPrice сум в месяц": "Ҳар бир рекрутерни ойлик нархи {recruiterPerPrice} сўм",
    "Сумма тарифа": "Тариф нархи",
    "Сумма рекрутеров": "Рекрутерларни нархи",
    "Счёт успешно создано! Не забудьте его оплатить.": "Ҳисоб яратилди! Ҳисобни тўлашни унутманг.",
    "Количество доступов": "Рухсатлар сони",
    "открытая": "очиқ",
    "закрытая": "ёпиқ",
    "Мои заявки": "Менинг сўровларим",
    "Заявки": "Сўровлар",
    "Создать заявку": "Сўров яратиш",
    "Редактировать заявку": "Сўровни таҳрирлаш",
    "Открытые заявки": "Очиқ сўровлар",
    "Закрытые заявки": "Ёпилган сўровлар",
    "Вы уверены, что хотите отклонить эту заявку?": "Ҳақиқатан ҳам ушбу сўровларни рад қилмоқчимисиз?",
    "Отклонить": "Рад этиш",
    "Отклоненные заявки": "Рад этилган сўровлар",
    "отклонена": "Рад этилган",
    "Причина отклонений": "Рад этиш сабаби",
    "подтверждена": "тасдиқланган",
    "Подтвердить": "Тасдиқлаш",
    "Подтвержденные заявки": "Тасдиқланган сўровлар",
    "Добвить рекрутеров": "Рекрутерлар қўшиш",
    "Количество рекрутеров": "Rekruterlar soni",
    "Для продление доступов выберите Администратора тоже": "Рухсатларни узайтириш учун Администраторни ҳам танланг",
    "Сделать по умолчанию": "Кейинги вакансияларга қўллаш",
    "на linkedin.com": "linkedin.com дан",
    "Брендирование вакансий": "Вакансияни брендлаш",
    "Выпускники ВУЗа": "ОТМ битирувчилари",
    "Документооборот": "Ҳужжат алмашинуви",
    "Общий": "Умумий",
    "Прямые заявители": "Тўғридан-тўғри ариза берувчилар",
    "Проактивный поиск": "Проактив қидирув",
    "Outlook календарь подключен": "Outlook календар уланган",
    "Синхронизировать ISH.UZ и Outlook календарь": "ISH.UZ ва Outlook календарини синхронлаштириш",
    "Если вы хотите синхронизировать ваши события с Outlook календарем, вы можете его подключить к ISH.UZ": "Тадбирларингизни Outlook календарингиз билан синхронлашни истасангиз, уни ISH.UZ га улашингиз мумкин",
    "Воронка вакансии": "Вакансия воронкаси",
    "Источник вакансии": "Вакансия манбаси",
    "Заголовок": "Сарлавҳа",
    "Описание": "Тавсиф",
    "Публикуется на {portal}": "{portal} да эълон қилинмоқда",
    "Установите таймер": "Таймер ўрнатинг",
    "Часы": "Соат",
    "Минуты": "Дақиқа",
    "Установить таймер": "Таймер ўрнатиш",
    "Не был выбран вариант ответа": "Ҳеч қандай жавоб танланмаган",
    "Мои папки": "Менинг папкаларим",
    "Создать папку": "Папка яратиш",
    "Название папки": "Папка номи",
    "Выберите папку": "Папкани танланг",
    "Добавить в папку": "Папкага қўшиш",
    "Редактировать собеседование": "Суҳбатни ўзгартириш",
    "Все кандидаты": "Барча номзодлар",
    "Перейти в мои кандидаты": "Менинг номзодларимга ўтиш",
    "Кандидат не подходит": "Номзод тўғри келмади",
    "Хотите отправить уведомление кандидату?": "Номзодга хабар юбормоқчимисиз?",
    "Был на сайте сегодня в time": "Сайтда бугун соат {time} да бўлган",
    "Был на сайте вчера в time": "Сайтда кеча соат {time} да бўлган",
    "Был на сайте меньше недели назад": "Сайтда шу ҳафта ичида бўлган",
    "Был на сайте более недели назад": "Сайтда бўлганига бир ҳафтадан ошган",
    "Был на сайте более двух недель назад": "Сайтда бўлганига икки ҳафтадан ошган",
    "Множественный выбор вариантов": "Бир нечта танлов вариантлари",
    "Редактировать приветственный текст": "Бошланғич матнни таҳрирлаш",
    "Приветственный текст": "Бошланғич матн",
    "На русском": "Рус тилида",
    "На узбекском (кириллица)": "Ўзбек тилида (кирилл)",
    "На узбекском (латиница)": "Ўзбек тилида (лотин)",
    "Кандидат откликнулся на эту вакансию": "Номзод бу вакансияга таклиф юборган",
    "Дата и время отклика": "Таклиф юборилган вақт",
    "Получить контакты": "Алоқа маълумотларини олиш",
    "Показать в случайном порядке": "Тасодифий тартибда кўрсатиш",
    "Количество показываемых тестов": "Ҳар бир номзод учун кўрсатилиши керак бўлган тестлар сони",
    "Типы ответов": "Жавоб бериш тури",
    "Прикрепить картинку": "Расм бириктириш(графиклар,жадваллар учун)",
    "Один из списка": "Рўйхатдагилардан бири",
    "Несколько из списка": "Рўйхатдагилардан бир нечтаси",
    "Текстовые поля": "Матн киритиш майдони",
    "Правильный ответ": "Тўғри жавоб",
    "Посмотреть результаты": "Натижаларни кўриш",
    "Импорт из hh.uz": "hh.uz дан импорт қилиш",
    "Ваши вакансии в hh.uz": "Сизнинг hh.uz даги вакансияларингиз",
    "Импортировать": "Импорт қилиш",
    "Переподключится": "Қайта улаш",
    "Показать только по ссылке": "Фақат ҳавола орқали кўрсатилсин",
    "Подключение к Telegram Bot": "Телеграм Бот га уланиш",
    "вы можете получать уведомления через бота": "хабарномаларни бот орқали олишингиз мумкин",
    "UTM ссылка": "УТМ ҳавола",
    "Цена": "Нарх",
    "Токен": "Токен",
    "Без регистрации": "Рўйхатсиз",
    "Создать ссылку UTM": "УТМ ҳаволани яратиш",
    "Введите название": "Номини киритинг",
    "Название UTM": "УТМ номи",
    "Дата создания": "Яратилган вақти",
    'Количество просмотров': 'Кўришлар сони',
    'Количество заявок': 'Аризалар сони',
};