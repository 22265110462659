import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/vacancies',
        method: 'get',
        params
    });
}

export function store(data) {
    return request({
        url: '/vacancies',
        method: 'post',
        data
    });
}

export function show(id) {
    return request({
        url: '/vacancies/' + id + '?nl=1',
        method: 'get'
    });
}

export function update(id, data) {
    return request({
        url: '/vacancies/' + id,
        method: 'put',
        data
    });
}

export function destroy(id) {
    return request({
        url: '/vacancies/send-archive/' + id,
        method: 'put'
    });
}

export function publish(id, data) {
    return request({
        url: '/vacancies/publish/' + id,
        method: 'put',
        data
    });
}

export function myVacancies(params) {
    return request({
        url: '/my-vacancies',
        method: 'get',
        params
    });
}

export function myVacanciesActive(params) {
    return request({
        url: '/my-vacancies/active',
        method: 'get',
        params
    });
}

export function myVacanciesArchived(params) {
    return request({
        url: '/my-vacancies/archived',
        method: 'get',
        params
    });
}

export function myVacanciesCount(params) {
    return request({
        url: '/my-vacancies/count',
        method: 'get',
        params
    });
}

export function response(data) {
    return request({
        url: '/vacancy-response',
        method: 'post',
        data
    });
}

export function getVacanyResponses(params) {
    return request({
        url: '/vacancy-response',
        method: 'get',
        params
    });
}

export function respondCandidateShow(id) {
    return request({
        url: '/vacancy-response/' + id,
        method: 'put'
    });
}

export function getCompatibleVacancies(params) {
    return request({
        url: '/compatible-vacancies',
        method: 'get',
        params
    });
}

export function shortListbyPositionName(params) {
    return request({
        url: '/vacancies/get-by-position-name',
        method: 'get',
        params
    });
}

export function countAutoselect(id) {
    return request({
        url: '/autoselection/resumes/count/' + id,
        method: 'get',
    });
}

export function resendDraft(id) {
    return request({
        url: '/vacancies/resend-draft/' + id,
        method: 'put',
    });
}

export function hhAvailableTypes() {
    return request({
        url: '/vacancies/hh-available-types',
        method: 'get',
    });
}

export function hhBrandTemplates() {
    return request({
        url: '/vacancies/hh-brand-templates',
        method: 'get',
    });
}

export function getVacancyFunnel(id) {
    return request({
        url: '/company-reports/candidates-funnel/' + id,
        method: 'get'
    });
}

export function getHHVacancies() {
    return request({
        url: '/vacancies/hh-vacancies',
        method: 'get'
    });
}

export function importHHVacancies(data) {
    return request({
        url: '/vacancies/import-from-hh',
        method: 'post',
        data
    });
}

export function getUTMList(data) {
    return request({
        url: '/vacancy-utm-sources/' + data.id,
        method: 'get',
        params: data.params
    })
}

export function createUTM(data) {
    return request({
        url: '/vacancy-utm-sources',
        method: 'post',
        data
    })
}