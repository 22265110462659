import {
  show, update, index, store, destroy,
  index_question,show_question,store_question,update_question,destroy_question, candidateAssessments,
  candidateSendAssessment
} from '@/api/assessment'

export const actions = {
  index({ commit }, query) {
    return new Promise((resolve, reject) => {
      index(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroy({ }, id) {
    return new Promise((resolve, reject) => {
      destroy(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  // 
  index_question({ commit }, query) {
    return new Promise((resolve, reject) => {
      index_question(query).then(res => {
        // commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  show_question({ commit }, id) {
    return new Promise((resolve, reject) => {
      show_question(id).then(res => {
        commit('SET_QUESTIONS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  store_question({ commit }, data) {
    return new Promise((resolve, reject) => {
      store_question(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  update_question({ commit }, data) {
    return new Promise((resolve, reject) => {
      update_question(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  destroy_question({ }, id) {
    return new Promise((resolve, reject) => {
      destroy_question(id).then(res => {
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  candidateAssessments({ commit }, data) {
    return new Promise((resolve, reject) => {
      candidateAssessments(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
  candidateSendAssessment({ commit }, data) {
    return new Promise((resolve, reject) => {
      candidateSendAssessment(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
}
