<template>
  <el-input type="tel" key="phone" v-model="model" :placeholder="$t(placeholder)" v-mask="'###############'">
    <template slot="prepend">+</template>
  </el-input>
</template>

<script>

export default {
  props:{
    value: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  data(){
    return {
      model: ''
    }
  },
  watch: {
    model(value){
      if (value && value.length) {
        this.$emit('input', '+'+value);
      } else {
        this.$emit('input', '');
      }
    }
  },
  mounted(){
    if (this.value && this.value.indexOf('+') == 0) {
      this.model = this.value.slice(1);
    } else {
      this.model = this.value?this.value:"";
    }
  },
  computed: {}
}
</script>
