import { 
  getChats,  
  createChat,  
  getMessages,  
  sendMessage,
  showedUpdate
} from '@/api/chat'
export const actions = {
  getChats({ commit }, params) {
    return new Promise((resolve, reject) => {
      getChats(params).then(res => {
        commit('SET_CHATS', res.data)
        commit('SET_NOT_SHOWED', params)
        resolve(res)
      }).catch(error => { reject(error) })
    })
  },
  createChat({ commit }, data) {
    return new Promise((resolve, reject) => {
      createChat(data).then(res => {
        resolve(res)
      }).catch(error => { reject(error) })
    })
  },
  getMessages({ commit }, params) {
    return new Promise((resolve, reject) => {
      getMessages(params)
        .then(res => {
          commit('SET_MESSAGES', res.data)
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  appendMessages({ commit }, params) {
    return new Promise((resolve, reject) => {
      getMessages(params)
        .then(res => {
          commit('APPEND_MESSAGES', res.data)
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  sendMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendMessage(data)
        .then(res => {
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  selectChat({ commit }, chat) {
    commit('SET_SELECT_CHAT', chat)
  },
  addMessage({ commit }, message) {
    commit('ADD_MESSAGE', message)
  },
  showedUpdate({ commit }, data) {
    return new Promise((resolve, reject) => {
      showedUpdate(data)
        .then(res => {
          resolve(res)
        }).catch((res) => { reject(res) })
    })
  },
  resetUnreadCount({ commit }, chat_id) {
    commit('RESET_UNREAD_COUNT', chat_id)
  },
  increaseUnreadCount({ commit }, chat_id) {
    commit('INCREASE_UNREAD_COUNT', chat_id)
  },
}
