import {monthToYears} from '@/utils/index'
export const mutations = {
  SET_ITEM: (state, item) => {
    var newData = {
      id: null,
      seeker_profile_id: item.id,
      company_id: null,
      vacancy_id: null,
      vacancy_step_id: null,
      created_by: null,
      tags: [],
      created_at: null,
      updated_at: null,
      status: null,
      source: null,
      applicant_confirmed: null,
      resume_id: null,
      vacancyCompatiblePercent: 0,
      vacancyCompatible: [],
    };
    newData['type'] = 'resume';
    newData['profile_sourse'] = 'resume';
    newData['experience_period'] = item.experience_month? monthToYears(item.experience_month):null;
    newData['experience_month'] = item.experience_month;
    newData['user_id'] = item.user_id;
    newData['last_name'] = item.last_name;
    newData['first_name'] = item.first_name;
    newData['patronymic'] = item.patronymic;
    newData['birth_date'] = item.birth_date;
    newData['gender'] = item.gender;
    newData['avatar'] = item.avatar;
    newData['position_id'] = item.position_id;
    newData['district_id'] = item.district_id;
    newData['region_id'] = item.region_id;
    newData['wanted_work'] = item.wanted_work;   
    newData['profile_created'] = item.created_at;   
    newData['profile_updated'] = item.updated_at;   
    newData['phone_number'] = item.phone_number;   
    newData['email'] = item.email;   
    newData['skills'] = item.skills;
    newData['languages'] = item.languages;
    newData['wanted_salary'] = item.wanted_salary;
    newData['category_id'] = item.category_id;
    newData['without_experience'] = item.without_experience;
    newData['driver_rights'] = item.driver_rights;
    newData['has_car'] = item.has_car;
    newData['salary_currency'] = item.salary_currency;
    newData['employment_type_id'] = item.employment_type_id;
    newData['about'] = item.about;
    newData['computer_level_id'] = item.computer_level_id;
    newData['position_name'] = item.position_name;
    newData['registration_step'] = item.registration_step;
    newData['uuid'] = item.uuid;
    newData['is_verified'] = item.is_verified;
    newData['registration_lang'] = item.registration_lang;
    newData['age'] = item.age;
    newData['category'] = item.category;
    newData['position'] = item.position;
    newData['is_reserved'] = item.is_reserved;
    newData['added_telegram'] = item.added_telegram;
    newData['is_showed'] = item.is_showed;
    newData['profile_tags'] = item.profile_tags;
    newData['showed_count'] = item.showed_count;
    newData['experiences'] = item.experiences;
    newData['educations'] = item.educations;
    newData['seeker_courses'] = item.seeker_courses;
    newData['region'] = item.region;
    newData['city'] = item.city;
    newData['ball'] = item.ball;
    newData['vacancy_response'] = item.vacancy_response;
    newData['last_online_at'] = item.user?item.user.last_online_at:null;
    newData['starting_work'] = item.starting_work;
    state.item = newData
  },
  SET_CV: (state, item) => {
    var newData = {
      id: null,
      seeker_profile_id: null,
      company_id: null,
      vacancy_id: null,
      vacancy_step_id: null,
      created_by: null,
      tags: [],
      created_at: null,
      updated_at: null,
      status: null,
      source: null,
      applicant_confirmed: null,
      resume_id: item.id,
      vacancyCompatiblePercent: 0,
      vacancyCompatible: [],
    };
    newData['type'] = 'resume';
    newData['profile_sourse'] = 'cv';
    newData['experience_period'] = item.experience_month?monthToYears(item.experience_month):null;
    newData['experience_month'] = item.experience_month;
    newData['user_id'] = null;
    newData['last_name'] = item.last_name;
    newData['first_name'] = item.first_name;
    newData['patronymic'] = item.middle_name;
    newData['birth_date'] = item.birth_date;
    newData['gender'] = item.gender;
    newData['avatar'] = item.avatar;
    newData['position_id'] = null;
    newData['district_id'] = null;
    newData['region_id'] = null;
    newData['wanted_work'] = null;   
    newData['profile_created'] = item.created_at;   
    newData['profile_updated'] = item.updated_at;   
    newData['phone_number'] = item.phone_number;   
    newData['email'] = item.email;   
    newData['skills'] = item.additional_info?item.additional_info['skills']:null;
    newData['languages'] = item.additional_info?item.additional_info['languages']:null;
    newData['languages_string'] = item.languages;
    newData['wanted_salary'] = item.wanted_salary;
    newData['category_id'] = null;
    newData['without_experience'] = null;
    newData['driver_rights'] = item.additional_info?item.additional_info['driver_rights']:null;
    newData['has_car'] = item.additional_info?item.additional_info['has_car']:null;
    newData['salary_currency'] = item.additional_info?item.additional_info['wanted_salary_currency']:null;
    newData['employment_type_id'] = null;
    newData['employment_type_name'] = item.additional_info?item.additional_info['employment_type']:null;
    newData['about'] = item.about;
    newData['computer_level_id'] = item.additional_info?item.additional_info['computer_level_id']:null;
    newData['position_name'] = item.position_name;
    newData['registration_step'] = 20;
    newData['uuid'] = item.uuid;
    newData['is_verified'] = null;
    newData['registration_lang'] = null;
    newData['age'] = item.age?item.age:_calculateAge(item.birth_date);
    newData['category'] = null;
    newData['position'] = null;
    newData['is_reserved'] = item.is_reserved;
    newData['added_telegram'] = null;
    newData['is_showed'] = item.is_showed;
    newData['profile_tags'] = [];
    newData['showed_count'] = item.showed_count;
    newData['experiences'] = item.additional_info?item.additional_info['experiences']:null;
    newData['experiences_string'] = item.experiences;
    newData['educations'] = item.additional_info?item.additional_info['educations']:null;
    newData['educations_string'] = item.educations;
    newData['seeker_courses'] = item.additional_info?item.additional_info['courses']:null;
    newData['region'] = item.region;
    newData['city'] = item.additional_info?item.additional_info['city']:null;
    newData['vacancy_response'] = item.vacancy_response;
    newData['resume_source'] = item.source;
    newData['from_portals'] = item.source == 'olx.uz' || item.source == 'hh.uz';
    newData['resume_file_path'] = item.file_path;
    state.itemCV = newData
  },
  SET_LIST: (state, items) => {
    state.list = items.data.map(item => {
      item['experience_period'] = monthToYears(item.experience_month)
      return item;
    });
    state.pagination = {
      total: items.total,
      page: items.current_page,
      limit: items.per_page,
      last_page: items.last_page,
      show: items.total > items.per_page,
      startIndex: ((items['current_page']-1)*items['per_page']),
    }
  },
  SET_IS_SHOWED: (state, model) => {
    state.list = state.list.map(item => {
      if(item.id == model.id) item['is_showed'] = true;
      return item;
    })
  }
}

function _calculateAge(birthday) { // birthday is a date
  if(birthday){
      var ageDifMs = Date.now() - (new Date(birthday)).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  return 0;
}