import request from '@/utils/request';

export function index(params) {
  return request({
    url: '/candidate',
    method: 'get',
    params
  });
}

export function store(data) {
  return request({
    url: '/candidate',
    method: 'post',
    data
  });
}

export function updateFormData(id, data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/candidate/' + id,
    method: 'post',
    data: formData
  });
}

export function update(id, data) {
  return request({
    url: '/candidate/' + id,
    method: 'put',
    data
  });
}

export function show(id) {
  return request({
    url: '/candidate/' + id,
    method: 'get'
  });
}
export function autoselection(vacancyId, data) {
  return request({
    url: '/autoselection/resumes/' + vacancyId,
    method: 'get',
    params: data
  });
}

export function comments(candidateId) {
  return request({
    url: '/candidate/comments/' + candidateId,
    method: 'get',
  });
}

export function commentCreate(candidateId, data) {
  return request({
    url: '/candidate/comments/' + candidateId,
    method: 'post',
    data
  });
}

export function shortListByStepId(stepId) {
  return request({
    url: '/candidate/list-by-step/' + stepId,
    method: 'get'
  });
}

export function addCandidate(data) {
  return request({
    url: '/candidate/from-source',
    method: 'post',
    data
  });
}

export function shortList(params) {
  return request({
    url: '/candidate/short/list',
    method: 'get',
    params
  });
}

export function vacancyCompatible(vacancyId, profileId) {
  return request({
    url: `/autoselection/vacancy-compatible-rate/${vacancyId}?profile_id=${profileId}`,
    method: 'get'
  });
}

export function actionHistories(params) {
  return request({
    url: '/candidate-action-histories/' + params['id'],
    method: 'get',
    params
  });
}

export function reserved(params) {
  return request({
    url: '/candidate/reserved',
    method: 'get',
    params
  });
}

export function blocked(params) {
  return request({
    url: '/candidate/blocked',
    method: 'get',
    params
  });
}

export function vacancyResponses(params) {
  return request({
    url: '/vacancy-response/short-list/'+params['vacancy_id'],
    method: 'get',
    params:params['data']
  });
}

export function rejectResponses(data) {
  return request({
    url: '/vacancy-response/reject/'+data['id'],
    method: 'post',
    data
  });
}

export function allTags() {
  return request({
    url: '/candidate/list-tags',
    method: 'get'
  });
}

export function parseCv(file) {
  const formData = new FormData();
  formData.append('cvfile', file);
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/utils/parsing-cv',
    method: 'post',
    data: formData
  });
}

export function autoselectionTest(id) {
  return request({
    url: '/autoselection/resumes-test/'+id,
    method: 'get'
  });
}

export function applicantConfirm(data) {
  return request({
    url: '/candidate/applicant-confirm/'+data['id'],
    method: 'post',
    data
  });
}

export function sendSms(data) {
  return request({
    url: '/candidate/send-sms/'+data['id'],
    method: 'post',
    data
  });
}

export function rejectNotification(data) {
  return request({
    url: '/candidate/reject-notification/'+data['candidate_id'],
    method: 'post',
    data
  });
}