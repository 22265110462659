export default {
    "hello": "Привет",
    "about": {
        "example": "Tohle je stránka O nás."
    },
    "day": "за {param} день",
    "days": "за {param} дней",
    "to_salary": "до {param}",
    "UZS": "сум",
    "USD": "USD",
    "RUB": "Рубль",
    "EUR": "Евро",
    "CNY": "Юань",
    "Ответ кандидата": "Ответ кандидата",
    "profile_fill": {
        "about": "О себе",
        "computer_level_id": "Знание компьютера",
        "driver_rights": "Категория прав",
        "email": "E-mail",
        "languages": "Знание языков",
        "phone_number": "Телефон",
        "seeker_courses": "Сертификаты",
        "seeker_educations": "Уровень образования",
        "seeker_experiences": "Опыт работы",
        "skills": "Навыки",
        "wanted_salary": "Желаемый уровень заработной платы"
    },
    "Все": "Все",
    "По вашему запросу ничего не найдено": "По вашему запросу ничего не найдено",
    "Очистить фильтр": "Очистить фильтр",
    "Вакансия": "Вакансия",
    "Локация": "Локация",
    "Отдел": "Отдел",
    "Крайний срок": "Крайний срок",
    "Ответственный": "Ответственный",
    "Приоритет": "Приоритет",
    "У вас еще нет созданных вакансий": "У вас еще нет созданных вакансий",
    "У вас еще нет кандидаты": "У вас еще нет кандидатов",
    "Заказчики": "Заказчики",
    "Рекрутеры": "Рекрутеры",
    "У вас еще нет заказчики": "У вас еще нет заказчики",
    "У вас еще нет рекрутеры": "У вас еще нет рекрутеры",

    "Комментировал": "Комментировал",
    "Создан на этап ": "Создан на этап {param}",
    "Переведен на этап ": "Переведен на этап {param}",
    "Отправлен в черный список": "Отправлен в черный список",
    "Активен": "Активен",
    "Отправлен в кадровый резерв": "Отправлен в кадровый резерв",
    "lorem_send_archieve": "Кадровый резерв - это база кандидатов, которые обладают необходимыми компетенциями для закрытия открытой вакансии. В случае, если у вас появятся открытие вакансии, вы сможете подобрать уже просмотревших кандидатов из кадрового резерва.",
    "lorem_candidate_block": "Черный список - это база специалистов, которых вы в перспективе не собирайтесь рассматривать и нанимать. Специалисты, добавленные в данный  список не будут появляться у вас в рекомендованных кандидатах, а также отправлять сообщения в чат.",

    "Заказчик": "Заказчик",
    "Рекрутер": "Рекрутер",
    "Выберите роль пользователя": "Выберите роль пользователя",
    "Выберите заказчика": "Выберите заказчика",
    "Крайний срок вакансии": "Крайний срок вакансии",
    "Ответственный рекрутер": "Ответственный рекрутер",
    "Выберите ответственного рекрутера": "Выберите ответственного рекрутера",
    "Низкий": "Низкий",
    "Средний": "Средний",
    "Высокий": "Высокий",
    "Есть опыт работы?": "Есть опыт работы?",
    "Дополнительное образование": "Сертификаты",
    "На какую вакансию добавить?": "На какую вакансию добавить?",
    "Введите название вакансии": "Введите название вакансии",
    "Добавить ещё опыт работы": "Добавить ещё опыт работы",
    "Организация": "Организация",
    "Есть": "Есть",
    "Добавить ещё курс": "Добавить ещё сертификат",
    "Добавить сертификат": "Добавить сертификат",
    "Дополнительная информация": "Дополнительная информация",
    "Выберите этап": "Выберите этап",
    "Сменить этап": "Сменить этап",
    "Добавление кандидата": "Добавление кандидата",
    "Добавить новую": "Добавить новую",
    "Введите фамилию или имя кандидата": "Введите фамилию или имя кандидата",
    "На другую вакансию": "На другую вакансию",
    "Отказать": "Отказать",
    "Профиль ISH.UZ": "Профиль ISH.UZ",
    "История действий": "История действий",
    "Совместимость": "Совместимость",
    "Метки": "Метки",
    "Добавить в базу кандидатов": "Добавить в базу кандидатов",
    "Добавить комментарий": "Добавить комментарий",
    "Написать кандидату": "Написать кандидату",
    "Переместить в кадровый резерв": "Переместить в кадровый резерв",
    "Отправить в черный список": "Отправить в черный список",
    "Комментарии": "Комментарии",
    "Источник": "Источник",
    "На какую вакансию отправить?": "На какую вакансию отправить?",
    "Написать коммениарий": "Написать коммениарий",
    "Всем": "Всем",
    "Только я": "Только я",
    "На какой этап?": "На какой этап?",
    "Кандидат уже находится в кадровом резерве": "Кандидат уже находится в кадровом резерве",

    "Еще подходящих вакансий": "Еще подходящих вакансий",
    "вакансий": "вакансий",
    "Ташкент": "Ташкент",
    "Вакансии": "Вакансии",
    "Компании": "Компании",
    "Профессии": "Профессии",
    "Работадателю": "Работадателю",
    "Создать резюме": "Создать резюме",
    "Войти": "Войти",
    "Поиск работы в Узбекистане": "Поиск работы в Узбекистане",
    "Найти работу": "Найти работу",
    "Работодателям": "Работодателям",
    "Категории": "Категории",
    "Поиск": "Поиск",
    "Весь Узбекистан": "Весь Узбекистан",
    "Найти вакансии": "Найти вакансии",
    "Создать профиль": "Создать профиль",
    "Это займет 3 минуты, и система сама для вас найдет наиболее подходящих кандидатов.": "Это займет 3 минуты, и система сама для вас найдет наиболее подходящих кандидатов.",
    "Это займет 3 минуты, и система сама найдет подходящие для вас вакансии": "Это займет 3 минуты, и система сама найдет подходящие для вас вакансии",
    "Постоянная работа": "Постоянная работа",
    "Работа по проекту": "Работа по проекту",
    "Удаленная работа": "Удаленная работа",
    "Стажировка": "Стажировка",
    "Работа для экспатов": "Работа для экспатов",
    "Всего объявлений": "Всего объявлений",
    "Работа по категориям": "Работа по категориям",
    "Работа в компаниях": "Работа в компаниях",
    "Поиск работы в компании": "Поиск работы в компании",
    "Поиск вакансий по городам": "Поиск вакансий по городам",
    "Поиск вакансий по регионам": "Поиск вакансий по регионам",
    "Ish.uz использует файлы «cookie», с целью персонализации сервисов и повышения удобства пользования веб-сайтом.": "Ish.uz использует файлы «cookie», с целью персонализации сервисов и повышения удобства пользования веб-сайтом.",
    "«Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта.": "«Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта.",
    "Если вы не хотите использовать файлы «cookie», измените настройки браузера.": "Если вы не хотите использовать файлы «cookie», измените настройки браузера.",
    "Вход": "Вход",
    "Соискатель": "Соискатель",
    "Работодатель": "Работодатель",
    "Введите номер мобильного телефона": "Введите номер мобильного телефона",
    "Далее": "Далее",
    "Войти с паролем": "Войти с паролем",
    "или": "или",
    "Продолжить через Google": "Продолжить через Google",
    "Продолжить через Facebook": "Продолжить через Facebook",
    "Введите адрес электронной почты": "Введите адрес электронной почты",
    "Поле не может быть пустым": "Поле не может быть пустым",
    "Пароль": "Пароль",
    "Забыли пароль?": "Забыли пароль?",
    "Нет аккаунта?": "Нет аккаунта?",
    "Зарегистрироваться": "Зарегистрироваться",
    "Зарегистрируйтесь и находите сотрудников": "Зарегистрируйтесь и находите сотрудников",
    "Название компании": "Название компании",
    "Контактное лицо": "Контактное лицо",
    "Введите Контактный номер": "Введите Контактный номер",
    "Повторите пароль": "Повторите пароль",
    "Пожалуйста, введите пароль еще раз": "Пожалуйста, введите пароль еще раз",
    "Нажимая кнопку “Зарегистрироваться”, вы принимаете": "Нажимая кнопку “Зарегистрироваться”, вы принимаете",
    "условия использования сайта": "условия использования сайта",
    "политику конфиденциальности": "политику конфиденциальности",
    "Есть аккаунт?": "Есть аккаунт?",
    "Изменить фото": "Изменить фото",
    "Профессия": "Профессия",
    "Город": "Город",
    "Дата рождения": "Дата рождения",
    "Пол": "Пол",
    "Телефон": "Телефон",
    "Ищу работу": "Ищу работу",
    "Не ищу работу": "Не ищу работу",
    "Готов выйти сразу": "Готов выйти сразу",
    "Нужно две недели": "Нужно две недели",
    "Сохранить": "Сохранить",
    "Отменить": "Отменить",
    "Категория": "Категория",
    "Позиция": "Позиция",
    "Мой профиль": "Мой профиль",
    "Подходящие вакансии": "Подходящие вакансии",
    "Приглашения от работодателей": "Приглашения от работодателей",
    "Отклики": "Отклики",
    "Желаемый уровень заработной платы": "Желаемый уровень заработной платы",
    "В этом блоке вы можете добавить свой уровень зарплаты.": "В этом блоке вы можете добавить свой уровень зарплаты.",
    "Укажите уровень образования": "Укажите уровень образования",
    "В этом блоке вы можете добавить образование, которое вы получили.": "В этом блоке вы можете добавить образование, которое вы получили.",
    "Опыт работы": "Опыт работы",
    "Заполняя информацию об опыте работы, раскройте для работодателя ваши положительные стороны, которые соответствуют должности, на которой вы хотите работать. Опишите ваши обязанности, достижения, знания, умения и положительный опыт применения их в работе.": "Заполняя информацию об опыте работы, раскройте для работодателя ваши положительные стороны, которые соответствуют должности, на которой вы хотите работать. Опишите ваши обязанности, достижения, знания, умения и положительный опыт применения их в работе.",
    "Нет опыта работы": "Нет опыта работы",
    "Укажите навыки": "Укажите навыки",
    "Когда вы описываете свои навыки, включите те, которые будут полезны вам в новой работе. Это покажет работодателю ваши конкретные преимущества перед другими кандидатами.": "Когда вы описываете свои навыки, включите те, которые будут полезны вам в новой работе. Это покажет работодателю ваши конкретные преимущества перед другими кандидатами.",
    "Знание языков": "Знание языков",
    "Укажите в этом блоке, какие языки и на каком уровне вы знаете. Чтобы определить уровень, воспользуйтесь нашими рекомендациями.": "Укажите в этом блоке, какие языки и на каком уровне вы знаете. Чтобы определить уровень, воспользуйтесь нашими рекомендациями.",
    "Категория прав": "Категория прав",
    "Наличие автомобиля": "Наличие автомобиля",
    "Есть личный автомобиль": "Есть личный автомобиль",
    "Добавить": "Добавить",
    "Отклик без резюме": "Отклик без резюме",
    "Показывать только непрочитанные": "Показывать только непрочитанные",
    "Выход": "Выход",
    "Код": "Код",
    "Хотите изменить E-mail?": "Хотите изменить E-mail?",
    "Изменить E-mail": "Изменить E-mail",
    "Настройки компании": "Настройки компании",
    "Поиск по кандидатам": "Поиск по кандидатам",
    "Баланс": "Баланс",
    "пополнить": "пополнить",
    "Редактирование информации о компании": "Редактирование информации о компании",
    "Название тип компании": "Название и тип компании",
    "Название": "Название",
    "Тип": "Тип",
    "Главная": "Главная",
    "Редактировать": "Редактировать",
    "Информация о компании": "Информация о компании",
    "Отрасль": "Отрасль",
    "Количество сотрудников в компании": "Количество сотрудников в компании",
    "Телефоно": "Телефоно",
    "Описание компании": "Описание компании",
    "Ищете сотрудников?": "Ищете сотрудников?",
    "Регионы": "Регионы",
    "Зарплата": "Зарплата",
    "Вид занятости": "Вид занятости",
    "Образование": "Образование",
    "Сбросить все": "Сбросить все",
    "Предыдущая": "Предыдущая",
    "Следующая": "Следующая",
    "мужской": "мужской",
    "женский": "женский",
    "Написать": "Написать",
    "Полная занятость": "Полная занятость",
    "Частичная занятость": "Частичная занятость",
    "Попроектная работа или разовое задание": "Попроектная работа или разовое задание",
    "Волонтерство": "Волонтерство",
    "не имеет значения": "не имеет значения",
    "Высшее бакалавр": "Высшее бакалавр",
    "Высшее магистр": "Высшее магистр",
    "Профиль заполнен на": "Профиль заполнен на {param} %",
    "Чем выше ваш процент, тем больше работодателей видят ваш профиль.": "Чем выше ваш процент, тем больше работодателей видят ваш профиль.",
    "Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений.": "Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений.",
    "Еще вы можете указать": "Еще вы можете указать",
    "Учебное заведение": "Учебное заведение",
    "Специализация (если есть)": "Специализация (если есть)",
    "Направление": "Направление",
    "Год окончания": "Год окончания",
    "Если вы сейчас учитесь — укажите ожидаемый год выпуска": "Если вы сейчас учитесь — укажите ожидаемый год выпуска",
    "Вакансии Узбекистана": "Вакансии Узбекистана",
    "Подходящие резюме": "Подходящие кандидаты",
    "Истекает": "Истекает",
    "Получить больше откликов": "Получить больше откликов",
    "Действия": "Действия",
    "Активные": "Активные",
    "Неопубликованные": "Неопубликованные",
    "Архивные": "Архивные",
    "Мои вакансии": "Мои вакансии",
    "Сохраненные резюме": "Сохраненные резюме",
    "Чат с кандидатами": "Чат с кандидатами",
    "Создать вакансию": "Создать вакансию",
    "Название должности": "Название должности",
    "Категория размещения вакансии": "Категория размещения вакансии",
    "Например, менеджер по продажам": "Например, менеджер по продажам",
    "Условия работы": "Условия работы",
    "Регион работы": "Регион работы",
    "Город работы": "Город работы",
    "Адрес работы": "Адрес работы",
    "Улица и дом": "Улица и дом",
    "Вакансии с указанной зарплатой получают в два раза больше откликов!": "Вакансии с указанной зарплатой получают в два раза больше откликов!",
    "Комментарий к зарплате": "Комментарий к зарплате",
    "Диапазон": "Диапазон",
    "Одно значение": "Одно значение",
    "Не указыать": "Не указыать",
    "Требования к соисталелю": "Требования к соисталелю",
    "Готовы взять без опыта": "Готовы взять без опыта",
    "от 1 года": "от 1 года",
    "от 2 лет": "от 2 лет",
    "от 5 лет": "от 5 лет",
    "Вакансия подходит для": "Вакансия подходит для",
    "Студента": "Студента",
    "Студент": "Студент",
    "Человека с инвалидностью": "Человека с инвалидностью",
    "Пенсионера": "Пенсионера",
    "Экспата иностранного специалиста": "Экспата иностранного специалиста",
    "Описание вакансии": "Описание вакансии",
    "Требования": "Требования",
    "Обязанности": "Обязанности",
    "Ключевые навыки": "Ключевые навыки",
    "Контактная информация": "Контактная информация",
    "Пользователь": "Пользователь",
    "Показывать на странице этой вакансии имя и телефон": "Показывать на странице этой вакансии имя и телефон",
    "Указать другой телефон": "Указать другой телефон",
    "Как можно откликнуться": "Как можно откликнуться",
    "Резюме обязательно": "Резюме обязательно",
    "Можно и без резюме (только имя, фамилия и контакты)": "Можно и без резюме (только имя, фамилия и контакты)",
    "Сколько человек вы хотите нанять по данной вакансии?": "Сколько человек вы хотите нанять по данной вакансии?",
    "Продолжить": "Продолжить",
    "На сайте Ish.uz размещены вакансии в Ташкенте и регионах Узбекистана. Информация на сайте позволяет осуществлять быстрый поиск и подбор персонала в любой сфере.": "На сайте Ish.uz размещены вакансии в Ташкенте и регионах Узбекистана. Информация на сайте позволяет осуществлять быстрый поиск и подбор персонала в любой сфере.",
    "Сохранено": "Сохранено",
    "Чтобы сохранить кандидата, нужно войти или зарегистрироваться.": "Чтобы сохранить кандидата, нужно войти или зарегистрироваться.",
    "по дате": "по дате",
    "по убыванию зарплат": "по убыванию зарплат",
    "по возрастанию зарплаты": "по возрастанию зарплаты",
    "Опыт работы:": "Опыт работы:",
    "Место работы": "Место работы",
    "по настоящее время": "по настоящее время",
    "Основной язык": "Основной язык",
    "Найти кандидатов": "Найти кандидатов",
    "Чтобы сохранить вакансию, нужно войти или зарегистрироваться.": "Чтобы сохранить вакансию, нужно войти или зарегистрироваться.",
    "Ищете работу?": "Ищете работу?",
    "Разместите резюме": "Разместите резюме",
    "чтобы работодатели сами могли предложить вам работу.": "чтобы работодатели сами могли предложить вам работу.",
    "Вы отправили отклик на эту вакансию": "Вы отправили отклик на эту вакансию",
    "Откликнуться": "Откликнуться",
    "Вакансия от": "Вакансия от",
    "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться": "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться в качестве соискателя",
    "Фильтры": "Фильтры",
    "Разместите вакансию": "Разместите вакансию",
    "чтобы кандидаты сами могли найим вас": "чтобы кандидаты сами могли найим вас",
    "Применить": "Применить",
    "подходящих вакансий": "подходящих вакансий",
    "Сортировка": "Сортировка",
    "C полследнего размещения": "C полследнего размещения",
    "откликов": "откликов",
    "просмотров вакансии": "просмотров вакансии",
    "показов вакансии": "показов вакансии",
    "Вам подойдут эти вакансии": "Вам подойдут эти вакансии",
    "до": "до",
    "от": "от",
    "До": "До",
    "От": "От",
    "Подробнее": "Подробнее",
    "Нет компании": "Нет компании",
    "сотрудников": "сотрудников",
    "О компании": "О компании",
    "Восстановить": "Восстановить",
    "Опубликовать": "Опубликовать",
    "Опубликовано": "Опубликовано",
    "Хорошо": "Хорошо",
    "Новые": "Новые",
    "Просмотренные": "Просмотренные",
    "Aктивные": "Aктивные",
    "Пожалуйста, введите новый пароль для учетной записи": "Пожалуйста, введите новый пароль для учетной записи",
    "Адрес": "Адрес",
    "Требуемый опыт работы": "Требуемый опыт работы",
    "Работа в Узбекистане. Открытые вакансии по категориям.": "Работа в Узбекистане. Открытые вакансии по категориям.",
    "Например, организация конференцей": "Например, организация конференцей",
    "Готов выйти через 2 недели": "Готов выйти через 2 недели",
    "Обновлено": "Обновлено",
    "Поиск по компаниям": "Поиск по компаниям",
    "Все компании": "Все компании",
    "По отраслям": "По отраслям",
    "Поиск компании": "Поиск компании",
    "Найти": "Найти",
    "Просмотры": "Просмотры",
    "Поднять вакансию": "Поднять вакансию",
    "Закрыть вакансию": "Закрыть вакансию",
    "Добавить вакансию": "Добавить вакансию",
    "Кандидаты по вакансии": "Кандидаты по вакансии",
    "Указать ещё один язык": "Указать ещё один язык",
    "Указать язык": "Указать язык",
    "Указать другое контактное лицо": "Указать другое контактное лицо",
    "Можно и без резюме (только имя,  фамилия и контакты)": "Можно и без резюме (только имя,  фамилия и контакты)",
    "Посмотреть все категории": "Посмотреть все категории",
    "Требования к  соисталелю": "Требования к  соисталелю",
    "Выберите": "Выберите",
    "сум": "сум",
    "Сумма": "Сумма",
    "suitable_vacancies": "suitable_vacancies",
    "Формат файла должно быть один из следующих (JPG, JPEG, PNG).": "Формат файла должно быть один из следующих (JPG, JPEG, PNG).",
    "Размер файла не должно превышать 2мб.": "Размер файла не должно превышать 2мб.",
    "Поиск сотрудников в Узбекистане": "Поиск сотрудников в Узбекистане",
    "Соискателю": "Соискателю",
    "Кандидаты по категориям": "Кандидаты по категориям",
    "Поиск кандидатов по регионам": "Поиск кандидатов по регионам",
    "Найти сотрудников в Узбекистане": "Найти сотрудников в Узбекистане",
    "На данной странице вы найдете информацию о предложениях компаний. Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана": "На данной странице вы найдете информацию о предложениях компаний. Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана",
    "Вакансии в Ташкенте": "Вакансии в Ташкенте",
    "Вакансии компаний": "Вакансии компаний",
    "Удобный поиск, актуальная информация - описание, фото, адреса и телефоны": "Удобный поиск, актуальная информация - описание, фото, адреса и телефоны",
    "найдено вакансий": "найдено вакансий",
    "Введите свой номер телефона": "Введите свой номер телефона",
    "и": "и",
    "Регион": "Регион",
    "Похожие вакансии": "Похожие вакансии",
    "Все вакансии": "Все вакансии",
    "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться.": "Чтобы откликнуться на вакансию, нужно войти или зарегистрироваться.",
    "Вакансия успешно опубликовано!": "Вакансия успешно опубликовано!",
    "Успешно": "Успешно",
    "Это займет 3 минуты, и система сама найдет подходящие для вас  вакансии": "Это займет 3 минуты, и система сама найдет подходящие для вас  вакансии",
    "На данной странице вы найдете информацию о предложениях компаний . Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана": "На данной странице вы найдете информацию о предложениях компаний . Предложения содержат цены, подробное описание и контактную информацию работодателей, работающих на рынке Узбекистана",
    "Работа в Ташкенте и городах Узбекистана. Подбор персонала и вакансии. Поиск работы в Узбекистане": "Работа в Ташкенте и городах Узбекистана. Подбор персонала и вакансии. Поиск работы в Узбекистане",
    "Работа в Ташкенте и городах Узбекистана": "Работа в Ташкенте и городах Узбекистана",
    "Нет подходящих вакансий": "Нет подходящих вакансий",
    "Здесь будут находиться ваши чаты с работодателями": "Здесь будут находиться ваши чаты с работодателями",
    "Сообщение": "Сообщение",
    "Отправить": "Отправить",
    "Сайт": "Сайт",
    "Не указана": "Не указана",
    "Посмотреть все отрасли": "Посмотреть все отрасли",
    "Логотип": "Логотип",
    "Чем занимается компания, ее миссия, успехи, перспективы, профессионального и карьерного роста, мотивационные программы, премиущества работы в компании": "Чем занимается компания, ее миссия, успехи, перспективы, профессионального и карьерного роста, мотивационные программы, премиущества работы в компании",
    "Прямой работодатель": "Прямой работодатель",
    "Кадровое агентство": "Кадровое агентство",
    "Ваша эл. почта": "Ваша эл. почта",
    "Перейти на эл. почту": "Перейти на эл. почту",
    "Новый пароль еще раз": "Новый пароль еще раз",
    "Войти по коду": "Войти по коду",
    "Подтверждение": "Подтверждение",
    "Повторно отправить код": "Повторно отправить код",
    "Отправить повторно": "Отправить повторно",
    "Письмо отправлено!": "Письмо отправлено!",
    "Чтобы восстановить пароль, откройте письмо, которое мы только что отправили на эл. почту": "Чтобы восстановить пароль, откройте письмо, которое мы только что отправили на эл. почту",
    "и нажмите в нем на кнопку “Восстановить пароль”": "и нажмите в нем на кнопку “Восстановить пароль”",
    "Новый пароль": "Новый пароль",
    "Назад": "Назад",
    "Еще": "Еще",
    "Выбрать несколько": "Выбрать несколько",
    "Выбрать один": "Выбрать один",
    "без указания": "без указания",
    "Мои сохраненные резюме": "Мои сохраненные резюме",
    "Отклик на вакансию": "Отклик на вакансию",
    "Cопроводительный текст (не обязательно)": "Cопроводительный текст (не обязательно)",
    "Для отклик на вакансию": "Для отклик на вакансию",
    "Ваше резюме неполное": "Ваше резюме неполное",
    "Пожалуйста, заполните свое резюме полностью": "Пожалуйста, заполните свое резюме полностью",
    "Заполнить резюме": "Заполнить резюме",
    "Чем выше ваш процент, тем больше работодателей видят ваш профиль. Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений": "Чем выше ваш процент, тем больше работодателей видят ваш профиль. Постарайтесь заполнить профиль свыше 80% чтобы получать максимальное количество предложений",
    "Данные успешно сохранены!": "Данные успешно сохранены!",
    "появлений в результатах поиска в неделю": "появлений в результатах поиска в неделю",
    "Просмотрен": "Просмотрен",
    "Не просмотрен": "Не просмотрен",
    "Доставлен": "Доставлен",
    "Удалить": "Удалить",
    "Нет отклики": "Нет отклики",
    "В этом блоке вы можете добавить информации о вас": "В этом блоке вы можете добавить информации о вас",
    "Укажите дополнительное образование": "Укажите дополнительное образование",
    "Добавить еще": "Добавить еще",
    "В этом блоке вы можете добавить дополнительное образование": "В этом блоке вы можете добавить дополнительное образование",
    "В этом блоке вы можете добавить желаемый вид занятости.": "В этом блоке вы можете добавить желаемый вид занятости.",
    "Вы уверены, что хотите удалить этот запись?": "Вы уверены, что хотите удалить эту запись?",
    "Внимание": "Внимание",
    "Да": "Да",
    "Нет": "Нет",
    "Запись успешно удалена": "Запись успешно удалена",
    "Специализация": "Специализация",
    "Регион поиска работы": "Регион поиска работы",
    "Весь город": "Весь город",
    "Укажите уровень владения компьютером": "Укажите уровень владения компьютером",
    "Нет личный автомобиль": "Нет личный автомобиль",
    "Местоположение учебного заведения": "Местоположение учебного заведения",
    "Должность": "Должность",
    "Местоположение": "Местоположение",
    "Обязанности и достижения": "Обязанности и достижения",
    "Наименование организации": "Наименование организации",
    "Местоположение (страна)": "Местоположение (страна)",
    "Местоположение (регион)": "Местоположение (регион)",
    "Начало работы": "Начало работы",
    "Завершение работы": "Завершение работы",
    "Семейное положение": "Семейное положение",
    "Семейный статус": "Семейный статус",
    "Кол-во детей": "Кол-во детей",
    "В этом блоке вы можете указать семейное положение": "В этом блоке вы можете указать семейное положение",
    "Указать": "Указать",
    "Язык": "Язык",
    "Уровень владения": "Уровень владения",
    "Персональная информация": "Персональная информация",
    "ФИО": "ФИО",
    "Дата рождение": "Дата рождение",
    "Серия и номер паспорта": "Серия и номер паспорта",
    "ПИНФЛ": "ПИНФЛ",
    "Фамилия": "Фамилия",
    "Имя": "Имя",
    "Отчество": "Отчество",
    "Желаемая должность": "Желаемая должность",
    "Мои навыки": "Мои навыки",
    "Укажите свои навыки": "Укажите свои навыки",
    "Кандидаты  по категориям": "Кандидаты  по категориям",
    "Поиск кандидатов по городам": "Поиск кандидатов по городам",
    "response send": "Отклик на вакансию",
    "Здесь хранятся соискатели, которые вас заинтересовали. Чтобы добавить соискателя в избранное посмотрите кандидатов и  нажмите на сердечку": "Здесь хранятся соискатели, которые вас заинтересовали. Чтобы добавить соискателя в избранное посмотрите кандидатов и  нажмите на сердечку",
    "Посмотреть кандидатов": "Посмотреть кандидатов",
    "Работа в Ташкенте": "Работа в Ташкенте",
    "Адрес поиска работы": "Адрес поиска работы",
    "Код отправлен на ваш номер в смс": "Код отправлен на ваш номер в смс",
    "Введите код": "Введите код",
    "Количество символов должно быть максимум": "Количество символов должно быть максимум {param}",
    "Количество символов должно быть": "Количество символов должно быть {param}",
    "Количество символов должно быть минимум": "Количество символов должно быть минимум {param}",
    "Зарегистрируйтесь и найдите работу": "Зарегистрируйтесь и найдите работу",
    "Напишите фамилия": "Напишите фамилия",
    "Напишите имя": "Напишите имя",
    "Какую вы ищете работу": "Какую вы ищете работу",
    "Например, учитель": "Например, учитель",
    "Где вы ищете работу": "Где вы ищете работу",
    "Мужчина": "Мужчина",
    "Женщина": "Женщина",
    "Город(район)": "Город(район)",
    "Чтобы сохранить вакансию, нужно войти в систему как соискатель": "Чтобы сохранить вакансию, нужно войти в систему как соискатель",
    "Для отклика на данную вакансию ваш профиль должен быть заполнен информацией": "Для отклика на данную вакансию ваш профиль должен быть заполнен информацией",
    "Чтобы откликнуться на эту вакансию, ваше резюме должно быть выше 70 процентов.  Заполните оставшиеся данные: ": "Чтобы откликнуться на эту вакансию, ваше резюме должно быть выше 70 процентов.  Заполните оставшиеся данные: ",
    "Заполнить профиль": "Заполнить профиль",
    "Весь регион": "Весь регион",
    "Требования:": "Требования:",
    "Условия работы:": "Условия работы:",
    "Обязанности:": "Обязанности:",
    "Не указывать контактное лицо (обращение только через отклик)": "Не указывать контактное лицо (обращение только через отклик)",
    "Не указывать": "Не указывать",
    "Редактировать вакансию": "Редактировать вакансию",
    "год": "год",
    "года": "года",
    "лет": "лет",
    "месяц": "месяц",
    "месяца": "месяца",
    "месяцев": "месяцев",
    "age год": "год",
    "age года": "года",
    "age лет": "лет",
    "Контактный номер": "Контактный номер",
    "Введите E-mail": "Введите E-mail",
    "Введите контактный номер": "Введите контактный номер",
    "База кандидатов доступна только зарегистрированным работодателям.": "База кандидатов доступна только зарегистрированным работодателям.",
    "О себе": "О себе",
    "Найти сотрудника": "Найти сотрудника",
    "Добавить фото": "Добавить фото",
    "Работаю тут по настоящее время": "Работаю тут по настоящее время",
    "Опишите, что вы делали на работе": "Опишите, что вы делали на работе",
    "Отмечайте понравившиеся вам вакансии и смотрите их здесь.": "Отмечайте понравившиеся вам вакансии и смотрите их здесь.",
    "Нет избранных": "Нет избранных",
    "Мы отправили код подтверждение на ваш E-mail": "Мы отправили код подтверждение на ваш E-mail",
    "Пожалуйста, проверьте свой E-mail": "Пожалуйста, проверьте свой E-mail",
    "У вас пока нет опубликованных вакансий. Опубликуйте вакансию и находите лучших кандидатов!": "У вас пока нет опубликованных вакансий. Опубликуйте вакансию и находите лучших кандидатов!",
    "Сообщений пока нет": "Сообщений пока нет",
    "Здесь будут находиться ваши чаты с Соискателями": "Здесь будут находиться ваши чаты с Соискателями",
    "Удалить фото": "Удалить фото",
    "Добавлено в сохраненное": "Добавлено в сохраненное",
    "Удалено из сохраненных": "Удалено из сохраненных",
    "Выбрать": "Выбрать",
    "Закрыть": "Закрыть",
    "Задать вопрос": "Задать вопрос",
    "Нет соединения с сервером, пожалуйста проверьте подключение к интернету": "Нет соединения с сервером, пожалуйста проверьте подключение к интернету",
    "Районы": "Районы",
    "Вакансия будет отправлена в архив, в будущем вы можете восстановить вакансию для повторной публикации.": "Вакансия будет отправлена в архив, в будущем вы можете восстановить вакансию для повторной публикации.",
    "Переместить в архив": "Переместить в архив",
    "Вакансия успешно отправлено в архив!": "Вакансия успешно отправлено в архив!",
    "Пожалуйста, выберите хотя бы одно значение": "Пожалуйста, выберите хотя бы одно значение",
    "Пожалуйста, выберите одно значение": "Пожалуйста, выберите одно значение",
    "Публикуйте вакансии, получайте отклики и общайтесь с кандидатами": "Публикуйте вакансии, получайте отклики и общайтесь с кандидатами",
    "Вы можете указать дополнительную информацию о компании, загрузить лого и добавить описание": "Вы можете указать дополнительную информацию о компании, загрузить лого и добавить описание",
    "Вакансии<br> и отклики": "Вакансии<br> и отклики",
    "Информация<br> о компании": "Информация<br> о компании",
    "Изменить пароль": "Изменить пароль",
    "Скачать резюме": "Скачать резюме",
    "Настройки профиля": "Настройки профиля",
    "Удаление аккаунта": "Удаление аккаунта",
    "Вы уверены, что хотите удалить свой профиль?": "Вы уверены, что хотите удалить свой профиль?",
    "Код подтверждение": "Код подтверждение",
    "Посмотреть вакансии": "Посмотреть вакансии",
    "SMS отправлено на номер": "SMS отправлено на номер {param}",
    "Сохраненные вакансии": "Сохраненные вакансии",
    "Пожалуйста, введите URL": "Пожалуйста, введите URL",
    "У вас нет архивные вакансий": "У вас нет архивные вакансий",
    "У вас нет неопубликованных вакансий": "У вас нет неопубликованных вакансий",
    "Подтвердите ваше согласие": "Подтвердите ваше согласие",
    "Наверх": "Наверх",
    "Вернуться к регистрации": "Вернуться к регистрации",
    "Создано": "Создано",
    "У вас нет заархивированных вакансий": "У вас нет заархивированных вакансий",
    "Вакансия закрыта": "Вакансия закрыта",
    "У вас <b>1</b> непрочитанных сообщений": "У вас <b>{param}</b> непрочитанных сообщений",
    "У вас есть непрочитанное сообщение": "У вас есть непрочитанное сообщение",
    "Ваш статус 'Не ищу работу'": "Ваш статус 'Не ищу работу'",
    "Чтобы откликнуться на вакансию, пожалуйста, поменяйте свой статус на 'Ищу работу'": "Чтобы откликнуться на вакансию, пожалуйста, поменяйте свой статус на 'Ищу работу'",
    "Сумма должно быть больше, чем начальная сумма": "Сумма должно быть больше, чем начальная сумма",
    "Текущий пароль": "Текущий пароль",
    "Не ищет работу": "Не ищет работу",
    "Деактивировать аккаунт": "Деактивировать аккаунт",
    "Вы уверены, что хотите деактивировать свой профиль?": "Вы уверены, что хотите деактивировать свой профиль?",
    "Самый быстрый и простой способ найти работу — создать профиль на нашем сайте!": "Самый быстрый и простой способ найти работу — создать профиль на нашем сайте!",
    "Создайте профиль и система сама найдет вам работу.": "Создайте профиль и система сама найдет вам работу.",
    "Удалённый аккаунт": "Удалённый аккаунт",
    "Вакансия не найдена": "Вакансия не найдена",
    "день": "день",
    "Фрилансер": "Фрилансер",
    "Выберите язык": "Выберите язык",

    "ish.uz - это смарт платформа для быстрого поиска работы": "<b>ish.uz</b> - это смарт платформа<br> для быстрого поиска работы",
    "Cмарт платформа для быстрого поиска работы": "Cмарт платформа для быстрого поиска работы",
    "Как работает ISH.UZ": "Как работает ISH.UZ",
    "Заполните профиль, указав информацию о себе, уровне образования и опыте работы": "<b>Заполните профиль</b>, указав информацию о себе, уровне образования и опыте работы",
    "Система в автоматическом режиме подберет самые подходящие для вас  вакансии": "Система в автоматическом режиме подберет <b>самые подходящие</b> для вас  вакансии",
    "Получайте уведомления о новых вакансиях и предложения от компаний": "Получайте уведомления о новых вакансиях и <b>предложения от компаний</b>",
    "5 минут и вы получите доступ к вакансиям лучших компаний": "<span>5 минут</span> и вы получите доступ к вакансиям лучших компаний",
    "Мы используем технологии использования нейросетей и машинного обучения для того, чтобы вы нашли лучшую работу как можно быстрее.": "Мы используем технологии использования нейросетей и машинного обучения для того, чтобы вы нашли лучшую работу как можно быстрее.",
    "Войти в профиль": "Войти в профиль",
    "Замечательно!": "Замечательно!",
    "Давайте перейдем к новым возможностям!": "Давайте перейдем к новым возможностям!",
    "Молодец!": "Молодец!",
    "Пропустить": "Пропустить",
    "Мы на полпути!": "Мы на полпути!",
    "Добавить язык": "Добавить язык",
    "Поздравляем!": "Поздравляем!",
    "Профиль заполнен!": "Профиль заполнен!",
    "В каком регионе ищете работу?": "В каком регионе ищете работу?",
    "Какую работу ищете?": "Какую работу ищете?",
    "Добавить учебное заведение": "Добавить учебное заведение",
    "Добавить опыт работы": "Добавить опыт работы",
    "Имеется личный автомобиль?": "Имеется личный автомобиль?",
    "Нет избранных вакансий": "Нет избранных вакансий",
    "Вперёд": "Вперёд",
    "Пожалуйста уточните": "Пожалуйста уточните",
    "Возможно вы имели в виду": "Возможно вы имели в виду",
    "У вас есть опыт работы?": "У вас есть опыт работы?",
    "Расположение места работы": "Расположение места работы",
    "Перейти в профиль": "Перейти в профиль",
    "Профиль": "Профиль",
    "Изменить": "Изменить",
    "Добавить желаемый уровень заработной платы": "Добавить желаемый уровень заработной платы",
    "Добавить знание языков": "Добавить знание языков",
    "Добавить ключевые навыки": "Добавить ключевые навыки",
    "Cкопировать": "Cкопировать",
    "Скопирован": "Скопирован",
    "Публичный": "Публичный",
    "Скрытый": "Скрытый",
    "Чат": "Чат",
    "Создайте вакансию в системе": "<b>Создайте вакансию</b> в системе",
    "Система автоматически подберет и оценит подходящих кандидатов на вакансию": "<b>Система автоматически подберет</b> и оценит подходящих кандидатов на вакансию",
    "Проведите все этапы отбора с помощью удобных инструментов": "Проведите все этапы отбора с помощью <b>удобных инструментов</b>",
    "Ссылка": "Ссылка",
    "Настройте конфиденциальность вашего профиля": "Настройте конфиденциальность вашего профиля",
    "Мобильный телефон:": "Мобильный телефон:",
    "Работодатели не могут найти ваше резюме в системе поиска ish.uz.  Это не повлияет на предыдущие заявки и не помешает компаниям, которым вы ответили, связаться с вами.": "Работодатели не могут найти ваше резюме в системе поиска ish.uz.  Это не повлияет на предыдущие заявки и не помешает компаниям, которым вы ответили, связаться с вами.",
    "Подробнее о конфиденциальности вашего профиль": "Подробнее о конфиденциальности вашего профиль",
    "Работодатели, которые ищут кандидатов, могут найти ваше резюме и информацию о профиле на сайте ish.uz в соответствии с нашими условиями": "Работодатели, которые ищут кандидатов, могут найти ваше резюме и информацию о профиле на сайте ish.uz в соответствии с нашими условиями",
    "Профил тўлдириш": "Профил тўлдириш",
    "Сертификаты": "Сертификаты",
    "Наименование": "Наименование",
    "Дата получения": "Дата получения",
    "Без опыта работы": "Без опыта работы",
    "Добавить о себе": "Добавить о себе",

    "Добавить уровень образования": "Добавить уровень образования",
    "Добавить уровень владения компьютером": "Добавить уровень владения компьютером",
    "Добавить Email": "Добавить Email",
    "Подтверждать": "Подтверждать",
    "Отправить код повторно можно через": "Отправить код повторно можно через {param}",
    "Отправить код еще раз": "Отправить код еще раз",
    "Изменить номер": "Изменить номер",
    "Отклик успешно отправлен!": "Отклик успешно отправлен!",
    "Вы пока не оставляли отклика ни к одной вакансии.": "Вы пока не оставляли отклика ни к одной вакансии.",
    "Навык": "Навык",
    "Резюме не найдено": "Резюме не найдено",
    "Не требуется личный автомобиль": "Не требуется личный автомобиль",
    "Опубликовать на {portal}": "Опубликовать на <b>{portal}</b>",
    "Опубликовано на portals": "Опубликовано на ",
    "Кандидаты": "Кандидаты",
    "Отчеты": "Отчеты",
    "Кадровый резерв": "Кадровый резерв",
    "Черный список": "Черный список",
    "Пользователи": "Пользователи",
    "Посмотреть вакансию": "Посмотреть вакансию",
    "Режим доски": "Режим доски",
    "Режим списка": "Режим списка",
    "Этапы": "Этапы",
    "Дополнительные этапы": "Дополнительные этапы",
    "Наши этапы": "Наши этапы",
    "Добавить этап": "Добавить этап",
    "Открыть воронку": "Открыть воронку",
    "Стандартные этапы": "Стандартные этапы",
    "Предложения ISH.UZ": "Предложения ISH.UZ",
    "Загрузить резюме": "Загрузить резюме",
    "Загрузить фото": "Загрузить фото",
    "Желаемый уровень ЗП": "Желаемый уровень ЗП",
    "Добавить кандидата": "Добавить кандидата",
    "Уровень образования": "Уровень образования",
    "Добавить образование": "Добавить образование",
    "Добавить ещё образование": "Добавить ещё образование",
    "Фото": "Фото",
    "Дата добавления": "Дата добавления",
    "Кем добавлен": "Кем добавлен",
    "Скачать в Excel": "Скачать в Excel",
    "Срок закрытия вакансии": "Срок закрытия вакансии",
    "Мы отправили ссылку активации на почту {mail}": "Мы отправили ссылку активации на почту {mail}",
    "Кандидаты не добавлены на этот этап": "Кандидаты не добавлены на этот этап",
    "У вас нет активных вакансий": "У вас нет активных вакансий",
    "Комментарий": "Комментарий",
    "Добавлен вручную": "Добавлен вручную",
    "Введите текст": "Введите текст",
    "Логин": "Логин",
    "Вы уверены, что хотите удалить этот email?": "Вы уверены, что хотите удалить этот email?",
    "Другая почта": "Другая почта",
    "Добавить почту": "Добавить почту",
    "Сервер почты": "Сервер почты",
    "Порт": "Порт",
    "Безопасное соединение": "Безопасное соединение",
    "Введите логин": "Введите логин",
    "Введите email": "Введите email",
    "У вас еще не добавлен email отправителя": "У вас еще не добавлен email отправителя",
    "На почту": "На почту",
    "ISH.UZ чат": "ISH.UZ чат",
    "Телеграм": "Телеграм",
    "Открыть в телеграме": "Открыть в телеграме",
    "Новое сообщение": "Новое сообщение",
    "Тема письма": "Тема письма",
    "Кому": "Кому",
    "От кого": "От кого",
    "sent_time": "Отправлено",
    "Оценка профиля": "Оценка профиля",
    "г.": "г.",
    "Высшее": "Высшее",
    "Пароль от почты": "Пароль от почты",
    "Создан": "Создан",
    "Нет информации": "Нет информации",
    "Почта": "Почта",
    "Кандидат не найден": "Кандидат не найден",
    "Введите должность": "Введите должность",
    "Причина отказа": "Причина отказа",
    "Способ уведомления кандидата": "Способ уведомления кандидата",
    "Редактировать кандидата": "Редактировать кандидата",
    "Нет данных": "Нет данных",
    "У вас есть несохраненные данные. Хотите продолжить?": "У вас есть несохраненные данные. Хотите продолжить?",
    "Соответствие": "Соответствие",
    "Скоринг": "Скоринг",
    "Конверсия": "Конверсия",
    "Добавить заказчика": "Добавить заказчика",
    "Добавить рекрутера": "Добавить рекрутера",
    "Организация не зарегистрирована на этот адрес": "Организация не зарегистрирована по данному адресу <b>{param}</b>.Пожалуйста, введите следующую информацию.",
    "Добавить email отправителя": "Добавить email отправителя",
    "Поиск по базе<br> соискателей": "Поиск по базе<br> соискателей",
    "Находите лучших кандидатов из базы данных": "Находите лучших кандидатов из базы данных",
    "Введите в формате адрес@ящик.домен": "Введите в формате example@gmail.com",
    "Восстановить пароль": "Восстановить пароль",
    "Почта для отправки писем": "Почта для отправки писем",
    "Удаленный профиль": "Удаленный профиль",
    "Организация не зарегистрирована по данному адресу email.": "Организация не зарегистрирована по данному адресу <b>{email}</b>.",
    "Пожалуйста, введите следующую информацию.": "Пожалуйста, введите следующую информацию.",
    "Электронный адрес email не привязан ни к одной из компаний.": "Электронный адрес <b>{email}</b> не привязан ни к одной из компаний.",
    "Для регистрации новой компании заполните поля ниже:": "Для регистрации новой компании заполните поля ниже:",
    "На e-mail email отправлено письмо с кодом доступа.": "На e-mail <b>{email}</b> отправлено письмо с кодом доступа.",
    "Пожалуйста, проверьте электронную почти и введите код из письма.": "Пожалуйста, проверьте электронную почти и введите код из письма.",
    "Чат с соискателями": "Чат с соискателями",
    "По вашим опубликованным вакансиям есть новые отклики": "По вашим опубликованным вакансиям есть новые отклики",
    "n отклик": "отклик",
    "n отклика": "отклика",
    "n откликов": "откликов",
    "Не опубликовано": "Не опубликовано",
    "Опубликованные": "Опубликованные",
    "Не опубликованные": "Не опубликованные",
    "Статус": "Статус",
    "Архив": "Архив",
    "соискателей": "соискателей",
    "У вас есть новые отклики": "У вас есть новые отклики",
    "У вас нет новых откликов": "У вас нет новых откликов",
    "Вы не можете добавить кандидата на эту доску": "Вы не можете добавить кандидата на эту доску",

    "Осталось {n} день доступа": "Осталось {n} день доступа",
    "Осталось {n} дня доступа": "Осталось {n} дня доступа",
    "Осталось {n} дней доступа": "Осталось {n} дней доступа",
    "Выберите подходящий тарифный план для доступа к базе соискателей": "Выберите подходящий тарифный план для доступа к базе соискателей",
    "Рекомендуемый": "Рекомендуемый",
    "Доступ к базе соискателей": "Доступ к базе соискателей",
    "дней": "дней",
    "Вы уже достигли этот лимит": "Вы уже достигли этот лимит",
    "c функцией “Автоподбор”": "c функцией “Автоподбор”",
    "Количество вакансий": "Количество вакансий",
    "Вы выбрали": "Вы выбрали",
    "Доступ к базе данных кандидатов": "Доступ к базе данных кандидатов",
    "Срок": "Срок",
    "Автоподбор кандидатов": "Автоподбор кандидатов",
    "Наша алгоритмы подберут наилучших кандидатов, вам останется только выбрать": "Наша алгоритмы подберут наилучших кандидатов, вам останется только выбрать",
    "Бесплатно при покупке доступа к базе": "Бесплатно при покупке доступа к базе",
    "У вас уже существует не оплаченный счет на этот тариф": "У вас уже существует не оплаченный счет на этот тариф",
    "Пожалуйста оплатите": "Пожалуйста оплатите",
    "Дата создание": "Дата создание",
    "Общая сумма": "Общая сумма",
    "Не оплачен": "Не оплачен",
    "Оплачен": "Оплачен",
    "Оплатить через Payme": "Оплатить через Payme",
    "История оплаты": "История оплаты",
    "Виберите способ оплаты": "Виберите способ оплаты",
    "Оплата производится через платежную систему Payme": "Оплата производится через платежную систему Payme",
    "Оплатить": "Оплатить",
    "Оплата производится через банковский счет": "Оплата производится через банковский счет",
    "Сформировать счет": "Сформировать счет",
    "Расчетный счет": "Расчетный счет",
    "Банк": "Банк",
    "МФО": "МФО",
    "ИНН": "ИНН",
    "ОКЭД": "ОКЭД",
    "Рег.код НДС": "Рег.код НДС",
    "Тариф": "Тариф",
    "Дата начало": "Дата начало",
    "Дата окончание": "Дата окончание",
    "Дата оплаты": "Дата оплаты",
    "Способ оплаты": "Способ оплаты",
    "Посмотреть": "Посмотреть",
    "Исполнитель": "Исполнитель",
    "Эл.почта": "Эл.почта",
    "Список заказанных услуг": "Список заказанных услуг",
    "Кол-во": "Кол-во",
    "Стоимость": "Стоимость",
    "Размещение вакансий": "Размещение вакансий",
    "Итого к оплате": "Итого к оплате",
    "Не забудьте оплатить счет в течение 1-го дня.": "Не забудьте оплатить счет в течение 1-го дня.",
    "После формирования счета на оплату вы сразу сможете получить доступ к базе соискателей и функционалу автопоиска.": "После формирования счета на оплату вы сразу сможете получить доступ к базе соискателей и функционалу автопоиска.",
    "Скачать счет": "Скачать счет",
    "Не ограничено": "Не ограничено",
    "Счет на оплату": "Счет на оплату",

    "Электронная почта": "Электронная почта",
    "Сохранить вакансию": "Сохранить вакансию",
    "Мои кандидаты": "Мои кандидаты",
    "Контактный телефон": "Контактный телефон",
    "Оплаченный период": "Оплаченный период",
    "Осталось {month} месяц доступа": "Осталось {month} месяц доступа",
    "Осталось {month} месяца доступа": "Осталось {month} месяца доступа",
    "Осталось {month} месяцев доступа": "Осталось {month} месяцев доступа",

    "Осталось {month} месяц и {n} день доступа": "Осталось {month} месяц и {n} день доступа",
    "Осталось {month} месяц и {n} дня доступа": "Осталось {month} месяц и {n} дня доступа",
    "Осталось {month} месяц и {n} дней доступа": "Осталось {month} месяц и {n} дней доступа",

    "Осталось {month} месяца и {n} день доступа": "Осталось {month} месяца и {n} день доступа",
    "Осталось {month} месяца и {n} дня доступа": "Осталось {month} месяца и {n} дня доступа",
    "Осталось {month} месяца и {n} дней доступа": "Осталось {month} месяца и {n} дней доступа",

    "Осталось {month} месяцев и {n} день доступа": "Осталось {month} месяцев и {n} день доступа",
    "Осталось {month} месяцев и {n} дня доступа": "Осталось {month} месяцев и {n} дня доступа",
    "Осталось {month} месяцев и {n} дней доступа": "Осталось {month} месяцев и {n} дней доступа",
    "Продлить доступ": "Продлить доступ",
    "Купить доступ": "Купить доступ",
    "Нет доступа": "Нет доступа",
    "Бесплатно": "Бесплатно",
    "Итого": "Итого",
    "Автоподбор": "Автоподбор",
    "База кандидатов": "База кандидатов",
    "Моя компания": "Моя компания",
    "Договора и счета": "Договоры и счета",
    "Тарифы": "Тарифы",
    "Период": "Период",
    "Счета": "Счета",
    "Договора": "Договора",
    "Выберите компанию": "Выберите компанию",
    "Счет на оплату № invoice_number к договору № contract_number доступ ISH.UZ period price сум": "Счет на оплату № {invoice_number} к договору № {contract_number} доступ ISH.UZ PRO <b>{period}</b> <b>{price}</b> сум",
    "Счет на оплату № invoice_number доступ ISH.UZ period price сум": "Счет на оплату № {invoice_number} доступ ISH.UZ PRO <b>{period}</b> <b>{price}</b> сум",
    "Договор № contract_number от contract_date года возмездное оказание услуг ISH.UZ": "Договор № <b>{contract_number}</b> от <b>{contract_date}</b> года возмездное оказание услуг ISH.UZ PRO",
    "Выберите период": "Выберите период",
    "Руководитель": "Руководитель",
    "Введите пароль": "Введите пароль",
    "Пока у вас не сформированы счета": "Пока у вас не сформированы счета",
    "Уважаемый user": "Уважаемый {user}",
    "Мы предоставили вашей компании полный доступ к сайту не дожидаясь поступления оплаты. Доступ действует в течение days. Пожалуйста, не забудьте оплатить счет.": "Мы предоставили вашей компании полный доступ к сайту не дожидаясь поступления оплаты. Доступ действует в течение {days}. Пожалуйста, не забудьте оплатить счет.",
    "Распечатать": "Распечатать",
    "Введите реквизиты организации": "Введите реквизиты организации",
    "ФИО руководителя": "ФИО руководителя",
    "Должность руководителя": "Должность руководителя",
    "Действующего на основании": "Действующего на основании",
    "Создать договор": "Создать договор",

    "Для получения доступа к базе кандидатов, подключите тариф.": "Для получения доступа к базе кандидатов, подключите тариф.",
    "Тарифы от 290 000 сум.": "Тарифы от 290 000 сум.",
    "У вас нет доступа к базе кандидатов.": "У вас нет доступа к базе кандидатов.",

    "Пробный период": "Пробный период",
    "Оплатить доступ": "Оплатить доступ",
    "Сегодня последний день доступа": "Сегодня последний день доступа",
    "Выбрать тариф": "Выбрать тариф",
    "Если у вас возникли вопросы, позвоните на номер +998 95 169-08-08": "Если у вас возникли вопросы, позвоните на номер <a href='tel:+998951690808'>+998 95 169-08-08</a>",
    "Телефон службы поддержки": "Телефон службы поддержки",
    "Выберите тариф, чтобы открыть доступ к контактным данным кандидатов и использовать все возможности сервиса": "Выберите тариф, чтобы открыть доступ к контактным данным кандидатов и использовать все возможности сервиса",
    "Поздравляем": "Поздравляем",
    "Ваша оплата прошла успешно": "Ваша оплата прошла успешно",
    "Теперь вам доступна все возможности сервиса": "Теперь вам доступна все возможности сервиса",
    "Перейти на главную страницу": "Перейти на главную страницу",
    "Оплата не проведена": "Оплата не проведена",

    "Профиль заполнен на русском языке": "Профиль заполнен на русском языке",
    "Профиль заполнен на узбекском языке": "Профиль заполнен на узбекском языке",
    "Запросить дополнительные данные": "Запросить дополнительные данные",
    "Выберите данные которые вы хотите запросить": "Выберите данные, которые вы хотите запросить",
    "Ваш запрос успешно отправлен!": "Ваш запрос успешно отправлен!",
    "Запросить информацию": "Запросить информацию",
    "Добавить к себе": "Добавить к себе",
    "В кадровый резерв": "В кадровый резерв",
    "В черный список": "В черный список",
    "Кандидат добавлен в кадровый резерв": "Кандидат добавлен в кадровый резерв",
    "Уведомления": "Уведомления",
    "Получать уведомления о новых кандидатах на вакансии(отклики) (11:00)": "Получать уведомления о новых кандидатах на вакансии(отклики) в 11:00",
    "Получать уведомления о новых кандидатах на вакансии(отклики) (17:00)": "Получать уведомления о новых кандидатах на вакансии(отклики) в 17:00",
    "Активный": "Активный",
    "Неактивный": "Неактивный",
    "Сортировка по дате": "Сортировка по дате",
    "Сортировка по соответствию": "Сортировка по соответствию",
    "Сортировка по скорингу": "Сортировка по скорингу",
    "Показывать только новые": "Показывать только новые",
    "Время последнего входа": "Время последнего входа",
    "Превышен лимит доступных устройств на этом аккаунте": "Превышен лимит доступных устройств на этом аккаунте",
    "Вы можете удалить одно из нижеперечисленных устройств, чтобы войти": "Вы можете удалить одно из нижеперечисленных устройств, чтобы войти",
    "по": "по",
    "position_name в company_name": "<b>{position_name}</b> в <b>{company_name}</b>",
    "Есть личное авто": "Есть личное авто",
    "Водительские права": "Водительские права",
    "Предпочтительный возраст": "Предпочтительный возраст",
    "Предпочтительный пол": "Предпочтительный пол",
    "эта информация только для автоподбора и не будет отображаться для соискателей": "эта информация только для автоподбора и не будет отображаться для соискателей",
    "до param_to": "до {param_to}",
    "от param_from до param_to": "от {param_from} до {param_to}",
    "от param_from": "от {param_from}",
    "ФИО контактного лица": "ФИО контактного лица",
    "Номер телефона контактного лица": "Номер телефона контактного лица",
    "Данная информация не публикуется в вакансии. Используется только для уточнения автоматического подбора.": "Данная информация не публикуется в вакансии. Используется только для уточнения автоматического подбора.",
    "Предпочтения к кандидатам": "Предпочтения к кандидатам",
    "Возраст": "Возраст",
    "Вакансия подойдет для кандидатов": "Вакансия подойдет для кандидатов",
    "Доступы к вакансиям": "Доступы к вакансиям",
    "Только свои вакансии": "Только свои вакансии",
    "Все вакансии right": "Все вакансии",
    "Выбрать вакансию": "Выбрать вакансию",
    "Роль": "Роль",
    "Администратор": "Администратор",
    "Подходит": "Подходит",
    "Не подходит": "Не подходит",
    "Заказчик одобрил": "Заказчик одобрил",
    "Заказчик не одобрил": "Заказчик не одобрил",
    "Может создавать вакансии, имеет доступ ко всей базе данных соискателей, управляет этапами отбора.": "Может создавать вакансии, имеет доступ ко всей базе данных соискателей, управляет этапами отбора.",
    "Имеет доступ к отобранным кандидатам, оценивает кандидатов на конкретной вакансии.": "Имеет доступ к отобранным кандидатам, оценивает кандидатов на конкретной вакансии.",
    "Добавить пользователя": "Добавить пользователя",

    "Прокомментируйте действие": "Прокомментируйте действие",
    "Спам": "Спам",
    "Кандидатов пока нет": "Кандидатов пока нет",
    "Этап": "Этап",
    "Сортировка по опыту работы": "Сортировка по опыту работы",
    "Поля не может быть пустым": "Поля не может быть пустым",
    "Описание вакансии не может быть менее 150 символов": "Описание вакансии не может быть менее 150 символов",
    "Другие": "Другие",
    "Дата публикации": "Дата публикации",
    "Уровень владения компьютером": "Уровень владения компьютером",
    "Город(район) работы": "Город(район) работы",
    "Кандидат добавлен к вакансии vacancy_position_name": "Кандидат добавлен к вакансии {vacancy_position_name}",
    "Успешно добавлен": "Успешно добавлен",
    "Перейти к вакансии": "Перейти к вакансии",
    "Подтвердить адрес": "Подтвердить адрес",
    "Собеседование на вакансию position_name": "Собеседование на вакансию {position_name}",
    "Собеседование": "Собеседование",
    "Собеседование не назначено": "Собеседование не назначено",
    "Назначить собеседование": "Назначить собеседование",
    "Дата": "Дата",
    "Время": "Время",
    "Добавить участников": "Добавить участников",
    "Добавить ссылку на онлайн собеседование": "Добавить ссылку на онлайн собеседование",
    "Укажите адрес, где будет проходить собеседование": "Укажите адрес, где будет проходить собеседование",
    "показать на карте": "показать на карте",
    "Дата и время": "Дата и время",
    "Отменить собеседование": "Отменить собеседование",
    "Собеседование проведено": "Собеседование проведено",
    "Календарь": "Календарь",
    "Текст": "Текст",
    "Отладчик": "Отладчик",
    "Назначено собеседование": "Назначено собеседование",
    "Понедельник": "Понедельник",
    "Вторник": "Вторник",
    "Среда": "Среда",
    "Четверг": "Четверг",
    "Пятница": "Пятница",
    "Суббота": "Суббота",
    "Воскресенье": "Воскресенье",
    "Январь": "Январь",
    "Февраль": "Февраль",
    "Март": "Март",
    "Апрель": "Апрель",
    "Май": "Май",
    "Июнь": "Июнь",
    "Июль": "Июль",
    "Август": "Август",
    "Сентябрь": "Сентябрь",
    "Октябрь": "Октябрь",
    "Ноябрь": "Ноябрь",
    "Декабрь": "Декабрь",
    "Сегодня": "Сегодня",
    "Пожалуйста, введите корректную ссылку": "Пожалуйста, введите корректную ссылку",
    "Добавить к вакансии": "Добавить к вакансии",

    "Вопрос": "Вопрос",
    "Варианты": "Варианты",
    "Вариант": "Вариант",
    "Добовить еще вариант": "Добовить еще вариант",
    "Добавить вопрос": "Добавить вопрос",
    "Мои тесты": "Мои тесты",
    "Название теста": "Название теста",
    "Добавить тест": "Добавить тест",
    "Вы уверены, что хотите удалить этот тест?": "Вы уверены, что хотите удалить этот тест?",
    "Тест успешно удалено!": "Тест успешно удалено!",
    "Тесты": "Тесты",
    "Отправить тест": "Отправить тест",
    "прошёл": "прошёл",
    "пройден": "пройден",
    "в ожидании": "в ожидании",
    "не прошёл": "не прошёл",
    "Создать тест": "Создать тест",
    "Выберите тип": "Выберите тип",
    "Правильный вариант": "Правильный вариант",
    "Неправильный вариант": "Неправильный вариант",
    "Редактировать тест": "Редактировать тест",
    "баллов": "баллов",
    "Результат": "Результат",
    "Добавить резюме на вакансию position_name": "Добавить резюме на вакансию {position_name}",
    "Добавить резюме": "Добавить резюме",
    "Поиск резюме": "Поиск резюме",
    "на ISH.UZ": "на ISH.UZ",
    "на HeadHunter.uz": "на HeadHunter.uz",
    "на OLX.uz": "на OLX.uz",
    "У вас нет тесты": "У вас нет тесты",
    "Ссылка на онлайн собеседование": "Ссылка на онлайн собеседование",
    "указать": "указать",
    "Чтобы легко добавлять кандидатов с других порталов поиска работы на ISH.UZ, установите расширение для браузера Google Chrome.": "Чтобы легко добавлять кандидатов с других порталов поиска работы на ISH.UZ, установите расширение для браузера Google Chrome.",
    "После установки расширения на профиле кандидата появится кнопка «Добавить в ISH.UZ», если вы имеете доступ к контактным данным кандидата.": "После установки расширения на профиле кандидата появится кнопка «Добавить в ISH.UZ», если вы имеете доступ к контактным данным кандидата.",
    "Установить расширение": "Установить расширение",
    "Перейти на portal": "Перейти на {portal}",
    "Эффективности рекрутеров": "Эффективности рекрутеров",
    "Рекрутер / вакансия": "Рекрутер / вакансия",
    "Количество вакансии": "Количество вакансии",
    "Статус вакансии": "Статус вакансии",
    "Закрытые вакансии": "Закрытые вакансии",
    "Все рекрутеры": "Все рекрутеры",
    "Закрыта": "Закрыта",
    "Открыта": "Открыта",
    "Дата открытия": "Дата открытия",
    "Дата закрытия": "Дата закрытия",
    "Количество кандидатов": "Количество кандидатов",
    "Длительность": "Длительность",
    "Время закрытия вакансии": "Время закрытия вакансии",
    "Публикация вакансии на сайтах поиска работы": "Публикация вакансии на сайтах поиска работы",
    "Подключить HeadHunter": "Подключить HeadHunter",
    "HeadHunter подключен": "HeadHunter подключен",
    "Подключить": "Подключить",
    "Если у вас есть аккаунт работодателя на сайте hh.uz, вы можете его подключить к ISH.UZ": "Если у вас есть аккаунт работодателя на сайте hh.uz, вы можете его подключить к ISH.UZ",
    "Интеграции": "Интеграции",
    "Отправлено тестовое задание": "Отправлено тестовое задание",
    "Отправлено СМС сообщение": "Отправлено СМС сообщение",
    "Доступы к этапам": "Доступы к этапам",
    "Все этапы": "Все этапы",
    "Выбрать этапы": "Выбрать этапы",
    "Срок прохождения этапа": "Срок прохождения этапа",
    "Количество дней": "Количество дней",
    "У вас не осталось вакансий для публикации в hh.uz": "У вас не осталось вакансий для публикации в hh.uz",
    "Анонимная премиум": "Анонимная премиум",
    "Премиум рекламная": "Премиум рекламная",
    "Анонимная: скрытый подбор": "Анонимная: скрытый подбор",
    "Стандарт плюс: автообновление": "Стандарт плюс: автообновление",
    "Премиум: неделя в топе": "Премиум: неделя в топе",
    "Рекламная": "Рекламная",
    "Бесплатная": "Бесплатная",
    "Стандарт: без обновления": "Стандарт: без обновления",
    "Прикрепить проверочный тест": "Прикрепить проверочный тест",
    "Вы можете отправить тестовое задание для кандидатов, чтобы при отклике проверить их": "Вы можете отправить тестовое задание для кандидатов, чтобы при отклике проверить их",
    "Выберите тест": "Выберите тест",
    "Результаты теста": "Результаты теста",
    "Cопроводительный текст": "Cопроводительный текст",
    "У вас нет доступа на ISH.UZ PRO": "У вас нет доступа на ISH.UZ PRO",
    "Перейти на ISH.UZ": "Перейти на ISH.UZ",
    "Просрочено": "Просрочено",
    "Просрочен": "Просрочен",
    "Превышено время нахождения кандидата на этом этапе": "Превышено время нахождения кандидата на этом этапе",
    "Доступ к системе для автоматизации подбора персонала ISH.UZ PRO": "Доступ к системе для автоматизации подбора персонала ISH.UZ PRO",
    "за одного рекрутера": "за одного рекрутера",
    "Выберите подходящий тарифный план для подключения к системе по автоматизации подбора персонала ISH.UZ PRO": "Выберите подходящий тарифный план для подключения к системе по автоматизации подбора персонала ISH.UZ PRO",
    "Доступ к ISH.UZ PRO": "Доступ к ISH.UZ PRO",
    "Продлить доступы": "Продлить доступы",
    "Купить еще доступ": "Купить еще доступ",
    "Отменить продление": "Отменить продление",
    "Создать счет": "Создать счет",
    "<b>Выберите</b> кому хотите продлить доступ.<br> После выбора внизу появится кнопка <br>\"<b>Создать счет</b>\"": "<b>Выберите</b> кому хотите продлить доступ.<br> После выбора внизу появится кнопка <br>\"<b>Создать счет</b>\"",
    "Рекрутера": "Рекрутера",
    "Выбрано": "Выбрано",
    "Выберите тариф": "Выберите тариф",
    "Стоимость каждого рекрутера recruiterPerPrice сум в месяц": "Стоимость каждого рекрутера {recruiterPerPrice} сум в месяц",
    "Сумма тарифа": "Сумма тарифа",
    "Сумма рекрутеров": "Сумма рекрутеров",
    "Счёт успешно создано! Не забудьте его оплатить.": "Счёт успешно создано! Не забудьте его оплатить.",
    "Количество доступов": "Количество доступов",
    "открытая": "открытая",
    "закрытая": "закрытая",
    "Мои заявки": "Мои заявки",
    "Заявки": "Заявки",
    "Создать заявку": "Создать заявку",
    "Редактировать заявку": "Редактировать заявку",
    "Открытые заявки": "Открытые заявки",
    "Закрытые заявки": "Закрытые заявки",
    "Вы уверены, что хотите отклонить эту заявку?": "Вы уверены, что хотите отклонить эту заявку?",
    "Отклонить": "Отклонить",
    "Отклоненные заявки": "Отклоненные заявки",
    "отклонена": "отклонена",
    "Причина отклонений": "Причина отклонений",
    "подтверждена": "подтверждена",
    "Подтвердить": "Подтвердить",
    "Подтвержденные заявки": "Подтвержденные заявки",
    "Добвить рекрутеров": "Добвить рекрутеров",
    "Количество рекрутеров": "Количество рекрутеров",
    "Для продление доступов выберите Администратора тоже": "Для продление доступов выберите Администратора тоже",
    "Сделать по умолчанию": "Сделать по умолчанию",
    "на linkedin.com": "на linkedin.com",
    "Брендирование вакансий": "Брендирование вакансий",
    "Выпускники ВУЗа": "Выпускники ВУЗа",
    "Документооборот": "Документооборот",
    "Общий": "Общий",
    "Прямые заявители": "Прямые заявители",
    "Проактивный поиск": "Проактивный поиск",
    "Outlook календарь подключен": "Outlook календарь подключен",
    "Синхронизировать ISH.UZ и Outlook календарь": "Синхронизировать ISH.UZ и Outlook календарь",
    "Если вы хотите синхронизировать ваши события с Outlook календарем, вы можете его подключить к ISH.UZ": "Если вы хотите синхронизировать ваши события с Outlook календарем, вы можете его подключить к ISH.UZ",
    "Воронка вакансии": "Воронка вакансии",
    "Источник вакансии": "Источник вакансии",
    "Заголовок": "Заголовок",
    "Описание": "Описание",
    "Публикуется на {portal}": "Публикуется на {portal}",
    "Установите таймер": "Установите таймер",
    "Часы": "Часы",
    "Минуты": "Минуты",
    "Установить таймер": "Установить таймер",
    "Не был выбран вариант ответа": "Не был выбран вариант ответа",
    "Мои папки": "Мои папки",
    "Создать папку": "Создать папку",
    "Название папки": "Название папки",
    "Выберите папку": "Выберите папку",
    "Добавить в папку": "Добавить в папку",
    "Редактировать собеседование": "Редактировать собеседование",
    "Все кандидаты": "Все кандидаты",
    "Перейти в мои кандидаты": "Перейти в мои кандидаты",
    "Кандидат не подходит": "Кандидат не подходит",
    "Хотите отправить уведомление кандидату?": "Хотите отправить уведомление кандидату?",
    "Был на сайте сегодня в time": "Был на сайте сегодня в {time}",
    "Был на сайте вчера в time": "Был на сайте вчера в {time}",
    "Был на сайте меньше недели назад": "Был на сайте меньше недели назад",
    "Был на сайте более недели назад": "Был на сайте более недели назад",
    "Был на сайте более двух недель назад": "Был на сайте более двух недель назад",
    "Множественный выбор вариантов": "Множественный выбор вариантов",
    "Редактировать приветственный текст": "Редактировать приветственный текст",
    "Приветственный текст": "Приветственный текст",
    "На русском": "На русском",
    "На узбекском (кириллица)": "На узбекском (кириллица)",
    "На узбекском (латиница)": "На узбекском (латиница)",
    "Кандидат откликнулся на эту вакансию": "Кандидат откликнулся на эту вакансию",
    "Дата и время отклика": "Дата и время отклика",
    "Получить контакты": "Получить контакты",
    "Показать в случайном порядке": "Показать в случайном порядке",
    "Количество показываемых тестов": "Количество показываемых тестов",
    "Типы ответов": "Типы ответов",
    "Прикрепить картинку": "Прикрепить картинку",
    "Один из списка": "Один из списка",
    "Несколько из списка": "Несколько из списка",
    "Текстовые поля": "Текстовые поля",
    "Правильный ответ": "Правильный ответ",
    "Посмотреть результаты": "Посмотреть результаты",
    "Импорт из hh.uz": "Импорт из hh.uz",
    "Ваши вакансии в hh.uz": "Ваши вакансии в hh.uz",
    "Импортировать": "Импортировать",
    "Переподключится": "Переподключится",
    "Показать только по ссылке": "Показать только по ссылке",
    "Подключение к Telegram Bot": "Подключение к Telegram Bot",
    "вы можете получать уведомления через бота": "вы можете получать уведомления через бота",
    "UTM ссылка": "UTM ссылка",
    "Цена": "Цена",
    "Токен": "Токен",
    "Без регистрации": "Без регистрации",
    "Создать ссылку UTM": "Создать ссылку UTM",
    "Введите название": "Введите название",
    "Название UTM": "Название UTM",
    'Дата создания': 'Дата создания',
    'Количество просмотров': 'Количество просмотров',
    'Количество заявок': 'Количество заявок',
};