import request from '@/utils/request';

export function recruiterStatistics() {
  return request({
    url: '/company/recruiter-statistics',
    method: 'get'
  });
}
export function vacanciesHistoryStatistics() {
  return request({
    url: '/company/vacancy-action-history-statistics',
    method: 'get'
  });
}
export function vacanciesTracking() {
  return request({
    url: '/company-reports/tracking',
    method: 'get'
  });
}
export function funnelDownload(data) {
  return request({
    url: '/company-reports/candidates-funnel/download',
    method: 'post',
    data
  });
}